import React, { useState } from "react";

const OptionsButtons = ({
  heading,
  options,
  selectedValue,
  setSelectedValue,
}) => {
  return (
    <div className="heading-buttons-line">
      <p className="btn-heading m-0">{heading}</p>
      <div className="btns-options">
        {options.map((option) => (
          <button
            key={option.value}
            style={{
              backgroundColor:
                option.value === selectedValue
                  ? "rgba(231, 50, 147, 1)" // Highlight selected button
                  : "rgba(98, 97, 141, 1)", // Default background for other buttons
              color: "white",
              width: "40px",
              height: "auto",
              border: "none",
              borderRadius: "8px",
              marginRight: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center", // Center vertically
              textAlign: "center", // Align text in center
              fontSize: heading === "OUT" ? "0.5rem" : "0.8rem",
            }}
            onClick={() => {
              // Toggle the selection
              if (option.value === selectedValue) {
                if(heading === "SCORE") {
                  setSelectedValue(0); // Deselect if already selected
                } else{
                setSelectedValue(null); // Deselect if already selected
                }
              } else {
                setSelectedValue(option.value); // Set the selected value
              }
            }}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OptionsButtons;
