import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";

const Partnership = ({ handleClose }) => {
  return (
    <section
      id="partnership"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="1x"
            onClick={handleClose}
          />
        </div>
        <h1 className="match-scoring-modal-heading">Partnership</h1>
        <div className="partnership-section px-5 py-2">
          <span className="d-flex justify-content-between align-align-items-center">
            <p>Babar Azam 3(17)</p>
            <strong>9 (24)</strong>
            <p>Babar Azam 3(17)</p>
          </span>
          <div className="d-flex justify-content-center">
            <div
              className="partnership-section-highlights d-flex position-relative mt-3"
              style={{
                borderBottom: "1px solid #000",
                width: "75%",
                paddingBottom: "10px",
              }}
            >
              {/* Player 1 (Grows Left to Right) */}
              <div
                style={{
                  flexGrow: 1,
                  marginRight: "5px",
                  position: "relative",
                }}
              >
                <ProgressBar
                  // now={}
                  variant="success"
                  className="progress-bar-rtl"
                />
              </div>

              {/* Player 2 (Grows Right to Left) */}
              <div
                style={{ flexGrow: 1, marginLeft: "5px", position: "relative" }}
              >
                <ProgressBar
                  // now={player1Progress}
                  variant="primary"
                  className="progress-bar-ltr"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Partnership;
