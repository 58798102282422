import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Alert,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactImageUploading from "react-images-uploading";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequestForm, uploadURL } from "../../../helper/api";
import Layout from "../../../layouts/Layout";
import { Images } from "../../../constants/Images";
import BackButton from "../../../constants/BackButton";
import PairButtons from "../../../constants/PairButtons";
import { getItemFromLocalStorage } from "../../../helper/helper";
import SingleButton from "../../../constants/SingleButton";
import EditPlayerSkeleton from "../../../constants/Skeleton Loaders/EditPlayerSkeleton";

const CreateEditPlayers = () => {
  const navigate = useNavigate();
  let location,
    { search } = useLocation();

  const [playerName, setPlayerName] = useState("");
  const [battingStyle, setBattingStyle] = useState("");
  const [bowlingStyle, setBowlingStyle] = useState("N/A");
  const [wicketKeeper, setWicketKeeper] = useState(false);
  const [playerImage, setPlayerImage] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [playerId, setPlayerId] = useState("");
  const [createUpdateLoading, setCreateUpdateLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  //   const [name, setName] = useState("");
  //   const [flags, setFlags] = useState([]);
  //   const [selectedPlayers, setSelectedPlayers] = useState([]);

  const paceOptions = [
    "Right-Arm Fast",
    "Left-Arm Fast",
    "Right-Arm Medium",
    "Left-Arm Medium",
    "Right-Arm Medium Fast",
    "Left-Arm Medium Fast",
  ];
  const spinOptions = [
    "Right-Arm Off Break",
    "Right-Arm Leg Spin",
    "Left-Arm Spin",
    "ChinaMan",
  ];

  const handlePaceChange = (e) => {
    setBowlingStyle(e.target.value);
  };

  const handleSpinChange = (e) => {
    setBowlingStyle(e.target.value);
  };

  // Check if editing a player
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("_id");
    const isEdit = queryParams.get("isEdit");
    console.log("a-id", id);
    console.log("a-isEdit", isEdit);
    if (id) {
      setIsEdit(true);
      fetchPlayerDetails(id);
    }
  }, [location]);

  // Fetch Player Details for Editing
  const fetchPlayerDetails = async (id) => {
    try {
      setDataLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/player/get-player`,
        token,
        { playerId: id }
      );
      if (result?.status === 200) {
        const player = result.data.player;
        setPlayerId(player._id);
        setPlayerName(player.playerName);
        setBattingStyle(player.battingStyle);
        setBowlingStyle(player.bowlingStyle);
        setWicketKeeper(player.wicketKeeper);
        if (player.playerImage) {
          setPlayerImage([{ data_url: player.playerImageUrl }]);
          setFeaturedImage(player.playerImage);
        }
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch player details"
        );
      }
      setDataLoading(false);
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  // Handle Image Upload
  const onChangeImage = (imageList, addUpdateIndex) => {
    setPlayerImage(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!playerName || !battingStyle) {
      toast.error("Please fill in all required fields.", {
        position: "top-center",
      });
      return;
    }

    try {
      setCreateUpdateLoading(true);
      const user = JSON.parse(getItemFromLocalStorage("USER"));

      const endpoint = isEdit
        ? `/api/secure/v2/player/edit-player`
        : `/api/secure/v2/player/register-player`;

      const formData = new FormData();
      formData.append("playerName", playerName);
      formData.append("battingStyle", battingStyle);
      formData.append("bowlingStyle", bowlingStyle);
      formData.append("wicketKeeper", wicketKeeper);
      if (isEdit === false)
        formData.append("ownerType", user.roleName?.replace(" ", ""));

      if (playerImage.some((file) => file.hasOwnProperty("file"))) {
        if (featuredImage) {
          formData.append("playerImage", featuredImage);
        } else {
          toast.error("Please Upload the Thumbnail Image for a Player.", {
            position: "top-center",
            theme: "colored",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }

      if (isEdit) {
        formData.append("playerId", playerId);
      }
      const token = getItemFromLocalStorage("TOKEN");

      const { result, error } = await postRequestForm(
        endpoint,
        token,
        formData
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success(
          `Player ${isEdit ? "updated" : "created"} successfully.`,
          { position: "top-center" }
        );
      } else {
        throw new Error(error?.response?.data?.message || "Operation failed");
      }

      setCreateUpdateLoading(false);

      navigate("/dashboard/all-players");
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  const resetBowlingStyle = () => {
    setBowlingStyle("");
  };

  return (
    <Layout>
      <section
        id="createTeam"
        style={{
          // backgroundImage: `url(${Images.background2})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        {" "}
        <Container>
          {/* <div className="d-flex justify-content-between align-items-center"> */}
            <BackButton
              icon={Images.backArrow}
              content="Back"
              onClick={() => {
                navigate("/dashboard/all-players");
              }}
            />
            <p className="text-light mt-2 text-center font-secondary fs-4 fw-bold">
              {isEdit ? "UPDATE PLAYER" : "CREATE PLAYER"}
            </p>
            {/* <div></div>
          </div> */}
        </Container>
        {dataLoading ? (
          <EditPlayerSkeleton />
        ) : (
          <Container className="list-container w-75">
            <Row className="align-items-start">
              {/* Player Name */}
              <Col lg={9} md={9} xs={9}>
                <div className="pb-3 heading border-0">
                  <p className="mb-2">PLAYER NAME:</p>
                  <FormControl
                    type="text"
                    placeholder="Name"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                  />
                </div>

                {/* Batting Style */}
                <div className="pb-3 heading border-0">
                  <p className="mb-2">BATTING STYLE:</p>
                  <Form.Check
                    inline
                    label="RHB"
                    name="battingStyle"
                    type="radio"
                    id="battingStyle-rhb"
                    value="RHB"
                    className="me-5"
                    checked={battingStyle === "RHB"}
                    onChange={(e) => setBattingStyle(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="LHB"
                    name="battingStyle"
                    type="radio"
                    id="battingStyle-lhb"
                    value="LHB"
                    className="ms-5"
                    checked={battingStyle === "LHB"}
                    onChange={(e) => setBattingStyle(e.target.value)}
                  />
                </div>

                {/* Wicket Keeper */}
                <div className="pb-3 heading border-0">
                  <p className="mb-2">WICKET KEEPER:</p>
                  <Form.Check
                    inline
                    label="YES"
                    name="wicketKeeper"
                    type="radio"
                    id="wicketKeeper-yes"
                    value="true"
                    className="me-5"
                    checked={wicketKeeper === true}
                    onChange={() => setWicketKeeper(true)}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="wicketKeeper"
                    type="radio"
                    id="wicketKeeper-no"
                    value="false"
                    className="ms-5"
                    checked={wicketKeeper === false}
                    onChange={() => setWicketKeeper(false)}
                  />
                </div>
              </Col>

              {/* Player Image */}
              <Col
                lg={3}
                md={3}
                xs={12}
                className=" d-flex justify-content-center"
              >
                <ReactImageUploading
                  value={playerImage}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                  maxNumber={1}
                  maxFileSize={10000000}
                  acceptType={["jpeg", "jpg", "gif", "png"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                  }) => (
                    <>
                      <div className="upload__image-wrapper mb-3 ">
                        {imageList?.length === 0 ? (
                          <div
                            className="image-item d-flex justify-content-center"
                            style={{
                              border: "1px solid #ccc",
                              cursor: "pointer",
                              width: "120px",
                              height: "120px",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundImage: `url(${
                                playerImage || Images.profile
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundBlendMode: "overlay",
                              backgroundColor: "rgba(231, 50, 147, .5)",
                              border: "1px solid rgba(231, 50, 147, 1)",
                              borderRadius: "50%",
                              ...(isDragging ? { color: "red" } : {}),
                            }}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              color="#000"
                              style={{
                                fontSize: "30px",
                              }}
                            />
                          </div>
                        ) : (
                          imageList?.map((image, index) => (
                            <div
                              key={index}
                              className="image-item position-relative d-inline-block"
                            >
                              <LazyLoadImage
                                src={image["data_url"]}
                                width={120}
                                height={120}
                                className="rounded-circle"
                                alt="uploaded"
                              />
                              <Button
                                className="position-absolute bg-light p-1 rounded-circle translate-middle p-0"
                                variant="link"
                                onClick={() => onImageUpdate(index)}
                                style={{
                                  lineHeight: 1,
                                  right: -10,
                                  bottom: -10,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  color="rgb(231, 50, 147)"
                                />
                              </Button>
                            </div>
                          ))
                        )}
                      </div>
                    </>
                  )}
                </ReactImageUploading>
              </Col>
            </Row>
            {/* Bowling Style */}
            <div className="heading border-0 w-100">
              <p className="mb-0 pt-3">BOWLING STYLE:</p>
              <Row className="justify-content-center">
                {/* Pace Options */}
                <Col xs={6}>
                  <Form.Group className="position-relative">
                    <Form.Select
                      disabled={spinOptions.some((opt) => opt === bowlingStyle)}
                      onChange={handlePaceChange}
                      value={
                        paceOptions.includes(bowlingStyle) ? bowlingStyle : ""
                      }
                    >
                      <option value="">Select Pace Style</option>
                      {paceOptions.map((pace, index) => (
                        <option key={index} value={pace}>
                          {pace}
                        </option>
                      ))}
                    </Form.Select>
                    {bowlingStyle && paceOptions.includes(bowlingStyle) && (
                      <Button
                        variant="light"
                        className=""
                        onClick={resetBowlingStyle}
                        style={{
                          position: "absolute",
                          right: "20px",
                          bottom: "0",
                          border: "none",
                          background: "none",
                          fontSize: "1.2rem",
                          color: "#dc3545",
                        }}
                      >
                        x
                      </Button>
                    )}
                  </Form.Group>
                </Col>

                {/* Spin Options */}
                <Col xs={6}>
                  <Form.Group className="position-relative">
                    <Form.Select
                      disabled={paceOptions.some((opt) => opt === bowlingStyle)}
                      onChange={handleSpinChange}
                      value={
                        spinOptions.includes(bowlingStyle) ? bowlingStyle : ""
                      }
                    >
                      <option value="">Select Spin Style</option>
                      {spinOptions.map((spin, index) => (
                        <option key={index} value={spin}>
                          {spin}
                        </option>
                      ))}
                    </Form.Select>
                    {bowlingStyle && spinOptions.includes(bowlingStyle) && (
                      <Button
                        variant="light"
                        className=""
                        onClick={resetBowlingStyle}
                        style={{
                          position: "absolute",
                          right: "20px",
                          bottom: "0",
                          border: "none",
                          background: "none",
                          fontSize: "1.2rem",
                          color: "#dc3545",
                        }}
                      >
                        x
                      </Button>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <br />
            {/* Submit Buttons */}
            <SingleButton
              content={
                createUpdateLoading ? (
                  <Spinner size="sm" />
                ) : isEdit ? (
                  "UPDATE PLAYER"
                ) : (
                  "CREATE PLAYER"
                )
              }
              onClick={handleSubmit}
              backgroundColor={"rgba(231, 50, 147, 1)"}
              color={"#fff"}
              buttonStyles={{
                width: "100%",
              }}
            />
          </Container>
        )}
      </section>
    </Layout>
  );
};

export default CreateEditPlayers;
