import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const WormChart = () => {
  const [selectedOption, setSelectedOption] = useState("Pakistan");

  // Sample data for both teams
  const teamData = {
    Pakistan: [
      2, 10, 15, 22, 30, 45, 50, 60, 72, 85, 95, 105, 120, 125, 135, 140, 150,
      160, 170, 180,
    ],
    India: [
      5, 12, 18, 25, 35, 42, 55, 65, 75, 90, 100, 110, 115, 130, 140, 145, 155,
      165, 175, 185,
    ],
  };

  // Chart Data Configuration
  const chartData = {
    labels: Array.from({ length: 20 }, (_, i) => i + 1),
    datasets: [
      selectedOption !== "India" && {
        label: "Pakistan Runs",
        data: teamData.Pakistan,
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        tension: 0.4,
      },
      selectedOption !== "Pakistan" && {
        label: "India Runs",
        data: teamData.India,
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        tension: 0.4,
      },
    ].filter(Boolean), // Remove null values if only one dataset is selected
  };

  // Chart options
  const options = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: "Overs" } },
      y: { title: { display: true, text: "Total Runs" } },
    },

  };

  return (
    <div className="worm px-5 py-2">
      {/* Team Selection */}
      <div className="team-selector">
        {["Pakistan", "India", "Both"].map((option) => (
          <label key={option} className="mx-2">
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={() => setSelectedOption(option)}
            />{" "}
            {option}
          </label>
        ))}
      </div>
      {/* Line Graph */}
      <Line data={chartData} options={options} />
    </div>
  );
};

export default WormChart;
