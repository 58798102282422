import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";

const BowlingCard = ({ handleClose }) => {
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);
  const [tableHead, setTableHead] = useState();

  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    console.log("Rows per page changed to:", newPerPage, page);
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  useEffect(() => {
    setTableData([
      {
        batsman: "Virat Kohli",
        runs: "100",
        balls: "50",
        zeros: "10",
        ones: "10",
        twos: "10",
        threes: "10",
        fours: "10",
        sixes: "10",
        strikeRate: "100",
      },
      {
        batsman: "Rohit Sharma",
        runs: "100",
        balls: "50",
        zeros: "10",
        ones: "10",
        twos: "10",
        threes: "10",
        fours: "10",
        sixes: "10",
        strikeRate: "100",
      },
      {
        batsman: "Rishabh Pant",
        runs: "100",
        balls: "50",
        zeros: "10",
        ones: "10",
        twos: "10",
        threes: "10",
        fours: "10",
        sixes: "10",
        strikeRate: "100",
      },
    ]);

    setTableHead([
      {
        name: "Batsman",
        cell: (row) => <p className="m-0 text-start">{row.batsman}</p>,
        sortable: true,
        width: "150px", // Wider first column
      },
      {
        name: "Runs",
        selector: (row) => row.runs,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "Balls",
        selector: (row) => row.balls,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "0s",
        selector: (row) => row.zeros,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "1s",
        selector: (row) => row.ones,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "2s",
        selector: (row) => row.twos,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "3s",
        selector: (row) => row.threes,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "4s",
        selector: (row) => row.fours,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "6s",
        selector: (row) => row.sixes,
        width: "60px",
        sortable: true,
        center: true,
      },
      {
        name: "Strike Rate",
        selector: (row) => row.strikeRate,
        width: "80px",
        sortable: true,
        center: true,
      },
    ]);
  }, []);

  return (
    <section
      id="BattingScoreCard"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
          />
        </div>
        <h1 className="match-scoring-modal-heading">BOWLING SCORE CARD</h1>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <DataTable
            columns={tableHead}
            data={tableData}
            paginationPerPage={perPage}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            pagination
            paginationServer
            highlightOnHover
            responsive
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            noDataComponent={<p>No data found</p>}
            customStyles={{
              table: {},
              headRow: {
                style: {
                  backgroundColor: "white", // White background for header row
                  fontWeight: "bold",
                },
              },
              headCells: {
                style: {
                  // border: "1px solid white", // White border for each header cell
                  color: "black", // Black text for better visibility
                  textAlign: "center",
                  fontSize: "14px",
                  padding: "10px",
                },
              },
              rows: {
                style: {
                  backgroundColor: "transparent", // Transparent background for body rows
                },
              },
              cells: {
                style: {
                  border: "1px solid white", // White border for each body cell
                  color: "white", // White text
                  textAlign: "center",
                  fontSize: "14px",
                  padding: "10px",
                },
              },
            }}
          />
        </div>
      </Container>
    </section>
  );
};

export default BowlingCard;
