import React, { useRef, useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import SingleButton from "../../constants/SingleButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompress,
  faExpand,
  faPause,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";

const MAX_VIDEO_SIZE_MB = 5 * 1024 * 1024; // 5MB in bytes

const CameraRecorder = ({
  recordedVideo,
  setRecordedVideo,
  showCamera,
  setShowCamera,
  saveBall,
  videoBlob,
  setVideoBlob,
  // undoBall,
}) => {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]); // Use ref to store chunks
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false); // New state for pause/resume
  const [devices, setDevices] = useState([]); // List of available cameras
  const [selectedDeviceId, setSelectedDeviceId] = useState(""); // Currently selected camera
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Fetch available video input devices (cameras)
  useEffect(() => {
    const getCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setDevices(videoDevices);

        // Set the first camera as default
        if (videoDevices.length > 0) {
          setSelectedDeviceId(videoDevices[0].deviceId);
        }
      } catch (error) {
        console.error("Error fetching camera devices:", error);
      }
    };

    getCameras();
  }, []);

  // Start camera based on selected device
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
        },
        audio: true,
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        // const blob = new Blob(recordedChunksRef.current, {
        //   type: "video/webm",
        // });
          const blob = new Blob(recordedChunksRef.current, {
            type: "video/mp4",
          });


        // Check video size before storing
        if (blob.size > MAX_VIDEO_SIZE_MB) {
          alert("Video exceeds 5MB. Please record a shorter video.");
          return;
        }

        setVideoBlob(blob); // ✅ Save the Blob for API request
        setRecordedVideo(URL.createObjectURL(blob));
        recordedChunksRef.current = [];
        setShowCamera(false); // Hide camera after recording stops
      };
    } catch (error) {
      console.error("Error accessing camera and microphone:", error);
    }
  };

  // Start recording
  const startRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "inactive"
    ) {
      recordedChunksRef.current = []; // Reset stored chunks before new recording
      mediaRecorderRef.current.start();
      setRecording(true);
      setPaused(false);
    }
  };

  // Stop recording - now works even if paused
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      (mediaRecorderRef.current.state === "recording" ||
        mediaRecorderRef.current.state === "paused")
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      setPaused(false);
    }
  };

  // Pause/Resume recording
  const pauseResumeRecording = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.pause();
        setPaused(true);
      } else if (mediaRecorderRef.current.state === "paused") {
        mediaRecorderRef.current.resume();
        setPaused(false);
      }
    }
  };

  // Remove the recorded video
  const cancelRecordedVideo = () => {
    setRecordedVideo(null);
    setShowCamera(true); // Show camera again after canceling the video
  };

  // Handle adding a video from the device
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_VIDEO_SIZE_MB) {
        alert("Uploaded video exceeds 5MB. Please select a smaller file.");
        return;
      }
      const videoURL = URL.createObjectURL(file);
      setRecordedVideo(videoURL);
      setShowCamera(false);
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => setIsFullScreen(true))
        .catch((err) => console.error("Error enabling full-screen mode:", err));
    } else {
      document.exitFullscreen().then(() => setIsFullScreen(false));
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Show recorded video */}
      {recordedVideo && (
        <div
          style={{
            height: "43vh",
            width: "auto",
            border: "0.5px solid black",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000",
            overflow: "hidden",
          }}
        >
          <video
            src={recordedVideo}
            controls
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
            }}
          />
          <button
            onClick={cancelRecordedVideo}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "red",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            ✕
          </button>
        </div>
      )}

      {showCamera && !recordedVideo && (
        <div
          style={{
            height: "43vh",
            width: "auto",
            border: "0.5px solid black",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000",
            overflow: "hidden",
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            muted
            style={{ width: "100%", height: "100%" }}
          ></video>
        </div>
      )}

      {/* Recording Controls */}
      <div
        style={{
          height: "6vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 6px",
        }}
      >
        <span className="d-flex align-items-center">
          <SingleButton
            backgroundColor={"rgba(231, 50, 147, 1)"}
            color={"#fff"}
            content={"Start Source"}
            onClick={startCamera}
            buttonStyles={{ fontSize: "10px", padding: "5px 8px" }}
          />
          <Form.Select
            aria-label="Camera"
            onChange={(e) => setSelectedDeviceId(e.target.value)}
            value={selectedDeviceId}
            style={{ fontSize: "10px" }}
            required
          >
            <option value="" disabled>
              Select Camera
            </option>
            {devices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${device.deviceId}`}
              </option>
            ))}
          </Form.Select>
        </span>
        <span className="d-flex align-items-center">
          {recording ? (
            <>
              <LazyLoadImage
                src={Images.recording}
                alt="icon"
                width={20}
                className="me-4"
                onClick={stopRecording}
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                icon={paused ? faPlay : faPause}
                onClick={pauseResumeRecording}
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  marginRight: "10px",
                  color: "#fff",
                }}
              />
            </>
          ) : (
            <LazyLoadImage
              src={Images.startCamera}
              alt="icon"
              width={20}
              className="me-4"
              onClick={startRecording}
              style={{ cursor: "pointer" }}
            />
          )}
        </span>
        <SingleButton
          backgroundColor={"rgba(231, 50, 147, 1)"}
          color={"#fff"}
          content={"Save"}
          onClick={saveBall}
        />
        <FontAwesomeIcon
          icon={isFullScreen ? faCompress : faExpand}
          onClick={toggleFullScreen}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            marginLeft: "10px",
            color: "#fff",
          }}
        />
      </div>
    </div>
  );
};

export default CameraRecorder;
