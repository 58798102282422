import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register necessary ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  // Sample data for types of outs
  const outTypes = {
    Bowled: 5,
    Caught: 7,
    LBW: 3,
    Runout: 2,
  };

  // Chart Data Configuration
  const chartData = {
    labels: Object.keys(outTypes),
    datasets: [
      {
        label: "Types of Outs",
        data: Object.values(outTypes),
        backgroundColor: ["#FF5733", "#36A2EB", "#FFCE56", "#4CAF50"], // Different colors for each type
        borderColor: ["#FF5733", "#36A2EB", "#FFCE56", "#4CAF50"],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: { position: "right" },
    },
  };

  return (
    <div className="pie-chart px-5 py-2" style={{ width: "40%" }}>
      <Pie data={chartData} options={options} />
      <h3 className="heading2 fw-bolder">Pakistan</h3>
    </div>
  );
};

export default PieChart;
