// import ForgetPassword from "../views/auth/ForgetPassword";
// import Login from "../views/auth/Login";
// import OTP from "../views/auth/OTP";
import Dashboard from "../views/private/Dashboard";
import ProfileSettings from "../views/private/ProfileSettings";
import StartMatch from "../views/private/StartMatch";
import UserManagement from "../views/private/UserManagement";
import CreateTournament from "../views/private/Tournament/CreateTournament";
import NewPassword from "../views/private/ChangePassword";
import SiteSettings from "./SiteSettings";
import { useDynamicTitle, useDynamicViewport } from "./documentSettings";
import TeamSelection from "../views/private/Tournament/TeamSelection";
import CreateTeam from "../views/private/CreateTeam";
import CreatePlayer from "../views/private/CreatePlayer";
import ExistingPlayer from "../views/private/ExistingPlayer";
import GetMatchReport from "../views/private/GetMatchReport";
import History from "../views/private/History";
import Previous from "../views/private/Previous";
import PlayerStatsReport from "../views/private/PlayerStatsReport";
import MatchScoring from "../views/private/MatchScoring";
import MatchSummary from "../views/private/MatchSummary";
// import ResetPassword from "../views/auth/ResetPassword";
import AllAdmin from "../views/private/Admin/AllAdmin";
import AllSuperAdmins from "../views/private/SuperAdmin/AllSuperAdmins";
import AllTeams from "../views/private/Team/AllTeams";
import AllPlayers from "../views/private/Players/AllPlayers";
import CreateEditPlayers from "../views/private/Players/CreateEditPlayers";
import CreateEditTeam from "../views/private/Team/CreateEditTeam";
import AddNewPlayers from "../views/private/Team/AddNewPlayers";
import SuperAdminLogin from "../views/auth/Super Admin/SuperAdminLogin";
import SuperAdminForgetPassword from "../views/auth/Super Admin/SuperAdminForgetPassword";
import SuperAdminOTP from "../views/auth/Super Admin/SuperAdminOTP";
import SuperAdminResetPassword from "../views/auth/Super Admin/SuperAdminResetPassword";
import Login from "../views/auth/User/Login";
import ForgetPassword from "../views/auth/User/ForgetPassword";
import OTP from "../views/auth/User/OTP";
import ResetPassword from "../views/auth/User/ResetPassword";
import ChangePassword from "./../views/private/ChangePassword";
import AllTournaments from "../views/private/Tournament/AllTournaments";
import CreateEditTournament from "../views/private/Tournament/CreateEditTournament";

function _allAdmin() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | All Admin`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AllAdmin />;
}

function _allSuperAdmins() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | All Super Admins`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AllSuperAdmins />;
}

function _getAllTeams() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | All Teams`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AllTeams />;
}

function _getAllTournaments() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | All Tournaments`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AllTournaments />;
}

function _createEditTournament() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create Tournament`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreateEditTournament />;
}

function _login() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Login`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Login />;
}

function _forgetPassword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Forget Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ForgetPassword />;
}
function _otp() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | OTP`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <OTP />;
}
function _resetPasword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Reset Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ResetPassword />;
}

function _superAdminlogin() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Super Admin Login`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SuperAdminLogin />;
}

function _superAdminForgetPassword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Super Admin Forget Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SuperAdminForgetPassword />;
}
function _superAdminOtp() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Super Admin OTP`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SuperAdminOTP />;
}
function _superAdminResetPasword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Super Admin Reset Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SuperAdminResetPassword />;
}

// function _adminlogin() {
//   useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin Login`);
//   useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
//   return <AdminLogin />;
// }

// function _adminForgetPassword() {
//   useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin Forget Password`);
//   useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
//   return <AdminForgetPassword />;
// }
// function _adminOtp() {
//   useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin OTP`);
//   useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
//   return <AdminOTP />;
// }
// function _adminResetPasword() {
//   useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin Reset Password`);
//   useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
//   return <AdminResetPassword />;
// }

function _dashboard() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Dashboard`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Dashboard />;
}

function _changePassword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | New Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ChangePassword />;
}

function _startMatch() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Start Match`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <StartMatch />;
}

function _createTournament() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create Tournament`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreateTournament />;
}

function _profileSettings() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Profile Settings`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ProfileSettings />;
}

function _userManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | User Management`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <UserManagement />;
}

function _teamSelection() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Team Selection`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <TeamSelection />;
}

function _createTeam() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create Team`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreateTeam />;
}

function _createPlayer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create Player`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreatePlayer />;
}

function _existingPlayer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Existing Player`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ExistingPlayer />;
}

function _getMatchReport() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Get Match Report`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <GetMatchReport />;
}

function _history() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | History`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <History />;
}

function _previous() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Previous Matches`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Previous />;
}

function _playerStatsReport() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Player Stats Report`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <PlayerStatsReport />;
}

function _matchScoring() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Match Scoring`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <MatchScoring />;
}

function _matchSummary() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Match Summary`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <MatchSummary />;
}

function _getAllPlayers() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | All Players`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AllPlayers />;
}

function _createEditPlayer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create/Edit Player`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreateEditPlayers />;
}

function _createEditTeam() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Create/Edit Team`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <CreateEditTeam />;
}

function _addNewPlayer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Add New Player`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddNewPlayers />;
}

const routes = [
  {
    path: "/",
    name: "Home",
    icon: "",
    component: _dashboard,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard",
    name: "Home",
    icon: "",
    component: _dashboard,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/all-users",
    name: "Admin",
    icon: "",
    component: _allAdmin,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/all-admin",
    name: "Super Admin",
    icon: "",
    component: _allSuperAdmins,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/change-password",
    name: "Super Admin New Password",
    icon: "",
    component: _changePassword,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/profile-settings",
    name: "Profile Settings",
    icon: "",
    component: _profileSettings,
    layout: "PRIVATE",
    show: "no",
  },
  {
    path: "/dashboard/all-teams",
    name: "All Teams",
    icon: "",
    component: _getAllTeams,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/all-players",
    name: "All Players",
    icon: "",
    component: _getAllPlayers,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/create-player",
    name: "Create Player",
    icon: "",
    component: _createEditPlayer,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/edit-player",
    name: "Edit Player",
    icon: "",
    component: _createEditPlayer,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/add-new-player",
    name: "Add New Player",
    icon: "",
    component: _addNewPlayer,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/create-team",
    name: "Create Team",
    icon: "",
    component: _createEditTeam,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/edit-team",
    name: "Edit Team",
    icon: "",
    component: _createEditTeam,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/all-tournament",
    name: "All Tournament",
    icon: "",
    component: _getAllTournaments,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/create-tournament",
    name: "Create Tournament",
    icon: "",
    component: _createEditTournament,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/edit-tournament",
    name: "Edit Tournament",
    icon: "",
    component: _createEditTournament,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/start-match",
    name: "Start Match",
    icon: "",
    component: _startMatch,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/history",
    name: "History",
    icon: "",
    component: _history,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/previous",
    name: "Previous",
    icon: "",
    component: _previous,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/match-scoring",
    name: "Match Scoring",
    icon: "",
    component: _matchScoring,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "/dashboard/match-summary",
    name: "Match Summary",
    icon: "",
    component: _matchSummary,
    layout: "PRIVATE",
    show: "no",
    accessRequired: false,
  },
  {
    path: "",
    name: "Tournament",
    icon: "",
    component: "",
    layout: "PRIVATE",
    show: "yes",
    accessRequired: false,
    subMenu: [
      {
        path: "/create-tournament",
        name: "Create Tournament",
        icon: "",
        component: _createTournament,
        layout: "PRIVATE",
        show: "no",
      },
      {
        path: "/team-selection",
        name: "Team Selection",
        icon: "",
        component: _teamSelection,
        layout: "PRIVATE",
        show: "no",
      },
      {
        path: "/team",
        name: "Teams",
        icon: "",
        component: _createTeam,
        layout: "PRIVATE",
        show: "no",
      },
      {
        path: "/player",
        name: "Create Player",
        icon: "",
        component: _createPlayer,
        layout: "PRIVATE",
        show: "no",
      },
      {
        path: "/existing-player",
        name: "Existing Player",
        icon: "",
        component: _existingPlayer,
        layout: "PRIVATE",
        show: "no",
      },
    ],
  },
  // {
  //   path: "",
  //   name: "Previous (Match Scoring)",
  //   icon: "",
  //   component: "",
  //   layout: "PRIVATE",
  //   show: "yes",
  //   accessRequired: false,
  //   subMenu: [
  //     {
  //       path: "/previous",
  //       name: "Previous",
  //       icon: "",
  //       component: _previous,
  //       layout: "PRIVATE",
  //     },
  //     {
  //       path: "/match-scoring",
  //       name: "Match Scoring",
  //       icon: "",
  //       component: _matchScoring,
  //       layout: "PRIVATE",
  //     },
  //   ],
  // },
  // {
  //   path: "/player-stats-report",
  //   name: "Player Stats Report",
  //   icon: "",
  //   component: _playerStatsReport,
  //   layout: "PRIVATE",
  //   show: "yes",
  //   accessRequired: true,
  // },
  // {
  //   path: "/user-management",
  //   name: "User Management",
  //   icon: "",
  //   component: _userManagement,
  //   layout: "PRIVATE",
  //   show: "yes",
  //   accessRequired: true,
  // },
  // {
  //   path: "",
  //   name: "History (Match Summary)",
  //   icon: "",
  //   component: "",
  //   layout: "PRIVATE",
  //   show: "yes",
  //   accessRequired: true,
  //   subMenu: [
  //     {
  //       path: "/history",
  //       name: "History",
  //       icon: "",
  //       component: _history,
  //       layout: "PRIVATE",
  //     },
  //     {
  //       path: "/match-summary",
  //       name: "Match Summary",
  //       icon: "",
  //       component: _matchSummary,
  //       layout: "PRIVATE",
  //     },
  //   ],
  // },
  {
    path: "/user/login",
    name: "Login",
    icon: "",
    component: _login,
    layout: "PUBLIC",
    show: "no",
  },
  {
    path: "/user/forget-password",
    name: "Forget Password",
    icon: "",
    component: _forgetPassword,
    layout: "PUBLIC",
    show: "no",
  },
  {
    path: "/user/otp",
    name: "OTP",
    icon: "",
    component: _otp,
    layout: "PUBLIC",
    show: "no",
  },
  {
    path: "/user/reset-password",
    name: "Reset Password",
    icon: "",
    component: _resetPasword,
    layout: "PUBLIC",
    show: "no",
  },

  {
    path: "/superadmin/login",
    name: "Super Admin Login",
    icon: "",
    component: _superAdminlogin,
    layout: "PUBLIC",
    show: "no",
  },
  {
    path: "/superadmin/forget-password",
    name: "Super Admin Forget Password",
    icon: "",
    component: _superAdminForgetPassword,
    layout: "PUBLIC",
    show: "no",
  },
  {
    path: "/superadmin/otp",
    name: "Super Admin OTP",
    icon: "",
    component: _superAdminOtp,
    layout: "PUBLIC",
    show: "no",
  },
  {
    path: "/superadmin/reset-password",
    name: "Super Admin Reset Password",
    icon: "",
    component: _superAdminResetPasword,
    layout: "PUBLIC",
    show: "no",
  },
  // {
  //   path: "/admin-login",
  //   name: "Super Admin Login",
  //   icon: "",
  //   component: _adminlogin,
  //   layout: "PUBLIC",
  //   show: "no",
  // },
  // {
  //   path: "/admin-forget-password",
  //   name: "Super Admin Forget Password",
  //   icon: "",
  //   component: _adminForgetPassword,
  //   layout: "PUBLIC",
  //   show: "no",
  // },
  // {
  //   path: "/admin-otp",
  //   name: "Super Admin OTP",
  //   icon: "",
  //   component: _adminOtp,
  //   layout: "PUBLIC",
  //   show: "no",
  // },
  // {
  //   path: "/admin-reset-password",
  //   name: "Super Admin Reset Password",
  //   icon: "",
  //   component: _adminResetPasword,
  //   layout: "PUBLIC",
  //   show: "no",
  // },
];

export default routes;
