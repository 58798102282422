import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import SingleButton from "../SingleButton";

const DLS = ({ handleClose, matchDetails, setMatchDetails }) => {
  const [dlsOvers, setDlsOvers] = useState(""); // Overs for both teams
  const [dlsScore, setDlsScore] = useState(); // Target for second innings
  const [confirmChanges, setConfirmChanges] = useState(false);

  // Check if it's the first or second innings
  const isSecondInnings = matchDetails?.match?.teams[1]?.ballsPlayed > 0;

  const handleSave = () => {
    if (!dlsOvers || (isSecondInnings && !dlsScore)) {
      alert("Please enter the required DLS values.");
      return;
    }

    setConfirmChanges(true); // Show confirmation before applying changes
  };

  const applyDLSChanges = () => {
    setMatchDetails((prev) => {
      let updatedMatch = { ...prev };

      if (!isSecondInnings) {
        // First innings: Update DLS overs for both teams
        updatedMatch.match.teams[0].dlsOvers = dlsOvers;
        updatedMatch.match.teams[1].dlsOvers = dlsOvers;
      } else {
        // Second innings: Update DLS score and overs
        updatedMatch.match.teams[0].dlsOvers = dlsOvers;
        updatedMatch.match.teams[1].dlsScore = dlsScore;
      }

      updatedMatch.match.isDlsApplied = true;

      return updatedMatch;
    });

    setConfirmChanges(false); // Close confirmation
    handleClose(); // Close modal
  };

  return (
    <section
      id="dlsModal"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
          />
        </div>
        <h1 className="match-scoring-modal-heading text-light text-center mb-5">
          {isSecondInnings ? "DLS for 2nd Innings" : "DLS for 1st Innings"}
        </h1>

        {/* DLS Overs Input */}
        <Form.Group className="mb-3 text-light">
          <Form.Label>Enter DLS Overs</Form.Label>
          <Form.Control
            type="number"
            value={dlsOvers}
            onChange={(e) => setDlsOvers(e.target.value)}
            placeholder="Enter updated overs"
          />
        </Form.Group>

        {/* DLS Score Input (Only for Second Innings) */}
        {isSecondInnings && (
          <Form.Group className="mb-3 text-light">
            <Form.Label>Enter DLS Target Score</Form.Label>
            <Form.Control
              type="number"
              value={dlsScore}
              onChange={(e) => setDlsScore(e.target.value)}
              placeholder="Enter new target score"
            />
          </Form.Group>
        )}

        <div className="d-flex flex-column align-items-center">
          <SingleButton
            content="Save"
            onClick={handleSave}
            backgroundColor="rgba(231, 50, 147, 1)"
            color="#fff"
            buttonStyles={{ width: "200px", marginTop: "10px" }}
          />
        </div>

        {/* Confirmation Modal */}
        {confirmChanges && (
          <div className="d-flex flex-column align-items-center mt-4">
            <p className="text-light">
              Are you sure you want to apply DLS changes?
            </p>
            <div className="d-flex gap-3">
              <SingleButton
                content="Yes"
                onClick={applyDLSChanges}
                backgroundColor="green"
                color="#fff"
              />
              <SingleButton
                content="No"
                onClick={() => setConfirmChanges(false)}
                backgroundColor="red"
                color="#fff"
              />
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

export default DLS;
