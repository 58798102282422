import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { getRequest, postRequestForm } from "../../../helper/api";
import DataTable from "react-data-table-component";
import { Images } from "../../../constants/Images";
import Layout from "../../../layouts/Layout";
import BackButton from "../../../constants/BackButton";
import PairButtons from "../../../constants/PairButtons";
import DatePicker from "react-datepicker";
import { getItemFromLocalStorage } from "../../../helper/helper";
import EditTournamentSkeleton from "../../../constants/Skeleton Loaders/EditTournamentSkeleton";
import TableSkeleton from "../../../constants/Skeleton Loaders/TableSkeleton";

const CreateEditTournament = () => {
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [tournamentId, setTournamentId] = useState("");
  const [fetchTeamLoader, setFetchTeamLoader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [teamsShow, setTeamsShow] = useState(false);
  const [existingTeams, setExistingTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [tournamentName, setTournamentName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tournamentType, setTournamentType] = useState("FC");

  const tournamentTypeOptions = [
    { value: "FC", label: "FC" },
    { value: "LIST-A", label: "LIST-A" },
    { value: "WT20", label: "WT20" },
    { value: "WT20I", label: "WT20I" },
    { value: "WODI", label: "WODI" },
    { value: "WLIST-A", label: "WLIST-A" },
    { value: "100", label: "100" },
    { value: "T20I", label: "T20I" },
    { value: "ODI", label: "ODI" },
    { value: "T20", label: "T20" },
    { value: "TEST", label: "TEST" },
    { value: "T10", label: "T10" },
  ];

  const [searching, setSearching] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };
  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearching(searchValue);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log("Rows per page changed to:", newPerPage, page);
    setPerPage(newPerPage);
    setCurrentPage(page); // Reset to the first page
  };

  const handleCheckboxChange = (team) => {
    const isSelected = selectedTeams.some((t) => t._id === team._id);
    setSelectedTeams((prev) =>
      isSelected ? prev.filter((t) => t._id !== team._id) : [...prev, team]
    );

    setExistingTeams((prev) =>
      prev.map((t) =>
        t._id === team._id ? { ...t, selected: !t.selected } : t
      )
    );
    console.log(selectedTeams);
  };

  const columns = [
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <Form.Check
          type="checkbox"
          id={`checkbox-${row._id}`}
          checked={row.selected}
          onChange={() => handleCheckboxChange(row)}
          style={{
            cursor: "pointer",
            transform: "scale(1.7)",
            backgroundColor: "transparent",
          }}
        />
      ),
      maxWidth: "100px",
    },
    {
      name: "Profile",
      selector: (row) => (
        <LazyLoadImage
          src={row.teamImageUrl || Images.profile}
          alt="Team Image"
          width="40"
          height="40"
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "center",
            border: "1px solid rgba(231, 50, 147, 1)",
          }}
        />
      ),
      maxWidth: "80px",
    },
    {
      name: "Team Name",
      selector: (row) => row.teamName,
      sortable: true,
      style: { justifyContent: "start" },
    },
    {
      name: "Owner",
      cell: (row) => (
        <div
          title={row.owner.email}
          className="d-flex gap-1 justify-content-between align-items-center"
        >
          <div className="me-2" title={row.owner.email}>
            <LazyLoadImage
              src={
                row.owner.profileImageUrl ? row?.owner?.profileImageUrl : null
              }
              alt={row.owner.email}
              width="40"
              height="40"
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                objectPosition: "center",
                border: "1px solid rgba(231, 50, 147, 1)",
              }}
            />
          </div>
          <div className="text-light">
            <p
              style={{
                wordBreak: "break-all",
                margin: 0,
              }}
              title={row.owner.email}
            >
              {row.owner.fullName}
            </p>
          </div>
        </div>
      ),
      maxWidth: "200px",
      sortable: false,
      style: {
        justifyContent: "left",
      },
    },
  ];

  const fetchTournamentDetails = async (id) => {
    try {
      setDataLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result } = await getRequest(
        `/api/secure/v2/tournament/get-tournament`,
        token,
        { tournamentId: id }
      );
      if (result?.status === 200) {
        const { tournament } = result.data;
        setTournamentName(tournament?.tournamentName);
        setTournamentType(tournament?.tournamentType);
        setStartDate(tournament?.startDate);
        setEndDate(tournament?.endDate);
        setTournamentId(tournament?._id);
        const modifiedTeams = tournament?.tournamentTeams?.map((team) => ({
          _id: team?._id,
          name: team?.teamName,
          image: team?.teamImageUrl,
          selected: true,
        }));

        setSelectedTeams(modifiedTeams);
        console.log("modifiedTeams", selectedTeams);
      }
    } catch (error) {
      toast.error(error.message, { position: "top-center" });
    } finally {
      setDataLoading(false);
    }
  };

  const handleCreateTournament = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!tournamentName || !tournamentType || !startDate || !endDate) {
        toast.error("Please fill all fields and select teams.", {
          position: "top-center",
        });
        return;
      }

      const payload = {
        tournamentName,
        tournamentType: tournamentType,
        startDate,
        endDate,
        tournamentTeams: selectedTeams.map((team) => team._id),
      };

      const user = JSON.parse(getItemFromLocalStorage("USER"));
      const token = getItemFromLocalStorage("TOKEN");
      if (isEdit) {
        payload.tournamentId = tournamentId;
      }
      if (!isEdit) {
        const ownerType = user.roleName?.replace(" ", "");
        payload.ownerType = ownerType;
      }
      const endpoint = isEdit
        ? `/api/secure/v2/tournament/edit-tournament`
        : `/api/secure/v2/tournament/register-tournament`;

      const { result, error } = await postRequestForm(endpoint, token, payload);

      if (result?.status === 200) {
        toast.success("Tournament created successfully.", {
          position: "top-center",
        });
        navigate("/dashboard/all-tournament");
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, { position: "top-center" });
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };

  const fetchExitingTeams = async (currentPage, perPage, searching) => {
    try {
      setFetchTeamLoader(true);
      const token = getItemFromLocalStorage("TOKEN");
      const queryParams = new URLSearchParams(search);
      const id = queryParams.get("_id");

      const endpoint = id
        ? `/api/secure/v2/team/get-teams-by-tournament`
        : `/api/secure/v2/team/get-teams`;

      const { result, error } = await getRequest(endpoint, token, {
        page: currentPage,
        limit: perPage,
        search: searching,
        tournamentId: id,
      });

      if (result?.status === 200) {
        const teams = result.data.teams.map((team) => ({
          ...team,
          //   selected: selectedTeams.some((t) => t._id === team._id),
        }));
        setExistingTeams(result.data.teams);
        setTotalRows(result.data.totalTeams);
        console.log("selectedTeams", selectedTeams);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch existing teams."
        );
      }

      setFetchTeamLoader(false);
    } catch (err) {
      toast.error(err.message || "Error fetching teams.", {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    fetchExitingTeams(currentPage, perPage, searching);
  }, [currentPage, perPage, searching]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("_id");
    console.log("id", id);

    if (id) {
      setIsEdit(true);
      fetchTournamentDetails(id);
    }
  }, [search]);

  return (
    <Layout>
      <section
        id="createEditTournament"
        style={{
          // backgroundImage: `url(${Images.background3})`,
          // backgroundSize: "cover",
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        {" "}
        <Container>
          {/* <div className="d-flex justify-content-between align-items-center"> */}
          <BackButton
            icon={Images.backArrow}
            content="Back"
            onClick={() => navigate("/dashboard/all-tournament")}
          />
          <p className="text-light pt-3 text-center mt-0 font-secondary fs-4 fw-bold">
            {isEdit ? "UPDATE TOURNAMENT" : "CREATE TOURNAMENT"}
          </p>
          <div></div>
          {/* </div> */}
        </Container>
        {teamsShow ? (
          <Container className="logo-table-container mt-5">
            <div className="table-container">
              <div className="pb-3 heading d-flex align-items-center border-0">
                <h3 className="mb-0 me-3 heading">Search Team</h3>
                <FormControl
                  type="text"
                  placeholder="Name"
                  className="w-25 fs-6 p-0 ps-2"
                  value={searching}
                  onChange={handleSearchCategory}
                />
              </div>
              {fetchTeamLoader ? (
                <TableSkeleton
                  row={perPage}
                  head={[
                    {
                      name: "Actions",
                      single: true,
                      className:
                        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell justify-content-center",
                      width: 30,
                      height: 30,
                    },
                    {
                      name: "Profile",
                      className:
                        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv elpSoT bOmZtP rdt_TableCell",
                      width: 30,
                      height: 30,
                      circle: true,
                    },
                    {
                      name: "Team Name",
                      className:
                        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                      width: 150,
                      height: 20,
                    },
                    {
                      name: "Owner",
                      className:
                        "ssc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv kPFmFZ jEzKnw rdt_TableCell",
                      width: [30, 100], // Profile image width and name width
                      height: [30, 20], // Profile image height and name height
                      circle: true,
                    },
                  ]}
                />
              ) : (
                <DataTable
                  columns={columns}
                  data={existingTeams}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  noDataComponent={<p>No data found</p>}
                  customStyles={{
                    headCells: {
                      style: {
                        border: "1px solid #ccc",
                        color: "#fff",
                      },
                    },
                    cells: {
                      style: {
                        border: "1px solid #ccc",
                        color: "#fff",
                        height: "55px",
                      },
                    },
                  }}
                />
              )}
            </div>
            <br />
            <PairButtons
              content1="Cancel"
              onClick1={() => setTeamsShow(false)}
              backgroundColor1={"#fff"}
              color1={"#000"}
              icon1={Images.backArrowButton}
              content2={
                loading ? <Spinner size="sm" /> : isEdit ? "Update" : "Create"
              }
              onClick2={handleCreateTournament}
              backgroundColor2={"rgba(231, 50, 147, 1)"}
              color2={"#fff"}
              icon2={Images.plus}
            />
          </Container>
        ) : (
          <>
            {dataLoading ? (
              <EditTournamentSkeleton />
            ) : (
              <Container className="image-input-container mt-5">
                <InputGroup className="mb-3" size="lg">
                  <InputGroup.Text>
                    <LazyLoadImage src={Images.user} alt="icon" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Tournament Name"
                    onChange={(e) => setTournamentName(e.target.value)}
                    value={tournamentName}
                    required
                  />
                </InputGroup>

                {/* Tournament Type */}
                <InputGroup className="mb-3" size="lg">
                  <InputGroup.Text>
                    <LazyLoadImage src={Images.tournamentType} alt="icon" />
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Tournament Type"
                    onChange={(e) => setTournamentType(e.target.value)}
                    value={tournamentType}
                    required
                  >
                    <option value="" disabled>
                      Select Tournament Type
                    </option>
                    {tournamentTypeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>

                {/* Start Date */}
                <InputGroup className="mb-3" size="lg">
                  <InputGroup.Text>
                    <LazyLoadImage src={Images.calendar} alt="icon" />
                  </InputGroup.Text>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Start Date"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    required
                  />
                </InputGroup>

                {/* End Date */}
                <InputGroup className="mb-3" size="lg">
                  <InputGroup.Text>
                    <LazyLoadImage src={Images.calendar} alt="icon" />
                  </InputGroup.Text>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="Select End Date"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    required
                  />
                </InputGroup>

                <Button
                  className="btn-default"
                  onClick={() => setTeamsShow(true)}
                >
                  Add Teams
                </Button>
              </Container>
            )}
          </>
        )}
      </section>
    </Layout>
  );
};

export default CreateEditTournament;
