import React, { useRef, useEffect } from "react";
import { Images } from "../../../constants/Images";

const scoreColors = {
  0: "white",
  1: "blue",
  2: "green",
  3: "yellow",
  4: "orange",
  5: "red",
  6: "red",
};

const BallLineTracker = ({ score, ballLineCords, setBallLineCords }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;

    // Load and draw the Ball Line Image
    const img = new Image();
    img.src = Images.ballLine;
    img.onload = () => {
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);

      // Draw section lines and labels
      drawSections(ctx, width, height);

      // Draw the dot if a point is selected
      if (ballLineCords) {
        ctx.beginPath();
        ctx.arc(ballLineCords.x, ballLineCords.y, 4, 0, 2 * Math.PI);
        ctx.fillStyle = ballLineCords.color;
        ctx.fill();
      }
    };
  }, [ballLineCords]);

  const drawSections = (ctx, width, height) => {
    // Flip the sections
    const wideOutsideOffEnd = width * 0.35; // 35% for Wide Outside Off
    const outsideOffEnd = wideOutsideOffEnd + width * 0.1; // 10% for Outside Off
    const stumpsEnd = outsideOffEnd + width * 0.1; // 10% for Stumps
    const legSideEnd = stumpsEnd + width * 0.45; // 45% for Leg Side (right side)

    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;

    // Draw section dividing lines
    ctx.beginPath();
    ctx.moveTo(wideOutsideOffEnd, 0);
    ctx.lineTo(wideOutsideOffEnd, height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(outsideOffEnd, 0);
    ctx.lineTo(outsideOffEnd, height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(stumpsEnd, 0);
    ctx.lineTo(stumpsEnd, height);
    ctx.stroke();

    // Set label styles
    ctx.fillStyle = "black";
    ctx.font = "bold 12px Arial";
    ctx.textAlign = "center";

    // Add section labels
    // ctx.fillText("Wide Outside Off", wideOutsideOffEnd / 2, height - 10);
    // ctx.fillText(
    //   "Outside Off",
    //   wideOutsideOffEnd + (outsideOffEnd - wideOutsideOffEnd) / 2,
    //   height - 10
    // );
    // ctx.fillText(
    //   "Stumps",
    //   outsideOffEnd + (stumpsEnd - outsideOffEnd) / 2,
    //   height - 10
    // );
    // ctx.fillText(
    //   "Leg Side",
    //   stumpsEnd + (legSideEnd - stumpsEnd) / 2,
    //   height - 10
    // );
  };

  const handleCanvasClick = (event) => {

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    const width = canvas.width;
    const wideOutsideOffEnd = width * 0.35;
    const outsideOffEnd = wideOutsideOffEnd + width * 0.1;
    const stumpsEnd = outsideOffEnd + width * 0.1;
    const legSideEnd = stumpsEnd + width * 0.45;

    let category;
    if (mouseX <= wideOutsideOffEnd) {
      category = "Wide Outside Off";
    } else if (mouseX <= outsideOffEnd) {
      category = "Outside Off";
    } else if (mouseX <= stumpsEnd) {
      category = "Stumps";
    } else {
      category = "Leg Side";
    }

    setBallLineCords({
      x: mouseX,
      y: mouseY,
      color: score === null ? "black" : scoreColors[score],
      category,
    });

    console.log(
      `Ball Line Selected: ${mouseX}, ${mouseY}, ${category}`
    );
  };

  return (
      <canvas
        ref={canvasRef}
        width={200}
        height={200}
        style={{
          border: "1px solid black",
          cursor: "pointer",
        }}
        onClick={handleCanvasClick}
      />
  );
};

export default BallLineTracker;
