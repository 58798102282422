import React, { useRef, useEffect } from "react";
import { Images } from "../../../constants/Images";

const scoreColors = {
  0: "white",
  1: "blue",
  2: "green",
  3: "yellow",
  4: "orange",
  5: "red",
  6: "red",
};

const WagonWheelTracker = ({ score, wagonWheelCords, setWagonWheelCords }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = width / 2 - 10;

    // Load and draw the wagon wheel image
    const img = new Image();
    img.src = Images.wagonWheel;
    img.onload = () => {
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);

      // Draw circular boundary
      // ctx.beginPath();
      // ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      // ctx.strokeStyle = "black";
      // ctx.lineWidth = 2;
      // ctx.stroke();

      // Draw sector lines (consistent with WagonWheel)
      for (let i = 0; i < 8; i++) {
        const startAngle = (i * 45 * Math.PI) / 180;
        const x = centerX + radius * Math.cos(startAngle);
        const y = centerY - radius * Math.sin(startAngle);
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(x, y);
        ctx.strokeStyle = "gray";
        ctx.lineWidth = 1;
        ctx.stroke();
      }

      // Draw stored point (if exists)
      if (wagonWheelCords) {
        ctx.beginPath();
        ctx.arc(
          centerX + wagonWheelCords.x,
          centerY - wagonWheelCords.y,
          4,
          0,
          2 * Math.PI
        );
        ctx.fillStyle = wagonWheelCords.color;
        ctx.fill();
      }
    };
  }, [wagonWheelCords]);

  const handleCanvasClick = (event) => {

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;

    // Convert to Cartesian coordinates
    const x = mouseX - centerX;
    const y = -(mouseY - centerY); // Flip y for Cartesian system

    // Calculate angle (same as in WagonWheel)
    let angle = Math.atan2(-y, x) * (180 / Math.PI);
    if (angle < 0) angle += 360; // Convert negative angles to positive range

    // **Use the same section logic as WagonWheel**
    const sectionIndex = (8 - Math.floor(angle / 45)) % 8;
    const sectionLabel = `${sectionIndex * 45}° - ${(sectionIndex + 1) * 45}°`;

    console.log(`Clicked at (${x}, ${y}), Section: ${sectionLabel}`);

    // Store the point
    const newPoint = {
      x,
      y,
      section: sectionLabel,
      color: score === null ? "black" : scoreColors[score],
    };

    setWagonWheelCords(newPoint);
  };

  return (
    <canvas
      ref={canvasRef}
      width={200}
      height={200}
      style={{
        border: "1px solid black",
        cursor: "pointer",
        borderRadius: "50%",
      }}
      onClick={handleCanvasClick}
    />
  );
};

export default WagonWheelTracker;
