import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Form, Modal, Row, Spinner, Toast } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import OptionsButtons from "../../constants/OptionsButtons";
import DataTable from "react-data-table-component";
import OptionCard from "../../constants/OptionsCard";
import SingleButton from "../../constants/SingleButton";
import CameraRecorder from "./CameraRecorder";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import VariationFast from "../../constants/scoring cards/VariationFast";
import VariationSpin from "../../constants/scoring cards/VariationSpin";
import BattingScoreCard from "../../constants/Match Scoring Modal/BattingScoreCard";
import BowlingCard from "../../constants/Match Scoring Modal/BowlingCard";
import Partnership from "../../constants/Match Scoring Modal/Partnership";
import WagonWheel from "../../constants/Match Scoring Modal/WagonWheel";
import PhaseComparison from "../../constants/Match Scoring Modal/PhaseComparison";
import PitchMap from "../../constants/Match Scoring Modal/PitchMap";
import DLS from "../../constants/Match Scoring Modal/DLS";
import EndMatch from "../../constants/Match Scoring Modal/EndMatch";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequest, postRequestForm } from "../../helper/api";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../helper/helper";
import WagonWheelTracker from "./BallTracker/WagonWheelTracker";
import BallLineTracker from "./BallTracker/BallLineTracker";
import BallLine from "../../constants/Match Scoring Modal/BallLine";
import BallLengthTracker from "./BallTracker/BallLengthTracker";
import SelectBowlerModal from "../../constants/SelectBowlerModal";
import { toast } from "react-toastify";
import moment from "moment/moment";
import WicketModal from "../../constants/WicketModal";
import NewInningsModal from "../../constants/NewInningsModal";
import { set, get } from "idb-keyval";

const MatchScoring = () => {
  const { search } = useLocation();
  const location = useLocation();
  const [ballsArray, setBallsArray] = useState(
    location.state?.ballsArray || []
  );
  const [winner, setWinner] = useState(null);
  const [syncing, setSyncing] = useState(false);
  const [zeroOne, setZeroOne] = useState(false);

  const [matchDetailsLoading, setMatchDetailsLoading] = useState(false);
  const [matchDetails, setMatchDetails] = useState();
  const [isSecondInnings, setIsSecondInnings] = useState(false);

  const [dismissedBatsman, setDismissedBatsman] = useState("striker");

  const [isSelectBowlerModal, setIsSelectBowlerModal] = useState(false);
  const [isWicketModal, setIsWicketModal] = useState(false);
  const [newBatsman, setNewBatsman] = useState(null);
  const [fielder, setFielder] = useState(null);
  const [isNewInningsModal, setIsNewInningsModal] = useState(false);
  const [selectedFacingBatsman, setSelectedFacingBatsman] = useState(null);
  const [selectedNonStriker, setSelectedNonStriker] = useState(null);
  const [selectedBowler, setSelectedBowler] = useState(null);

  const [isVariation, setIsVariation] = useState(false);
  const [show, setShow] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [showCamera, setShowCamera] = useState(true); // Toggle camera visibility

  const [ballLengthCords, setBallLengthCords] = useState(null);
  const [ballLineCords, setBallLineCords] = useState(null);
  const [wagonWheelCords, setWagonWheelCords] = useState(null);

  const [tableData1, setTableData1] = useState([]);
  const [score, setScore] = useState(0);
  const scoreOptions = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
  ];

  const [wide, setWide] = useState(null);
  const wideOptions = [
    { label: "WD", value: 1 },
    { label: "WD1", value: 2 },
    { label: "WD2", value: 3 },
    { label: "WD3", value: 4 },
    { label: "WD4", value: 5 },
    { label: "WD5", value: 6 },
    { label: "WD6", value: 7 },
  ];

  const [noBall, setNoBall] = useState(null);
  const noBallOptions = [
    { label: "NB", value: 1 },
    { label: "NB1", value: 2 },
    { label: "NB2", value: 3 },
    { label: "NB3", value: 4 },
    { label: "NB4", value: 5 },
    { label: "NB5", value: 6 },
    { label: "NB6", value: 7 },
  ];

  const [byes, setByes] = useState(null);
  const byesOptions = [
    { label: "BY", value: 0 },
    { label: "BY1", value: 1 },
    { label: "BY2", value: 2 },
    { label: "BY3", value: 3 },
    { label: "BY4", value: 4 },
    { label: "BY5", value: 5 },
    { label: "BY6", value: 6 },
  ];

  const [legByes, setLegByes] = useState(null);
  const legByesOptions = [
    { label: "LB", value: 0 },
    { label: "LB1", value: 1 },
    { label: "LB2", value: 2 },
    { label: "LB3", value: 3 },
    { label: "LB4", value: 4 },
    { label: "LB5", value: 5 },
    { label: "LB6", value: 6 },
  ];

  const [out, setOut] = useState(null);
  const outOptions = [
    { label: "Caught", value: "Caught" },
    { label: "LBW", value: "LBW" },
    { label: "Bowled", value: "Bowled" },
    { label: "Run Out", value: "Run Out" },
    { label: "Stumped", value: "Stumped" },
    { label: "Timed Out", value: "Timed Out" },
    { label: "Retired Out", value: "Retired Out" },
  ];

  const [offShot, setOffShot] = useState(null);
  const offShotOptions = [
    { label: "Square Cut", value: "Square Cut" },
    { label: "Square Drive", value: "Square Drive" },
    { label: "Cover Drive", value: "Cover Drive" },
    { label: "Off Drive", value: "Off Drive" },
    { label: "Offside Punch", value: "Offside Punch" },
    { label: "Offside Push", value: "Offside Push" },
    { label: "Reverse Sweep", value: "Reverse Sweep" },
    { label: "Switch Hit", value: "Switch Hit" },
  ];

  const [technicalError, setTechnicalError] = useState(null);
  const technicalErrorOptions = [
    { label: "Catch Drop", value: "Catch Drop" },
    { label: "Miss Field", value: "Miss Field" },
    { label: "Head Fall", value: "Head Fall" },
    { label: "Poor Calling", value: "Poor Calling" },
    { label: "Coach Note", value: "Coach Note" },
    { label: "Missed Run Out", value: "Missed Run Out" },
    { label: "Missed Stump", value: "Missed Stump" },
    { label: "Plan Against Fast", value: "Plan Against Fast" },
    { label: "Plan Against Spin", value: "Plan Against Spin" },
  ];

  const [feet, setFeet] = useState(null);
  const feetOptions = [
    { label: "No Feet Movement", value: "No Feet Movement" },
    { label: "Stood Deep in Crease", value: "Stood Deep in Crease" },
    { label: "Down the Track", value: "Down the Track" },
    { label: "Walking Out", value: "Walking Out" },
    { label: "Front Feet", value: "Front Feet" },
    { label: "Back Feet", value: "Back Feet" },
    { label: "Move Offside", value: "Move Offside" },
    { label: "Move Legside", value: "Move Legside" },
  ];

  const [legShot, setLegShot] = useState(null);
  const legShotOptions = [
    { label: "Leg-Glance", value: "Leg-Glance" },
    { label: "Flick", value: "Flick" },
    { label: "Sweep", value: "Sweep" },
    { label: "Slog Sweep", value: "Slog Sweep" },
    { label: "On Drive", value: "On Drive" },
    { label: "Pull", value: "Pull" },
    { label: "Hook", value: "Hook" },
    { label: "Paddle sweep", value: "Paddle sweep" },
    { label: "On Side Punch", value: "On Side Punch" },
    { label: "On Side Push", value: "POn Side Push" },
  ];

  const [event, setEvent] = useState(null);
  const eventOptions = [
    { label: "Hit on Pads", value: "Hit on Pads" },
    { label: "Hit on Body", value: "Hit on Body" },
    { label: "Miss Time Shot", value: "Miss Time Shot" },
    { label: "Uncomfortable", value: "Uncomfortable" },
    { label: "Inside Edge", value: "Inside Edge" },
    { label: "Outside Edge", value: "Outside Edge" },
    { label: "Missed", value: "Missed" },
    { label: "Bottom Edge", value: "Bottom Edge" },
    { label: "Middled", value: "Middled" },
  ];

  const [length, setLength] = useState(null);
  const lengthOptions = [
    { label: "Short Length", value: "Short Length" },
    { label: "Full Toss", value: "Full Toss" },
    { label: "Good Length", value: "Good Length" },
    { label: "Half Volley", value: "Half Volley" },
    { label: "Yorker", value: "Yorker" },
    { label: "Wide Yorker", value: "Wide Yorker" },
    { label: "Short Good Length", value: "Short Good Length" },
  ];

  const [line, setLine] = useState(null);
  const lineOptions = [
    { label: "Stumps Line", value: "Stumps Line" },
    { label: "Outside Off", value: "Outside Off" },
    { label: "Wide Outside Off", value: "Wide Outside Off" },
    { label: "Down The Leg", value: "Down The Leg" },
  ];

  const [variationPace, setVariationPace] = useState(null);

  const [variation, setVariation] = useState(null);

  const [spinner, setSpinner] = useState(null);

  const [crease, setCrease] = useState(null);
  const creaseOptions = [
    { label: "Round the Wicket", value: "Round the Wicket" },
    { label: "Over the Wicket", value: "Over the Wicket" },
    { label: "Wide Crease", value: "Wide Crease" },
    { label: "Close to the Stumps", value: "Close to the Stumps" },
  ];

  const [pace, setPace] = useState(null);

  const columns1 = [
    {
      name: "Batter",
      selector: (row) => row.batter,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
      width: "80px",
    },
    {
      name: "Non-Striker",
      selector: (row) => row.nonStriker,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
      width: "80px",
    },
    {
      name: "Bowler",
      selector: (row) => row.bowler,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
      width: "80px",
    },
    {
      name: "Extras",
      selector: (row) => row.extras,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
      width: "80px",
    },
    {
      name: "Wickets",
      selector: (row) => row.wickets,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
      width: "80px",
    },
    {
      name: "Length",
      selector: (row) => row.length,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
      width: "80px",
    },
    {
      name: "Delivery",
      selector: (row) => row.delivery,
      sortable: true,
      style: { borderRight: "1px solid #fff", justifyContent: "center" },
      width: "80px",
    },
    {
      name: "Feet",
      selector: (row) => row.feet,
      sortable: true,
      style: { justifyContent: "center" },
      width: "80px",
    },
  ];

  // ------------------ Saving Ball ------------------

  // Function to save `ballsArray` to local storage
  const saveBallsToLocalStorage = (matchId, ballsArray) => {
    localStorage.setItem(`ballsArray_${matchId}`, JSON.stringify(ballsArray));
  };

  const saveMatchDetailsToLocalStorage = (matchId, matchDetails) => {
    localStorage.setItem(
      `matchDetails_${matchId}`,
      JSON.stringify(matchDetails)
    );
  };

  // Function to load `ballsArray` from local storage
  const loadMatchFromLocalStorage = (matchId) => {
    const matchDetails = localStorage.getItem(`matchDetails_${matchId}`);
    return matchDetails ? JSON.parse(matchDetails) : {};
  };
  // Function to load `ballsArray` from local storage
  const loadBallsFromLocalStorage = (matchId) => {
    const savedBalls = localStorage.getItem(`ballsArray_${matchId}`);
    return savedBalls ? JSON.parse(savedBalls) : [];
  };

  // Function to remove `ballsArray` from local storage
  const clearBallsFromLocalStorage = (matchId) => {
    localStorage.removeItem(`ballsArray_${matchId}`);
  };

  const saveBall = async () => {
    if (!matchDetails) return;

    if (!matchDetails) return;
    if (
      wagonWheelCords === null ||
      ballLineCords === null ||
      ballLengthCords === null
    ) {
      alert("Please select all required fields before proceeding.");
      return;
    }
    let allOvers = loadOversFromLocalStorage(matchDetails.match._id) || [];

    let localOverId =
      allOvers.length > 0
        ? allOvers[allOvers.length - 1].localOverId
        : uuidv4(); // ✅ Over ID yahan set ho rahi hai

    // if (
    //   allOvers.length === 0 ||
    //   allOvers[allOvers.length - 1].balls?.length >= 6
    // ) {
    //   // ✅ If last over is full OR no over exists, start a new one
    //   localOverId = uuidv4();
    // }

    const lastOver = allOvers.length > 0 ? allOvers[allOvers.length - 1] : null;
    const legalBallCount = lastOver
      ? lastOver?.balls?.filter((ball) => !ball.wide && !ball.noBall).length
      : 1;

    let validBallsCount = ballsArray.reduce((count, ball) => {
      return (
        count +
        (!ball.wide && !ball.noBall
          ? 1
          : ball.score == 0 && (ball.wide || ball.noBall)
          ? 0
          : 0)
      ); // Count only valid balls
    }, 0);

    if (!wide && !noBall) {
      validBallsCount += 1;
    }

    if (
      !lastOver ||
      legalBallCount >= 6 ||
      (matchDetails.match.teams[0].ballsPlayed === 0 &&
        matchDetails.match.teams[1].ballsPlayed > 0)
    ) {
      // ✅ Start a new over if the last over has 6 legal deliveries
      console.log("✅ Over Completed! Starting New Over...");
      localOverId = uuidv4();
    }

    console.log("localOverId", localOverId, allOvers);
    const localBallId = uuidv4();

    if (videoBlob) {
      await set(`video-${localBallId}`, videoBlob);
    }

    var ballData = {};

    if (out === null) {
      ballData = {
        localBallId,
        localOverId, // ✅ Ball ke andar bhi Over ID add kar di
        matchId: matchDetails.match._id,
        facingBatsman: matchDetails.match.facingBatsman?.player?._id,
        nonStriker: matchDetails.match.nonStriker?.player?._id,
        bowler: matchDetails.match.bowler?.player?._id,
        score: score === null ? 0 : score,
        video: videoBlob ? `video-${localBallId}` : null, // Store video key instead of Blob
        wide: wide || null,
        noBall: noBall || null,
        legBye: legByes || 0,
        bye: byes || 0,
        isWicket: !!out,
        wicket: out
          ? {
              type: out,
              dismissedBatsman:
                matchDetails.match?.facingBatsman?.player?._id === newBatsman
                  ? matchDetails.match?.facingBatsman?.player?._id
                  : matchDetails.match?.nonStriker?.player?._id,
              fielder: fielder || null, // Ensure fielder is optional
            }
          : null,
        battingStats: {
          feet,
          offShots: offShot,
          longShots: legShot,
          events: event,
          areaToHit: null,
        },
        bowlingStats: {
          length,
          line,
          pace,
          variation,
          crease,
        },
        pitchMap: ballLengthCords
          ? { x: ballLengthCords.x, y: ballLengthCords.y }
          : null,
        playerMap: ballLineCords
          ? { x: ballLineCords.x, y: ballLineCords.y }
          : null,
        wagonWheel: wagonWheelCords
          ? { x: wagonWheelCords.x, y: wagonWheelCords.y }
          : null,
      };
    }

    let striker = matchDetails.match.facingBatsman;
    let nonStriker = matchDetails.match.nonStriker;
    let bowler = matchDetails.match.bowler;
    let totalRuns = parseInt(score) || 0;
    let extraRuns = 0;

    // Handle Extras
    if (wide) {
      extraRuns += parseInt(wide) || 0;
    }
    if (noBall) {
      extraRuns += parseInt(noBall) || 0;
    }
    if (legByes) {
      extraRuns += parseInt(legByes) || 0;
    }
    if (byes) {
      extraRuns += parseInt(byes) || 0;
    }

    totalRuns += extraRuns;

    // Update Batsman & Bowler Stats
    setMatchDetails((prev) => {
      let updatedMatch = { ...prev };

      // Update facing batsman
      let updatedPlayers = updatedMatch.match.teams[0].selectedPlayers.map(
        (player) =>
          player.player?._id === striker.player?._id
            ? {
                ...player,
                runsAchieved:
                  player.runsAchieved +
                  (wide || noBall || legByes || byes ? 0 : totalRuns),
                ballsPlayed: player.ballsPlayed + (wide || noBall ? 0 : 1),
              }
            : player
      );

      updatedMatch.match.teams[0].selectedPlayers = updatedPlayers;

      //add runsAchieved and ballsPlayed in match.facingBatsman as well
      updatedMatch.match.facingBatsman = {
        ...updatedMatch.match.facingBatsman,
        runsAchieved:
          (updatedMatch.match.facingBatsman.runsAchieved || 0) +
          (wide || noBall || legByes || byes ? 0 : totalRuns),
        ballsPlayed:
          (updatedMatch.match.facingBatsman.ballsPlayed || 0) +
          (wide || noBall || out ? 0 : 1),
      };

      // Update bowler
      let updatedBowlers = updatedMatch.match.teams[1].selectedPlayers.map(
        (player) =>
          player.player?._id === bowler.player?._id
            ? {
                ...player,
                runsGiven:
                  player.runsGiven +
                  (wide || noBall || legByes || byes ? 0 : totalRuns),
                ballsDelivered:
                  player.ballsDelivered + (wide || noBall ? 0 : 1),
              }
            : player
      );

      updatedMatch.match.teams[1].selectedPlayers = updatedBowlers;

      updatedMatch.match.bowler = {
        ...updatedMatch.match.bowler,
        runsGiven:
          (updatedMatch.match.bowler.runsGiven || 0) +
          (wide || noBall || legByes || byes ? 0 : totalRuns),
        ballsDelivered:
          (updatedMatch.match.bowler.ballsDelivered || 0) +
          (wide || noBall ? 0 : 1),
      };

      // Update team score and balls
      updatedMatch.match.teams[0].runs += totalRuns;
      updatedMatch.match.teams[0].ballsPlayed += wide || noBall ? 0 : 1;
      updatedMatch.match.teams[0].oversPlayed = calculateOvers(
        updatedMatch.match.teams[0].ballsPlayed
      );

      if (out) updatedMatch.match.teams[0].wickets += 1;

      console.log("score", score);
      if (out !== null) {
        updateBatsman(dismissedBatsman, newBatsman);
        ballData = {
          localBallId,
          localOverId, // ✅ Ball ke andar bhi Over ID add kar di
          matchId: matchDetails.match.id,
          facingBatsman: matchDetails.match.facingBatsman?.player?.id,
          nonStriker: matchDetails.match.nonStriker?.player?.id,
          bowler: matchDetails.match.bowler?.player?.id,
          score: score === null ? 0 : score,
          video: videoBlob ? `video-${localBallId}` : null, // Store video key instead of Blob
          wide: wide || null,
          noBall: noBall || null,
          legBye: legByes || 0,
          bye: byes || 0,
          isWicket: !!out,
          wicket: out
            ? {
                type: out,
                dismissedBatsman:
                  matchDetails.match?.facingBatsman?.player?._id === newBatsman
                    ? matchDetails.match?.facingBatsman?.player?._id
                    : matchDetails.match?.nonStriker?.player?._id,
                fielder: fielder || null, // Ensure fielder is optional
              }
            : null,
          battingStats: {
            feet,
            offShots: offShot,
            longShots: legShot,
            events: event,
            areaToHit: null,
          },
          bowlingStats: {
            length,
            line,
            pace,
            variation,
            crease,
          },
          pitchMap: ballLengthCords
            ? { x: ballLengthCords.x, y: ballLengthCords.y }
            : null,
          playerMap: ballLineCords
            ? { x: ballLineCords.x, y: ballLineCords.y }
            : null,
          wagonWheel: wagonWheelCords
            ? { x: wagonWheelCords.x, y: wagonWheelCords.y }
            : null,
        };
      }

      // Swap Striker if Odd Score (except for extras)
      if (!wide && !noBall && totalRuns % 2 !== 0) {
        [updatedMatch.match.facingBatsman, updatedMatch.match.nonStriker] = [
          updatedMatch.match.nonStriker,
          updatedMatch.match.facingBatsman,
        ];
      }

      // ✅ Extract Key Data
      const team1 = updatedMatch.match.teams[0];
      const team2 = updatedMatch.match.teams[1];

      const team1Runs = team1.runs;
      const team2Runs = team2.runs;
      const team1OversPlayed = parseFloat(team1.oversPlayed);
      const team2OversPlayed = parseFloat(team2.oversPlayed);
      const team1Wickets = team1.wickets;
      const team2Wickets = team2.wickets;
      const totalOvers = parseFloat(updatedMatch.match.totalOvers);
      const dlsOvers1 = parseFloat(updatedMatch.match.teams[0].dlsOvers);
      const dlsOvers2 = parseFloat(updatedMatch.match.teams[1].dlsOvers);
      const dlsTarget = team2.dlsScore > 0 ? team2.dlsScore : team2Runs;

      // ✅ **First Innings Completion Check**
      const isFirstInningsComplete =
        (team1Wickets === 10 ||
          team1OversPlayed === (dlsOvers1 > 0.0 ? dlsOvers1 : totalOvers)) &&
        !updatedMatch.match.isFirstInningCompleted;

      // ✅ **Second Innings Completion Check**
      const isSecondInningsComplete =
        (team2Wickets === 10 || team2OversPlayed === totalOvers) &&
        updatedMatch.match.isFirstInningCompleted;

      if (
        isFirstInningsComplete &&
        (team2Wickets < 10 || team2OversPlayed === 0.0)
      ) {
        console.log(
          "✅ First Innings Completed! Switching to Second Innings..."
        );

        // updatedMatch.match.isSecondInnings = true;

        // Swap teams for second innings
        [updatedMatch.match.teams[0], updatedMatch.match.teams[1]] = [
          updatedMatch.match.teams[1],
          updatedMatch.match.teams[0],
        ];

        updatedMatch.match.teams[0].runs = 0;
        updatedMatch.match.teams[0].wickets = 0;
        updatedMatch.match.teams[0].ballsPlayed = 0;
        updatedMatch.match.teams[0].oversPlayed = "0.0";

        clearBallsFromLocalStorage(matchDetails.match.id);
        setIsNewInningsModal(true);
        setIsSecondInnings(true);

        return updatedMatch; // Exit here to ensure proper innings switch
      }

      if (
        (team1Wickets === 10 ||
          team1OversPlayed === (dlsOvers1 > 0.0 ? dlsOvers1 : totalOvers) ||
          team1Runs > dlsTarget) &&
        isSecondInningsComplete
      ) {
        console.log("✅ Match Completed! Determining Winner...");

        const win = team1Runs > dlsTarget ? team1 : team2;

        setWinner(win);
        toast.success(`🏆 Match Over! Winner: ${win.teamId.teamName}`);

        console.log("Winner:", win);
        updatedMatch.match.winner = win.teamId._id;

        console.log("Winner in if:", winner);
        setActiveModal("EndMatch");
        clearBallsFromLocalStorage(matchDetails.match.id);

        return updatedMatch;
      }
      console.log("validBallsCount", validBallsCount);

      // End of Over opening modal (ignore No-Balls & Wides)
      if (validBallsCount > 0 && validBallsCount >= 6) {
        setIsSelectBowlerModal(true);
        [updatedMatch.match.facingBatsman, updatedMatch.match.nonStriker] = [
          updatedMatch.match.nonStriker,
          updatedMatch.match.facingBatsman,
        ];
        clearBallsFromLocalStorage(matchDetails.match.id);
      }
      return updatedMatch;
    });

    if (validBallsCount > 0 && validBallsCount >= 6) {
      setBallsArray([]);
      saveBallsToLocalStorage(matchDetails.match.id, []);
    } else {
      let updatedBallsArray = [...ballsArray, ballData];
      setBallsArray(updatedBallsArray);
      saveBallsToLocalStorage(matchDetails.match.id, updatedBallsArray);
    }
    updateOversInLocalStorage(matchDetails.match.id, localOverId, ballData);

    console.log("ballsArray:", ballsArray);
    console.log("ballData:", ballData);
    console.log("Match Details:", matchDetails);
    // resetSelections();
    sendBallToAPI(matchDetails, ballData);
  };

  const sendMatchDetailsToAPI = async (matchId, matchDetails) => {
    try {
      saveMatchDetailsToLocalStorage(matchId, matchDetails);

      const payload = structuredClone(matchDetails.match);

      // ✅ Ensure `player` field is mapped correctly
      if (payload.facingBatsman?.player) {
        payload.facingBatsman.player = payload.facingBatsman.player._id;
      }
      if (payload.nonStriker?.player) {
        payload.nonStriker.player = payload.nonStriker.player._id;
      }
      if (payload.bowler?.player) {
        payload.bowler.player = payload.bowler.player._id;
      }

      const removeIds = (obj) => {
        if (!obj) return null;
        const { _id, id, ...cleanedObj } = obj;
        return cleanedObj;
      };

      payload.facingBatsman = removeIds(payload.facingBatsman);
      payload.nonStriker = removeIds(payload.nonStriker);
      payload.bowler = removeIds(payload.bowler);

      // ✅ Correct `teams` structure
      payload.teams = payload.teams.map(
        ({ _id, id, teamId, selectedPlayers, substitutePlayers, ...rest }) => ({
          ...rest,
          teamId: teamId?._id,
          selectedPlayers: selectedPlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
          substitutePlayers: substitutePlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
        })
      );

      payload.matchId = payload._id;

      // ✅ Remove unnecessary fields
      const fieldsToRemove = [
        "_id",
        "id",
        "owner",
        "tournamentId",
        "totalOvers",
        "matchType",
        "overs",
        "updatedAt",
        "createdAt",
        "__v",
        "matchNumber",
        "matchName",
        "winner",
        "isFirstInningCompleted",
        "manOfTheMatch",
      ];
      fieldsToRemove.forEach((field) => delete payload[field]);

      console.log("✅ Cleaned Payload:", JSON.stringify(payload, null, 2));

      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/v2/match/match-details-sync`,
        token,
        payload
      );

      if (result?.status === 200) {
        console.log("✅ Ball data sent successfully!");
        toast.success("Ball data sent successfully!", {
          position: "top-center",
          theme: "colored",
          autoClose: 6000,
        });
      }
    } catch (error) {
      console.error("❌ Error sending ball data:", error);
    } finally {
      setBallsArray([]);
    }
  };

  const handleInningsSelection = () => {
    if (!selectedFacingBatsman || !selectedNonStriker || !selectedBowler) {
      alert("Please select all players before proceeding.");
      return;
    }

    setMatchDetails((prev) => {
      let updatedMatch = { ...prev };

      // ✅ Find and assign selected players
      const facingBatsmanObj = updatedMatch.match.teams[0].selectedPlayers.find(
        (player) => player.player?._id === selectedFacingBatsman
      );

      const nonStrikerObj = updatedMatch.match.teams[0].selectedPlayers.find(
        (player) => player.player?._id === selectedNonStriker
      );

      const bowlerObj = updatedMatch.match.teams[1].selectedPlayers.find(
        (player) => player.player?._id === selectedBowler
      );

      // ✅ Assign only if players exist
      if (facingBatsmanObj)
        updatedMatch.match.facingBatsman = { ...facingBatsmanObj };
      if (nonStrikerObj) updatedMatch.match.nonStriker = { ...nonStrikerObj };
      if (bowlerObj) updatedMatch.match.bowler = { ...bowlerObj };

      // ✅ Reset innings stats
      updatedMatch.match.teams[0] = {
        ...updatedMatch.match.teams[0],
        runs: 0,
        wickets: 0,
        ballsPlayed: 0,
        oversPlayed: "0.0",
      };

      updatedMatch.match.isFirstInningCompleted = true;

      console.log("🚀 Updated Match Details for New Innings:", updatedMatch);
      return updatedMatch;
    });

    sendMatchDetailsInningsToAPI(matchDetails.match.id, matchDetails);

    // ✅ Reset local storage and UI
    setBallsArray([]);
    saveBallsToLocalStorage(matchDetails.match.id, []);
    saveMatchDetailsToLocalStorage(matchDetails.match.id, matchDetails);

    // ✅ Close modals
    setIsSelectBowlerModal(false);
    setIsNewInningsModal(false);
  };

  const sendMatchDetailsInningsToAPI = async (matchId, matchDetails) => {
    try {
      saveMatchDetailsToLocalStorage(matchId, matchDetails);

      const payload = structuredClone(matchDetails.match);

      // ✅ Ensure `player` field is mapped correctly
      if (payload.facingBatsman?.player) {
        payload.facingBatsman.player = payload.facingBatsman.player._id;
      }
      if (payload.nonStriker?.player) {
        payload.nonStriker.player = payload.nonStriker.player._id;
      }
      if (payload.bowler?.player) {
        payload.bowler.player = payload.bowler.player._id;
      }

      const removeIds = (obj) => {
        if (!obj) return null;
        const { _id, id, ...cleanedObj } = obj;
        return cleanedObj;
      };

      payload.facingBatsman = removeIds(payload.facingBatsman);
      payload.nonStriker = removeIds(payload.nonStriker);
      payload.bowler = removeIds(payload.bowler);

      // ✅ Correct `teams` structure
      payload.teams = payload.teams.map(
        ({ _id, id, teamId, selectedPlayers, substitutePlayers, ...rest }) => ({
          ...rest,
          teamId: teamId?._id,
          selectedPlayers: selectedPlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
          substitutePlayers: substitutePlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
        })
      );

      payload.matchId = payload._id;

      // ✅ Remove unnecessary fields
      const fieldsToRemove = [
        "_id",
        "id",
        "owner",
        "tournamentId",
        "totalOvers",
        "matchType",
        "overs",
        "updatedAt",
        "createdAt",
        "__v",
        "matchNumber",
        "matchName",
        "winner",
        "manOfTheMatch",
        "venue",
        "isDlsApplied",
      ];
      fieldsToRemove.forEach((field) => delete payload[field]);

      console.log("✅ Cleaned Payload:", JSON.stringify(payload, null, 2));

      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/v2/match/match-inning`,
        token,
        payload
      );

      if (result?.status === 200) {
        console.log("✅ Match data sent successfully!");
        toast.success("Match Data sent successfully!", {
          position: "top-center",
          theme: "colored",
          autoClose: 6000,
        });
      }
    } catch (error) {
      console.error("❌ Error sending ball data:", error);
    } finally {
      setBallsArray([]);
    }
  };

  const calculateOvers = (totalBalls) => {
    let overs = Math.floor(totalBalls / 6);
    let balls = totalBalls % 6;
    return `${overs}.${balls}`;
  };

  function oversToBalls(overs) {
    overs = parseFloat(overs); // Convert to float if not already
    let completeOvers = Math.floor(overs); // Extract complete overs
    let remainingBalls = Math.round((overs % 1) * 10); // Extract decimal part as balls
    return completeOvers * 6 + remainingBalls;
  }

  const resetSelections = () => {
    setBallLengthCords(null);
    setBallLineCords(null);
    setWagonWheelCords(null);
    setByes(null);
    setLegByes(null);
    setScore(0);
    setWide(false);
    setNoBall(false);
    setOut(null);
    setFeet(null);
    setOffShot(null);
    setLegShot(null);
    setTechnicalError(null);
    setEvent(null);
    setLength(null);
    setLine(null);
    setVariation(null);
    setSpinner(null);
    setCrease(null);
    setPace(null);
    setRecordedVideo(null);
    setShowCamera(true);
    setVideoBlob(null);
    setFielder(null);
    setNewBatsman(null);
  };

  useEffect(() => {
    if (score) {
      setOut(null);
      setWide(null);
      setNoBall(null);
      setByes(null);
      setLegByes(null);
    }
    if (wide) {
      setScore(null);
      setOut(null);
      setNoBall(null);
      setByes(null);
      setLegByes(null);
    }
    if (noBall) {
      setScore(null);
      setOut(null);
      setWide(null);
      setByes(null);
      setLegByes(null);
    }
    if (byes) {
      setScore(null);
      setOut(null);
      setWide(null);
      setNoBall(null);
      setLegByes(null);
    }
    if (legByes) {
      setScore(null);
      setOut(null);
      setWide(null);
      setNoBall(null);
      setByes(null);
    }
  }, [score, wide, noBall, byes, legByes]);

  const fetchMatchDetails = async (id) => {
    try {
      setMatchDetailsLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/match/get-match`,
        token,
        { matchId: id }
      );
      if (result?.status === 200) {
        console.log("API match", result.data);
        setMatchDetails(result.data);
        setZeroOne(true);
      }
      setMatchDetailsLoading(false);
    } catch (error) {
      setMatchDetailsLoading(false);
      toast.error(error.message);
    }
  };
  // ✅ Function to delay requests (500ms gap)
  // const debounceRequests = async (callback, delay = 500) => {
  //   await new Promise((resolve) => setTimeout(resolve, delay));
  //   await callback();
  // };

  let debounceTimer;
  const debounceRequests = (callback, delay = 500) => {
    clearTimeout(debounceTimer); // Pehle wale call ko cancel karo
    debounceTimer = setTimeout(() => {
      callback(); // Sirf last request chalegi
    }, delay);
  };

  useEffect(() => {
    if (!matchDetails) return;

    const matchId = matchDetails.match.id;
    const storedOvers = loadOversFromLocalStorage(matchId) || [];
    const latestOvers = matchDetails.match.overs || [];

    const isSameOver = (over1, over2) =>
      over1?.localOverId === over2?.overId?.localOverId;
    const isSameBall = (ball1, ball2) =>
      ball1?.localBallId === ball2?.localBallId &&
      ball1?.localOverId === ball2?.localOverId;

    // ✅ Filter overs only in localStorage (not in API)
    const extraOvers = storedOvers.filter(
      (storedOver) =>
        !latestOvers.some((latestOver) => isSameOver(storedOver, latestOver))
    );

    // ✅ Filter overs only in API (not in localStorage)
    const newOvers = latestOvers.filter(
      (latestOver) =>
        !storedOvers.some((storedOver) => isSameOver(storedOver, latestOver))
    );

    if (extraOvers.length > 0) {
      console.log("LocalStorage Overs", extraOvers);
      debounceRequests(() => sendAllPendingOversToAPI(matchId, extraOvers));
    }
    if (newOvers.length > 0) {
      console.log("Apis Overs", newOvers);
      saveOversToLocalStorage(
        matchId,
        transformBackendOversToLocalFormat(latestOvers)
      );
    }

    // ✅ Process Balls Only for Fully Synced Overs
    latestOvers.forEach((latestOver) => {
      const storedOver = storedOvers.find((o) => isSameOver(o, latestOver));
      if (!storedOver) return;

      if (!storedOver.balls) storedOver.balls = [];
      if (!latestOver.balls) latestOver.balls = [];

      const extraBalls = storedOver.balls.filter(
        (storedBall) =>
          !latestOver.balls.some((latestBall) =>
            isSameBall(storedBall, latestBall)
          )
      );
      const newBalls = latestOver.balls.filter(
        (latestBall) =>
          !storedOver.balls.some((storedBall) =>
            isSameBall(storedBall, latestBall)
          )
      );
      console.log("local, aPI", extraBalls, newBalls);
      if (extraBalls.length > 0) {
        console.log("LocalStorage Balls", extraBalls);
        debounceRequests(() =>
          sendAllPendingBallsToAPI(matchId, extraBalls, storedOver.localOverId)
        );
      }
    });

    const latestOver = latestOvers[latestOvers.length - 1];

    if (latestOver) {
      // ✅ Fix: Start count from 0 instead of 1
      const validBallsCount =
        latestOver?.balls?.reduce((count, ball) => {
          return ball?.wide || ball?.noBall ? count : count + 1;
        }, 0) || 0;

      // const validBallsCount = latestOver
      //   ? latestOver?.balls?.filter((ball) => !ball.wide && !ball.noBall).length
      //   : 1;

      console.log("✅ latestOver:", latestOver);
      console.log("✅ Valid Balls Count:", validBallsCount);

      // ✅ Correct Over Completion Check
      if (validBallsCount > 0 && validBallsCount >= 6) {
        console.log("✅ Over Completed! Resetting Balls Array");
        setBallsArray([]); // Reset array on over completion
      } else {
        console.log("✅ Ongoing Over. Updating Balls Array", latestOver?.balls);
        setBallsArray(latestOver?.balls);
      }
    } else {
      setBallsArray([]);
    }
  }, [zeroOne]);

  useEffect(() => {
    if (!matchDetails) return;
    loadBallsFromLocalStorage(matchDetails.match.id);
  }, [zeroOne]);

  const sendAllPendingOversToAPI = async (matchId, overs) => {
    try {
      setSyncing(true);

      if (overs.length === 0) return; // Koi pending overs nahi toh kuch mat karo
      const matchDetails = loadMatchFromLocalStorage(matchId);
      saveMatchDetailsToLocalStorage(matchId, matchDetails);

      console.log(`🚀 Sending ${overs.length} pending overs to API...`);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/v2/match/match-scoring-overs-bulking`,
        token,
        { matchId, overs }
      );
    } catch (error) {
      console.error("❌ Error syncing overs:", error);
    } finally {
      setSyncing(false);
      window.location.reload();
    }
  };

  // ✅ Function to send **all pending balls in one request**
  const sendAllPendingBallsToAPI = async (matchId, balls, localOverId) => {
    try {
      setSyncing(true);
      if (balls.length === 0) return;

      console.log(`🚀 Waiting 500ms before sending ${balls.length} balls...`);
      await new Promise((resolve) => setTimeout(resolve, 500)); // Delay before request

      const matchDetails = loadMatchFromLocalStorage(matchId);
      saveMatchDetailsToLocalStorage(matchId, matchDetails);

      console.log(`🚀 Sending ${balls.length} pending balls to API...`);

      const token = getItemFromLocalStorage("TOKEN");
      const payload = structuredClone(matchDetails.match);

      payload.facingBatsman.player = payload.facingBatsman.player._id;
      payload.nonStriker.player = payload.nonStriker.player._id;
      payload.bowler.player = payload.bowler.player._id;

      // ✅ Remove `_id` and `id` from nested objects
      const removeIds = (obj) => {
        if (!obj) return null;
        const { _id, id, ...cleanedObj } = obj;
        return cleanedObj;
      };

      payload.facingBatsman = removeIds(payload.facingBatsman);
      payload.nonStriker = removeIds(payload.nonStriker);
      payload.bowler = removeIds(payload.bowler);

      payload.teams = payload.teams.map(
        ({ _id, id, teamId, selectedPlayers, substitutePlayers, ...rest }) => ({
          ...rest,
          teamId: teamId?._id,
          selectedPlayers: selectedPlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
          substitutePlayers: substitutePlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
        })
      );

      payload.balls = balls;
      payload.matchId = payload?._id;
      payload.localOverId = localOverId;

      const fieldsToRemove = [
        "_id",
        "id",
        "owner",
        "tournamentId",
        "totalOvers",
        "matchType",
        "overs",
        "updatedAt",
        "createdAt",
        "__v",
        "matchNumber",
        "matchName",
        "winner",
        "manOfTheMatch",
        "isFirstInningCompleted",
      ];
      fieldsToRemove.forEach((field) => delete payload[field]);

      console.log("✅ Cleaned Payload:", JSON.stringify(payload, null, 2));

      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(
            key,
            typeof value === "object" && key !== "video"
              ? JSON.stringify(value)
              : value
          );
        }
      });

      await Promise.all(
        balls.map(async (ball, index) => {
          console.log("✅ Attaching video for ball", ball);
          if (ball.video) {
            const videoBlob = await get(`video-${ball.localBallId}`);
            if (videoBlob) {
              console.log("✅ Retrieved videoBlob for ball", videoBlob);
              const videoFile = new File([videoBlob], `video_${index}.webm`, {
                type: "video/webm",
              });

              console.log("✅ Appending video to formData", videoFile);
              formData.append(`video_${index}`, videoFile);
            }
          }
        })
      );

      const { result, error } = await postRequestForm(
        `/api/secure/v2/match/match-scoring-bulking`,
        token,
        formData
      );

      if (result?.status === 200) {
        console.log("✅ All pending balls sent successfully!");
      } else {
        console.error("❌ Error sending balls", error);
      }
    } catch (error) {
      console.error("❌ Unexpected error:", error);
    } finally {
      setSyncing(false);
    }
  };

  const loadOversFromLocalStorage = (matchId) => {
    const allOvers = JSON.parse(localStorage.getItem(`allOvers_${matchId}`));
    return allOvers || [];
  };
  const updateOversInLocalStorage = (matchId, localOverId, ballData) => {
    if (!matchId || !localOverId || !ballData) return;

    let allOvers = loadOversFromLocalStorage(matchId) || [];

    // Check if the over already exists
    let existingOverIndex = allOvers.findIndex(
      (over) => over.localOverId === localOverId
    );

    if (existingOverIndex !== -1) {
      // ✅ Over found, push ball into existing over
      allOvers[existingOverIndex].balls.push(ballData);
    } else {
      // ✅ New over, create and add it
      let newOver = {
        localOverId,
        balls: [ballData],
      };
      allOvers.push(newOver);
    }

    saveOversToLocalStorage(matchId, allOvers);
  };
  const saveOversToLocalStorage = (matchId, allOvers) => {
    if (!matchId || !allOvers) return;
    localStorage.setItem(`allOvers_${matchId}`, JSON.stringify(allOvers));
  };

  // ✅ Function to transform **Backend format → LocalStorage format**
  const transformBackendBallToLocalFormat = (ball) => ({
    matchId: ball.matchId,
    facingBatsman: ball.players.facingBatsman._id,
    nonStriker: ball.players.nonStriker._id,
    bowler: ball.players.bowler._id,
    score: ball.score,
    wide: ball.wide,
    noBall: ball.noBall,
    legBye: ball.legBye,
    bye: ball.bye,
    isWicket: ball.isWicket,
    wicket: ball.wicket,
    battingStats: ball.battingStats,
    bowlingStats: ball.bowlingStats,
    pitchMap: ball.coordinates.pitchMap,
    playerMap: ball.coordinates.playerMap,
    wagonWheel: ball.coordinates.wagonWheel, // ✅ Fix: Now using coordinates.wagonWheel
  });

  const transformBackendOversToLocalFormat = (backendOvers) => {
    return backendOvers.map((over) => ({
      localOverId: over.overId.localOverId,
      balls: over.balls.map((ball) => ({
        localBallId: ball.localBallId,
        localOverId: ball.localOverId,
        matchId: ball.matchId,
        overId: ball.overId,
        facingBatsman: ball.players.facingBatsman._id,
        nonStriker: ball.players.nonStriker._id,
        bowler: ball.players.bowler._id,
        score: ball.score,
        isWicket: ball.isWicket,
        wicket: ball.wicket,
        wide: ball.wide,
        noBall: ball.noBall,
        legBye: ball.legBye,
        bye: ball.bye,
        video: ball.video || null,
        battingStats: ball.battingStats,
        bowlingStats: ball.bowlingStats,
        coordinates: ball.coordinates,
        createdAt: ball.createdAt,
        ballNumber: ball.ballNumber,
      })),
    }));
  };

  // Function to update batsman after wicket
  const updateBatsman = (dismissedBatsman, newBatsmanId) => {
    setMatchDetails((prev) => {
      const updatedMatch = { ...prev };

      // Find the new batsman
      const newBatsman = updatedMatch.match.teams[0].selectedPlayers.find(
        (player) => player.player?._id === newBatsmanId
      );

      if (!newBatsman) {
        console.error("🚨 Error: Selected batsman not found in team!");
        return prev;
      }

      // Find and update the dismissed batsman (add 1 ball played)
      const dismissedBatsmanObj =
        dismissedBatsman === "striker"
          ? updatedMatch.match.facingBatsman
          : updatedMatch.match.nonStriker;

      if (dismissedBatsmanObj) {
        dismissedBatsmanObj.ballsPlayed =
          (dismissedBatsmanObj.ballsPlayed || 0) + 1;
      }

      // Replace the dismissed batsman with the new one
      if (dismissedBatsman === "striker") {
        updatedMatch.match.facingBatsman = { ...newBatsman };
      } else {
        updatedMatch.match.nonStriker = { ...newBatsman };
      }

      console.log("✅ Updated Batsman & Balls Played:", updatedMatch);
      return updatedMatch;
    });

    setNewBatsman(newBatsmanId);
  };

  // Send API call when wicket modal closes
  // useEffect(() => {
  //   if (!isWicketModal && newBatsman) {
  //     setMatchDetails((prev) => {
  //       let updatedMatch = { ...prev };

  //       updatedMatch.match.facingBatsman =
  //         updatedMatch.match.teams[0].selectedPlayers.find(
  //           (player) => player._id === newBatsman
  //         );

  //       return updatedMatch;
  //     });

  //     setNewBatsman(null);
  //     saveBall();
  //   }
  // }, [isWicketModal]);

  // Open Wicket Modal when a batsman is out
  useEffect(() => {
    if (out) {
      setIsWicketModal(true);
    }
  }, [out]);
  const sendBallToAPI = async (matchDetails, ballData) => {
    try {
      saveMatchDetailsToLocalStorage(matchDetails.match._id, matchDetails);
      console.log("Sending ball data to API...");
      const token = getItemFromLocalStorage("TOKEN");

      // Deep clone match data to avoid modifying the original object
      const payload = structuredClone(matchDetails.match);

      payload.facingBatsman.player = payload.facingBatsman.player._id;
      payload.nonStriker.player = payload.nonStriker.player._id;
      payload.bowler.player = payload.bowler.player._id;

      // Remove _id and id from facingBatsman
      const removeIds = (obj) => {
        if (!obj) return null;
        const { _id, id, ...cleanedObj } = obj;
        return cleanedObj;
      };

      payload.facingBatsman = removeIds(payload.facingBatsman);
      payload.nonStriker = removeIds(payload.nonStriker);
      payload.bowler = removeIds(payload.bowler);

      // Convert teams properly
      payload.teams = payload.teams.map(
        ({ _id, id, teamId, selectedPlayers, substitutePlayers, ...rest }) => ({
          ...rest,
          teamId: teamId?._id, // Convert teamId

          selectedPlayers: selectedPlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),

          substitutePlayers: substitutePlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
        })
      );

      payload.ballData = structuredClone(ballData);
      payload.matchId = payload?._id;
      delete payload.ballData.video;
      // Remove unnecessary fields
      const fieldsToRemove = [
        "_id",
        "id",
        "owner",
        "tournamentId",
        "totalOvers",
        "matchType",
        "overs",
        "updatedAt",
        "createdAt",
        "__v",
        "matchNumber",
        "matchName",
        "winner",
        "manOfTheMatch",
        "isFirstInningCompleted",
      ];

      fieldsToRemove.forEach((field) => delete payload[field]);

      // console.log("✅ Cleaned Payload:", JSON.stringify(payload, null, 2));

      const formData = new FormData();

      // Append all payload data except `ballData` separately
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          if (typeof value === "object" && key !== "video") {
            formData.append(key, JSON.stringify(value)); // Convert objects to JSON
          } else {
            formData.append(key, value);
          }
        }
      });

      // ✅ Fix: Ensure ballData is correctly handled
      if (ballData.video) {
        try {
          const videoBlob = await get(`video-${ballData.localBallId}`);
          if (videoBlob) {
            console.log(
              "✅ Retrieved videoBlob for ball",
              ballData.localBallId
            );
            const videoFile = new File(
              [videoBlob],
              `video_${ballData.localBallId}.webm`,
              {
                type: "video/webm",
              }
            );

            console.log("✅ Appending single video to formData", videoFile);
            formData.append("video", videoFile); // ✅ Ensure video is properly attached
          } else {
            console.warn(
              "⚠️ No video found in IndexedDB for",
              ballData.localBallId
            );
          }
        } catch (error) {
          console.error("❌ Error retrieving video from IndexedDB:", error);
        }
      }

      const { result, error } = await postRequestForm(
        `/api/secure/v2/match/match-scoring`,
        token,
        formData
      );

      if (result?.status === 200) {
        console.log("Ball data sent successfully!");
        toast.success("Ball data sent successfully!", {
          position: "top-center",
          theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error sending ball data:", error);
    }
  };

  const swapStrikerAndNonStriker = () => {
    setMatchDetails((prev) => {
      if (!prev || !prev.match) return prev; // Ensure match details exist

      // Ensure fallback values if the current striker/non-striker are missing
      const currentStriker = prev.match.facingBatsman || null;
      const currentNonStriker = prev.match.nonStriker || null;

      if (!currentStriker || !currentNonStriker) {
        alert("Cannot swap players: Missing striker or non-striker!");
        return prev;
      }

      return {
        ...prev,
        match: {
          ...prev.match,
          facingBatsman: currentNonStriker,
          nonStriker: currentStriker,
        },
      };
    });
  };

  // -----------------------------------------------------------------

  // const isFirstRender = useRef(true); // ✅ Declare useRef outside useEffect

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("_id");

    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return; // ✅ Skip fetching on first render
    // }

    if (id && !matchDetails) {
      console.log("Fetching match details...");
      fetchMatchDetails(id);
    }
  }, [search]); // ✅ Runs only when `search` changes

  return (
    <section
      id="matchScoring"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        minHeight: "100vh", // Ensures it covers the full viewport height
        overflowY: "auto", // Enables scrolling if needed
      }}
    >
      {matchDetailsLoading ? (
        <Spinner
          animation="border"
          about="Match Details..."
          className="d-block mx-auto my-5"
        />
      ) : (
        <>
          {syncing ? (
            <div
              className="d-flex flex-column justify-content-center align-items-center text-center"
              style={{
                height: "100vh",
              }}
            >
              <Spinner
                animation="border"
                about="Match Details..."
                className="d-block mx-auto my-5"
              />
              <p className="text-white">Syncing in progress, please wait...</p>
            </div>
          ) : (
            <div>
              <div className="header">
                <div>
                  <LazyLoadImage
                    src={Images.hamburgerButton}
                    alt="flag"
                    className="bars"
                    height={35}
                    width={35}
                    onClick={() => setShow(true)}
                  />
                  <>
                    <Offcanvas
                      show={show}
                      style={{
                        background:
                          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
                      }}
                      onHide={() => setShow(false)}
                    >
                      <Offcanvas.Header closeButton closeVariant="white">
                        <Offcanvas.Title className="fs-3 text-light">
                          Scoring Info
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body className="off-canvas-body">
                        <button
                          onClick={() => {
                            setActiveModal("BattingScoreCard");
                            setShow(false);
                          }}
                        >
                          Batting Score Card
                        </button>
                        <button onClick={() => setActiveModal("BowlingCard")}>
                          Bowling Card
                        </button>
                        <button onClick={() => setActiveModal("Partnership")}>
                          Partnership
                        </button>
                        <button onClick={() => setActiveModal("WagonWheel")}>
                          Wagon Wheel
                        </button>
                        <button onClick={() => setActiveModal("PitchMap")}>
                          Pitch Map
                        </button>
                        <button
                          onClick={() => setActiveModal("PhaseComparison")}
                        >
                          Phase Comparison
                        </button>
                        <button onClick={() => setActiveModal("DLS")}>
                          DLS
                        </button>
                        <button onClick={() => setActiveModal("EndMatch")}>
                          End Match
                        </button>
                        <button onClick={() => setActiveModal("BallLine")}>
                          Ball Line
                        </button>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </>
                  <Modal
                    show={!!activeModal}
                    onHide={() => setActiveModal(null)}
                    centered
                    dialogClassName="custom-modal"
                  >
                    <Modal.Body>
                      {activeModal === "BattingScoreCard" && (
                        <BattingScoreCard
                          handleClose={() => setActiveModal(null)}
                        />
                      )}
                      {activeModal === "BowlingCard" && (
                        <BowlingCard handleClose={() => setActiveModal(null)} />
                      )}
                      {activeModal === "Partnership" && (
                        <Partnership handleClose={() => setActiveModal(null)} />
                      )}
                      {activeModal === "WagonWheel" && (
                        <WagonWheel handleClose={() => setActiveModal(null)} />
                      )}
                      {activeModal === "PitchMap" && (
                        <PitchMap handleClose={() => setActiveModal(null)} />
                      )}
                      {activeModal === "PhaseComparison" && (
                        <PhaseComparison
                          handleClose={() => setActiveModal(null)}
                        />
                      )}
                      {activeModal === "DLS" && (
                        <DLS
                          handleClose={() => setActiveModal(null)}
                          matchDetails={matchDetails}
                          setMatchDetails={setMatchDetails}
                        />
                      )}
                      {activeModal === "EndMatch" && (
                        <EndMatch
                          matchDetails={matchDetails}
                          setMatchDetails={setMatchDetails}
                          winner={winner}
                          handleClose={() => setActiveModal(null)}
                        />
                      )}
                      {activeModal === "BallLine" && (
                        <BallLine handleClose={() => setActiveModal(null)} />
                      )}
                    </Modal.Body>
                  </Modal>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "430px", marginLeft: "90px" }}
                >
                  {/* -------------------------------Header Bar--------------------------------- */}
                  <span className="d-flex justify-content-center align-items-center">
                    <LazyLoadImage
                      src={matchDetails?.match?.teams[0]?.teamId?.teamImageUrl}
                      alt="flag"
                      width={30}
                      height={30}
                      className="rounded-circle me-3"
                    />
                    <p className="header-font">
                      {matchDetails?.match?.teams[0]?.teamId?.teamName}
                    </p>
                  </span>
                  <p className="vs">VS</p>
                  <span className="d-flex justify-content-center align-items-center">
                    <LazyLoadImage
                      src={matchDetails?.match?.teams[1]?.teamId?.teamImageUrl}
                      alt="flag"
                      width={30}
                      height={30}
                      className="rounded-circle me-3"
                    />
                    <p className="header-font">
                      {matchDetails?.match?.teams[1]?.teamId?.teamName}
                    </p>
                  </span>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "250px" }}
                >
                  <div>
                    <span className="d-flex align-items-center mb-1">
                      <LazyLoadImage
                        src={Images.calendar}
                        alt="flag"
                        width={15}
                        height={15}
                        className="me-2"
                      />
                      <p className="header-sec-font">
                        {moment(matchDetails?.match?.date).format("DD-MM-YYYY")}
                      </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <LazyLoadImage
                        src={Images.location}
                        alt="flag"
                        width={15}
                        height={15}
                        className="me-2"
                      />
                      <p className="header-sec-font">
                        {matchDetails?.match?.venue}
                      </p>
                    </span>
                  </div>
                  <div>
                    <span className="d-flex align-items-center mb-1">
                      <LazyLoadImage
                        src={Images.trophyIcon}
                        alt="flag"
                        width={9.22}
                        height={15}
                        className="me-2"
                      />
                      <p className="header-sec-font">
                        {matchDetails?.match?.tournamentId?.tournamentName}
                      </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <LazyLoadImage
                        src={Images.matchIcon}
                        alt="flag"
                        width={20.38}
                        height={15}
                        className="me-2"
                      />
                      <p className="header-sec-font">
                        Match {matchDetails?.match?.matchNumber}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <section className="d-flex">
                <div className="left-layout" style={{ width: "40vw" }}>
                  {/* -------------------------------Camera Section--------------------------------- */}
                  <CameraRecorder
                    recordedVideo={recordedVideo}
                    setRecordedVideo={setRecordedVideo}
                    showCamera={showCamera}
                    setShowCamera={setShowCamera}
                    saveBall={saveBall}
                    videoBlob={videoBlob}
                    setVideoBlob={setVideoBlob}
                    // undoBall={undoBall}
                  />
                  {/* -------------------------------Scoring Buttons--------------------------------- */}

                  <div
                    className="scoring-buttons px-2"
                    style={{ height: "31vh" }}
                  >
                    <OptionsButtons
                      heading="SCORE"
                      options={scoreOptions}
                      selectedValue={score}
                      setSelectedValue={setScore}
                    />
                    <OptionsButtons
                      heading="WIDE"
                      options={wideOptions}
                      selectedValue={wide}
                      setSelectedValue={setWide}
                    />
                    <OptionsButtons
                      heading="NO-BALL"
                      options={noBallOptions}
                      selectedValue={noBall}
                      setSelectedValue={setNoBall}
                    />
                    <OptionsButtons
                      heading="BYES"
                      options={byesOptions}
                      selectedValue={byes}
                      setSelectedValue={setByes}
                    />
                    <OptionsButtons
                      heading="LEG BYES"
                      options={legByesOptions}
                      selectedValue={legByes}
                      setSelectedValue={setLegByes}
                    />
                    <OptionsButtons
                      heading="OUT"
                      options={outOptions}
                      selectedValue={out}
                      setSelectedValue={setOut}
                    />
                  </div>
                  <div className="table-container">
                    {/* -------------------------------Balls Table--------------------------------- */}

                    <DataTable
                      columns={columns1}
                      data={tableData1}
                      // pagination
                    />
                  </div>
                </div>
                <div style={{ width: "60vw" }}>
                  <div
                    style={{
                      height: "62vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="right-layout d-flex">
                      <section className="right-layout-left w-50">
                        <div
                          className="score-card-match d-flex flex-column justify-content-center"
                          style={{ height: "10vh" }}
                        >
                          <div className="target-details d-flex align-items-center justify-content-between">
                            {/* -------------------------------Score Board--------------------------------- */}

                            <div>
                              <span className="d-flex align-items-center">
                                <LazyLoadImage
                                  src={
                                    matchDetails?.match?.teams[0]?.teamId
                                      ?.teamImageUrl
                                  }
                                  alt="flag"
                                  style={{ width: "20px", height: "20px" }}
                                  className="rounded-circle me-2"
                                />
                                <p className="d-flex align-items-center target-details-p">
                                  {matchDetails?.match?.teams[0]?.runs}/
                                  {matchDetails?.match?.teams[0]?.wickets}
                                  <h6 className="target-details-h6 ms-1">
                                    (
                                    {matchDetails?.match?.teams[0]?.oversPlayed}
                                    )
                                  </h6>
                                </p>
                              </span>
                            </div>
                            <div className="vs2">
                              {/* {matchDetails?.match?.matchType} */}
                              {parseFloat(
                                matchDetails?.match?.teams[0]?.dlsOvers
                              ) > 0
                                ? matchDetails?.match?.teams[0]?.dlsOvers
                                : matchDetails?.match?.totalOvers}
                            </div>
                            <div className="right-align-container">
                              <span className="d-flex align-items-center">
                                <LazyLoadImage
                                  src={
                                    matchDetails?.match?.teams[1]?.teamId
                                      ?.teamImageUrl
                                  }
                                  alt="flag"
                                  style={{ width: "20px", height: "20px" }}
                                  className="rounded-circle me-2"
                                />
                                <p className="d-flex align-items-center target-details-p">
                                  {matchDetails?.match?.teams[1]?.runs}/
                                  {matchDetails?.match?.teams[1]?.wickets}
                                  <h6 className="target-details-h6 ms-1">
                                    {" "}
                                    (
                                    {matchDetails?.match?.teams[1]?.oversPlayed}
                                    )
                                  </h6>
                                </p>
                              </span>
                            </div>
                          </div>
                          <span className="d-flex justify-content-between align-items-center descrip mt-1">
                            <p className="">
                              CRR:{" "}
                              {(
                                (matchDetails?.match?.teams[0]?.runs /
                                  (matchDetails?.match?.teams[0]?.ballsPlayed ||
                                    1)) *
                                6
                              ).toFixed(2) || "0.00"}
                              {isSecondInnings && (
                                <p>
                                  RRR:{" "}
                                  {(
                                    ((matchDetails?.match?.teams[1]?.dlsScore
                                      ? matchDetails?.match?.teams[1]?.dlsScore
                                      : matchDetails?.match?.teams[1]?.runs -
                                        matchDetails?.match?.teams[0]?.runs) /
                                      ((matchDetails?.match?.teams[1]?.dlsOvers
                                        ? oversToBalls(
                                            matchDetails?.match?.teams[1]
                                              ?.dlsOvers
                                          )
                                        : oversToBalls(
                                            matchDetails?.match?.teams[1]
                                              ?.oversPlayed
                                          )) -
                                        (matchDetails?.match?.teams[0]?.dlsOvers
                                          ? oversToBalls(
                                              matchDetails?.match?.teams[0]
                                                ?.dlsOvers
                                            )
                                          : oversToBalls(
                                              matchDetails?.match?.teams[0]
                                                ?.oversPlayed
                                            )) || 1)) *
                                    6
                                  ).toFixed(2) || "0.00"}
                                  Target:{" "}
                                  {matchDetails?.match?.teams[1]?.dlsScore
                                    ? parseInt(
                                        matchDetails?.match?.teams[1]?.dlsScore
                                      ) + 1
                                    : parseInt(
                                        matchDetails?.match?.teams[1]?.runs
                                      ) + 1}
                                </p>
                              )}
                            </p>
                          </span>
                        </div>
                        {/* -------------------------------BAtting OPtions--------------------------------- */}

                        <p className="bigHeading text-center">BATTING</p>
                        <div className="batting-cards">
                          <OptionCard
                            heading="FEET"
                            options={feetOptions}
                            selectedValue={feet}
                            setSelectedValue={setFeet}
                            secWidth={"98%"}
                            secHeight={"auto"}
                            btnFontSize={"clamp(0.5rem, 1.5vh, 1rem)"} // Adjusts dynamically
                            btnHeight={"auto"}
                            btnWidth={"auto"}
                            gap={"3px"}
                          />
                          <div className="d-flex justify-content-around w-100">
                            <OptionCard
                              heading="OFF SHOTS"
                              options={offShotOptions}
                              selectedValue={offShot}
                              setSelectedValue={setOffShot}
                              secHeight={"auto"}
                              secWidth={"28%"}
                              btnFontSize={"clamp(0.5rem, 1.5vh, 1rem)"}
                              btnHeight={"auto"}
                              btnWidth={"100%"}
                              gap={"4px"}
                            />
                            <div style={{ width: "70%" }}>
                              <OptionCard
                                heading="LEG SHOTS"
                                options={legShotOptions}
                                selectedValue={legShot}
                                setSelectedValue={setLegShot}
                                secHeight={"auto"}
                                secWidth={"100%"}
                                btnFontSize={"clamp(0.5rem, 1.5vh, 1rem)"}
                                btnHeight={"auto"}
                                btnWidth={"auto"}
                                gap={"3px"}
                              />

                              <OptionCard
                                heading="TECHNICAL ERRORS"
                                options={technicalErrorOptions}
                                selectedValue={technicalError}
                                setSelectedValue={setTechnicalError}
                                secWidth={"100%"}
                                secHeight={"auto"}
                                btnFontSize={"clamp(0.5rem, 1.5vh, 1rem)"}
                                btnHeight={"auto"}
                                btnWidth={"auto"}
                                gap={"3px"}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="right-layout-right w-50">
                        <div
                          className="current-scores align-items-center"
                          style={{ height: "10vh" }}
                        >
                          {/* -------------------------------BAtting Card--------------------------------- */}

                          <div>
                            <span>
                              <LazyLoadImage
                                src={Images.pinkBat}
                                alt="flag"
                                width={15}
                                height={15}
                                className="me-2"
                                onClick={() => {
                                  //swap the striker and non-striker
                                  swapStrikerAndNonStriker();
                                }}
                              />
                              <p className="current-scores-p">
                                {
                                  matchDetails?.match?.facingBatsman?.player
                                    ?.playerName
                                }
                                :
                                {
                                  matchDetails?.match?.facingBatsman
                                    ?.runsAchieved
                                }{" "}
                                * (
                                {
                                  matchDetails?.match?.facingBatsman
                                    ?.ballsPlayed
                                }
                                )
                                {/* <h6 className="current-scores-h6">
                        6s: 01 | 4s: 01 | Dots: 5 | Strike Rate: 80
                      </h6> */}
                              </p>
                            </span>
                            <span>
                              <LazyLoadImage
                                src={Images.pinkBat}
                                alt="flag"
                                width={15}
                                height={15}
                                style={{ visibility: "hidden" }}
                                className="me-2"
                              />
                              <p className="current-scores-p">
                                {
                                  matchDetails?.match?.nonStriker?.player
                                    ?.playerName
                                }
                                :{matchDetails?.match?.nonStriker?.runsAchieved}{" "}
                                * (
                                {matchDetails?.match?.nonStriker?.ballsPlayed})
                                {/* <h6 className="current-scores-h6">
                        6s: 01 | 4s: 01 | Dots: 5 | Strike Rate: 80
                      </h6> */}
                              </p>
                            </span>
                          </div>
                          <div className="right-align-container">
                            {/* -------------------------------Bowling Card--------------------------------- */}

                            <span className="d-flex align-items-start text-end">
                              <LazyLoadImage
                                src={Images.pinkBall}
                                alt="flag"
                                width={10}
                                height={10}
                                className="mt-1"
                              />
                              <p className="current-scores-p">
                                {
                                  matchDetails?.match?.bowler?.player
                                    ?.playerName
                                }
                                :
                                <b className="">
                                  (
                                  {calculateOvers(
                                    matchDetails?.match?.bowler?.ballsDelivered
                                  )}
                                  )
                                </b>
                                {/* <h6 className="current-scores-h6">
                        Runs: 26 | Wkts: 02 | Maiden: 00 | Econ: 7.5
                      </h6> */}
                                <span className="d-flex flex-wrap">
                                  {ballsArray.map((ball) => (
                                    <h5
                                      className="overs"
                                      style={{
                                        backgroundColor: ball.isWicket
                                          ? "rgba(231, 50, 147, 1)" // Pink for wickets
                                          : ball.wide ||
                                            ball.noBall ||
                                            ball.bye ||
                                            ball.legBye
                                          ? "rgb(231, 219, 50)" // Yellow for extras
                                          : "white", // White for normal runs
                                        color: ball.isWicket
                                          ? "white"
                                          : "black",
                                        textAlign: "center",
                                        // padding: "5px 12px",
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      {/* ✅ Show Runs on Top if Extra */}
                                      {ball.wide ||
                                      ball.noBall ||
                                      ball.bye ||
                                      ball.legBye ? (
                                        <>
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              // display: "block",
                                            }}
                                          >
                                            {ball.score > 0 ? ball.score : null}
                                          </span>
                                          <span style={{ fontSize: "10px" }}>
                                            {ball.wide ? (
                                              <div className="d-flex flex-column">
                                                <p className="m-0">
                                                  {ball.wide - 1}
                                                </p>
                                                <p
                                                  className="m-0"
                                                  style={{ fontSize: "7px" }}
                                                >
                                                  WD
                                                </p>
                                              </div>
                                            ) : ball.noBall ? (
                                              <div className="d-flex flex-column">
                                                <p className="m-0">
                                                  {ball.noBall - 1}
                                                </p>
                                                <p
                                                  className="m-0"
                                                  style={{ fontSize: "7px" }}
                                                >
                                                  NB
                                                </p>
                                              </div>
                                            ) : ball.bye ? (
                                              <div className="d-flex flex-column">
                                                <p className="m-0">
                                                  {ball.bye}
                                                </p>
                                                <p
                                                  className="m-0"
                                                  style={{ fontSize: "7px" }}
                                                >
                                                  b
                                                </p>
                                              </div>
                                            ) : ball.legBye ? (
                                              <div className="d-flex flex-column">
                                                <p className="m-0">
                                                  {ball.legBye}
                                                </p>
                                                <p
                                                  className="m-0"
                                                  style={{ fontSize: "7px" }}
                                                >
                                                  lb
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        /* ✅ Show Wicket or Normal Runs */
                                        <span style={{ fontWeight: "bold" }}>
                                          {ball.isWicket
                                            ? "W"
                                            : ball.score > 0
                                            ? ball.score
                                            : "•"}
                                        </span>
                                      )}
                                    </h5>
                                  ))}
                                </span>
                              </p>
                            </span>
                          </div>
                        </div>
                        {/* -------------------------------Bowling Options--------------------------------- */}

                        <p className="bigHeading text-center">BOWLING</p>
                        <div className="bowling-cards d-flex justify-content-around">
                          {/* First Column - LINE & CREASE */}
                          <div style={{ width: "32%" }}>
                            <OptionCard
                              heading="LINE"
                              options={lineOptions}
                              selectedValue={line}
                              setSelectedValue={setLine}
                              secHeight="auto"
                              secWidth="100%"
                              btnFontSize="clamp(0.5rem, 1.5vh, 1rem)"
                              btnHeight="auto"
                              btnWidth="145px"
                              gap="4px"
                            />
                            <OptionCard
                              heading="CREASE"
                              options={creaseOptions}
                              selectedValue={crease}
                              setSelectedValue={setCrease}
                              secHeight="auto"
                              secWidth="100%"
                              btnFontSize="clamp(0.5rem, 1.5vh, 1rem)"
                              btnHeight="auto"
                              btnWidth="145px"
                              gap="4px"
                            />
                          </div>

                          {/* Second Column - LENGTH & PACE */}
                          <div style={{ width: "32%" }}>
                            <OptionCard
                              heading="LENGTH"
                              options={lengthOptions}
                              selectedValue={length}
                              setSelectedValue={setLength}
                              secHeight="auto"
                              secWidth="100%"
                              btnFontSize="clamp(0.5rem, 1.5vh, 1rem)"
                              btnHeight="auto"
                              btnWidth="158px"
                              gap="4px"
                            />
                            <div className="heading-options-line">
                              <p className="heading-options-line-p">PACE</p>
                              <div>
                                <Form.Control
                                  type="float"
                                  style={{
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    color: "white",
                                    marginBottom: "5px",
                                    textAlign: "center",
                                    fontSize: "clamp(0.5rem, 1.5vh, 1rem)",
                                  }}
                                  placeholder="Custom Pace"
                                  value={pace}
                                  onChange={(e) => setPace(e.target.value)}
                                />
                                <SingleButton
                                  content="Done"
                                  backgroundColor="rgba(231, 50, 147, 1)"
                                  styles="justify-content-center"
                                  buttonStyles={{
                                    fontSize: "clamp(0.5rem, 1.5vh, 1rem)",
                                    padding: "5px 10px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Third Column - VARIATION */}
                          <div style={{ width: "32%" }}>
                            {isVariation ? (
                              <VariationSpin
                                heading="VARIATION"
                                selectedValue={spinner}
                                setSelectedValue={setSpinner}
                                variation="true"
                                isVariation={isVariation}
                                setIsVariation={setIsVariation}
                              />
                            ) : (
                              <VariationFast
                                heading="VARIATION"
                                selectedValue={variation}
                                setSelectedValue={setVariation}
                                variation="true"
                                isVariation={isVariation}
                                setIsVariation={setIsVariation}
                                variationPace={variationPace}
                                setVariationPace={setVariationPace}
                              />
                            )}
                          </div>
                        </div>
                      </section>
                    </div>

                    <OptionCard
                      heading="EVENTS"
                      options={eventOptions}
                      selectedValue={event}
                      setSelectedValue={setEvent}
                      secHeight="auto" // Fills the available height
                      secWidth="100%"
                      btnFontSize="clamp(0.5rem, 1.5vh, 1rem)"
                      btnHeight="auto"
                      btnWidth="auto"
                      gap="5px"
                    />
                  </div>
                  {/* -------------------------------Graphs--------------------------------- */}

                  <BallLengthTracker
                    score={score}
                    ballLengthCords={ballLengthCords}
                    setBallLengthCords={setBallLengthCords}
                  />
                  <WagonWheelTracker
                    score={score}
                    wagonWheelCords={wagonWheelCords}
                    setWagonWheelCords={setWagonWheelCords}
                  />
                  <BallLineTracker
                    score={score}
                    ballLineCords={ballLineCords}
                    setBallLineCords={setBallLineCords}
                  />
                  <Modal
                    show={isSelectBowlerModal}
                    onHide={() => setIsSelectBowlerModal(false)}
                    centered
                    dialogClassName="custom-modal"
                  >
                    <Modal.Body>
                      <SelectBowlerModal
                        handleClose={() => setIsSelectBowlerModal(false)}
                        matchDetails={matchDetails}
                        setMatchDetails={setMatchDetails}
                        setBallsArray={setBallsArray}
                      />
                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={isWicketModal}
                    onHide={() => setIsWicketModal(false)}
                    centered
                    dialogClassName="custom-modal"
                  >
                    <Modal.Body>
                      <WicketModal
                        handleClose={() => setIsWicketModal(false)}
                        matchDetails={matchDetails}
                        dismissedBatsman={dismissedBatsman}
                        setDismissedBatsman={setDismissedBatsman}
                        newBatsman={newBatsman}
                        setNewBatsman={setNewBatsman}
                        fielder={fielder}
                        setFielder={setFielder}
                        out={out}
                      />
                    </Modal.Body>
                  </Modal>
                  <Modal
                    show={isNewInningsModal}
                    onHide={() => setIsNewInningsModal(false)}
                    centered
                    dialogClassName="custom-modal"
                  >
                    <Modal.Body>
                      <NewInningsModal
                        show={isNewInningsModal}
                        handleClose={() => setIsNewInningsModal(false)}
                        teams={matchDetails?.match.teams}
                        setSelectedFacingBatsman={setSelectedFacingBatsman}
                        setSelectedNonStriker={setSelectedNonStriker}
                        setSelectedBowler={setSelectedBowler}
                        handleInningsSelection={handleInningsSelection}
                      />
                    </Modal.Body>
                  </Modal>
                </div>
              </section>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default MatchScoring;
