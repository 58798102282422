import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { deleteRequest, getRequest, uploadURL } from "../../../helper/api";
import Layout from "../../../layouts/Layout";
import { Images } from "../../../constants/Images";
import BackButton from "../../../constants/BackButton";
import PairButtons from "../../../constants/PairButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { getItemFromLocalStorage } from "../../../helper/helper";
import TableSkeleton from "../../../constants/Skeleton Loaders/TableSkeleton";

const AddNewPlayers = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [existingPlayers, setExistingPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [images, setImages] = useState([]);

  const [search, setSearch] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };
  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log("Rows per page changed to:", newPerPage, page);
    setPerPage(newPerPage);
    setCurrentPage(page); // Reset to the first page
  };

  const fetchExitingPlayers = async (currentPage, perPage, search) => {
    try {
      setLoading(true);
      console.log("Fetching existing players...", currentPage, perPage, search);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/player/get-players`,
        token,
        { page: currentPage, limit: perPage, search: search }
      );
      if (result?.status === 200) {
        setExistingPlayers(result.data.players);
        setTotalRows(result.data.totalPlayers);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch existing players"
        );
      }
    } catch (err) {
      console.error("Error fetching existing players:", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchExitingPlayers(currentPage, perPage, search);
  }, [search, currentPage, perPage]);

  const [players, setPlayers] = useState([]);
  const handleCheckboxChange = (player) => {
    setPlayers((prev) => {
      const isSelected = prev?.some((p) => p._id === player._id);
      if (isSelected) {
        return prev?.filter((p) => p._id !== player._id);
      } else {
        if (prev?.length > 0) {
          return [
            ...prev,
            {
              _id: player._id,
              selected: true,
              name: player.playerName,
              image: player.playerImageUrl,
            },
          ];
        } else {
          return [
            {
              _id: player._id,
              selected: true,
              name: player.playerName,
              image: player.playerImageUrl,
            },
          ];
        }
      }
    });
  };

  const handleDelete = async (playerId) => {
    try {
      const { result, error } = await deleteRequest(
        `/api/secure/player/remove-player?_id=${playerId}`
      );
      if (result?.status === 200) {
        toast.success("Team deleted successfully.", { position: "top-center" });
        fetchExitingPlayers();
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to delete team"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  const columns = [
    {
      name: "Actions",
      cell: (row) => (
        <Form.Check
          type="checkbox"
          id={`checkbox-${row._id}`}
          checked={players?.some((p) => p._id === row._id)}
          onChange={() => handleCheckboxChange(row)}
        />
      ),
      maxWidth: "100px",
    },
    {
      name: "Profile",
      selector: (row) => (
        <LazyLoadImage
          src={row.playerImageUrl ? row.playerImageUrl : Images.profile}
          alt="profile"
          width="40"
          height="40"
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "center",
            border: "1px solid rgba(231, 50, 147, 1)",
          }}
        />
      ),
      maxWidth: "80px",
    },
    {
      name: "Player Name",
      selector: (row) => row.playerName,
      sortable: true,
    },
    {
      name: "Owner",
      cell: (row) => (
        <div
          title={row.owner.email}
          className="d-flex gap-1 justify-content-between align-items-center"
        >
          <div className="me-2" title={row.owner.email}>
            <LazyLoadImage
              src={
                row.owner.profileImageUrl ? row?.owner?.profileImageUrl : null
              }
              alt={row.owner.email}
              width="40"
              height="40"
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                objectPosition: "center",
                border: "1px solid rgba(231, 50, 147, 1)",
              }}
            />
          </div>
          <div className="text-light">
            <p
              style={{
                wordBreak: "break-all",
                margin: 0,
              }}
              title={row.owner.email}
            >
              {row.owner.fullName}
            </p>
            {/* <p
                        style={{
                          wordBreak: "break-all",
                          margin: 0,
                        }}
                      >
                        {row.owner.email}
                      </p> */}
          </div>
        </div>
      ),
      maxWidth: "200px",
      sortable: false,
      style: {
        justifyContent: "left",
      },
    },
  ];

  useEffect(() => {
    if (state) {
      setTeamName(state?.teamName);
      setSelectedPlayers(state?.selectedPlayers);
      setImages(state?.images);
    }
  }, [state]);

  useEffect(() => {
    setPlayers(selectedPlayers !== null ? selectedPlayers : []);
  }, [selectedPlayers]);

  return (
    <Layout>
      <section
        id="addNewPlayer"
        style={{
          // backgroundImage: `url(${Images.background3})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          // background: "rgb(17,28,65)",
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        <Container>
          {/* <div className="d-flex justify-content-between align-items-center"> */}
            <BackButton
              icon={Images.backArrow}
              content="Back"
              onClick={() => {
                if (state?.isEdit) {
                  navigate(`/dashboard/edit-team?_id=${state.teamId}`, {
                    state: {
                      players,
                      teamName,
                      images,
                      isEdit: state?.isEdit,
                      teamId: state.teamId,
                    },
                  });
                } else {
                  navigate("/dashboard/create-team", {
                    state: {
                      players,
                      teamName,
                      images,
                      isEdit: false,
                      teamId: null,
                    },
                  });
                }
              }}
            />{" "}
            <p className="text-light mt-2 text-center font-secondary fs-4 fw-bold">
              Add New Player
            </p>
            {/* <div></div>
          </div> */}
        </Container>
        <Container className="logo-table-container mt-5 ">
          {/* <LazyLoadImage
            className="logo mt-3 mb-5"
            src={Images.logo}
            width={100}
            alt="Logo"
          /> */}
          <div className="table-container">
            <div className="pb-3 heading d-flex align-items-center border-0">
              <h3 className="mb-0 me-3 heading">SEARCH PLAYER</h3>
              <FormControl
                type="text"
                placeholder="Name"
                className="w-25 fs-6 p-0 ps-2"
                value={search}
                onChange={handleSearchCategory}
              />
            </div>
            {loading ? (
              <TableSkeleton
                row={perPage}
                head={[
                  {
                    name: "Actions",
                    single: true,
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell",
                    width: 30,
                    height: 30,
                  },
                  {
                    name: "Profile",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv elpSoT bOmZtP rdt_TableCell",
                    width: 30,
                    height: 30,
                    circle: true,
                  },
                  {
                    name: "Player Name",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                    width: 150,
                    height: 20,
                  },
                  {
                    name: "Owner",
                    className:
                      "ssc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv kPFmFZ jEzKnw rdt_TableCell",
                    width: [30, 100], // Profile image width and name width
                    height: [30, 20], // Profile image height and name height
                    circle: true,
                  },
                ]}
              />
            ) : (
              <DataTable
                columns={columns}
                data={existingPlayers}
                pagination
                highlightOnHover
                responsive
                paginationPerPage={perPage}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationServer
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                noDataComponent={<p>No data found</p>}
                customStyles={{
                  headCells: {
                    style: {
                      border: "1px solid #ccc",
                      color: "#fff",
                    },
                  },
                  cells: {
                    style: {
                      borderRight: "1px solid #ccc",
                      color: "#fff",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    },
                  },
                }}
              />
            )}
          </div>
        </Container>
        <div className="container mt-3 pb-3">
          <PairButtons
            content1="CANCEL"
            onClick1={() => {
              if (state?.isEdit) {
                navigate(`/dashboard/edit-team?_id=${state.teamId}`, {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: state?.isEdit,
                    teamId: state.teamId,
                  },
                });
              } else {
                navigate("/dashboard/create-team", {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: false,
                    teamId: null,
                  },
                });
              }
            }}
            icon1={Images.backArrowButton}
            backgroundColor1={"#fff"}
            color1={"#000"}
            content2="DONE"
            onClick2={() => {
              if (players.length === 0) {
                toast.error("Please select at least one player", {
                  position: "top-center",
                  theme: "colored",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                });
                return;
              }
              console.log(players, teamName);
              if (state?.isEdit) {
                navigate(`/dashboard/edit-team?_id=${state.teamId}`, {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: state?.isEdit,
                    teamId: state.teamId,
                  },
                });
              } else {
                navigate("/dashboard/create-team", {
                  state: {
                    players,
                    teamName,
                    images,
                    isEdit: false,
                    teamId: null,
                  },
                });
              }
            }}
            icon2={Images.whiteNextArrow}
            backgroundColor2={"rgba(231, 50, 147, 1)"}
            color2={"#fff"}
            reversedIcon2={true}
          />
        </div>
      </section>
    </Layout>
  );
};

export default AddNewPlayers;
