import React from "react";
import { max } from "underscore";

const VariationSpin = ({
  heading,
  secHeight,
  secWidth,
  variation,
  isVariation,
  setIsVariation,
  selectedValue,
  setSelectedValue,
}) => {
  const buttonStyles = {
    color: "white",
    height: "auto",
    width: "100%",
    border: "none",
    borderRadius: "5px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.6rem",
  };

  return (
    <div
      className="heading-options-line"
      style={{
        height: secHeight,
        width: secWidth,
      }}
    >
      <p className="d-flex align-items-center justify-content-around heading-options-line-p">
        {heading}
        <label
          style={{ background: isVariation ? "#e7e7e7" : "transparent" }}
          className="react-switch"
        >
          <input
            checked={isVariation}
            onChange={() => setIsVariation(!isVariation)}
            className="react-switch-checkbox"
            type="checkbox"
          />
          <div className="react-switch-button" />
          <div
            className="react-switch-labels"
            style={{
              backgroundColor: isVariation ? "#be8202" : "red",
              borderRadius: "50px",
            }}
          >
            <span style={{ color: isVariation ? "#fff" : "red" }}>SPIN</span>
            <span style={{ color: !isVariation ? "#fff" : "#be8202" }}>
              PACE
            </span>
          </div>
        </label>
      </p>

      {/* Buttons Section */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "1.2px",
        }}
      >
        <p style={{ fontSize: "0.5rem", margin: "0", fontWeight: "bold" }}>
          OFF BREAK
        </p>
        <hr
          style={{
            width: "100%",
            backgroundColor: "#fff",
            margin: "0",
          }}
        />
        <button
          style={{
            backgroundColor:
              selectedValue === "Off Spin"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Off Spin")}
        >
          Off Spin
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Arm Ball"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Arm Ball")}
        >
          Arm Ball
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Dusra"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Dusra")}
        >
          Dusra
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Carrom Ball"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Carrom Ball")}
        >
          Carrom Ball
        </button>
        <hr
          style={{
            width: "100%",
            backgroundColor: "#fff",
            margin: "0",
          }}
        />
        <p style={{ fontSize: "0.5rem", margin: "0", fontWeight: "bold" }}>
          LEG BREAK/CHINAMAN
        </p>
        <hr
          style={{
            width: "100%",
            backgroundColor: "#fff",
            margin: "0",
          }}
        />
        <button
          style={{
            backgroundColor:
              selectedValue === "Leg Spin"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Leg Spin")}
        >
          Leg Spin
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Googley"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Googley")}
        >
          Googley
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Flipper"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Flipper")}
        >
          Flipper
        </button>
        <hr
          style={{
            width: "100%",
            backgroundColor: "#fff",
            margin: "0",
          }}
        />
        <p style={{ fontSize: "0.5rem", margin: "0", fontWeight: "bold" }}>
          LEFT ARM SPIN
        </p>
        <hr
          style={{
            width: "100%",
            backgroundColor: "#fff",
            margin: "0",
          }}
        />
        <button
          style={{
            backgroundColor:
              selectedValue === "Left Arm Spin"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Left Arm Spin")}
        >
          Left Arm Spin
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Arm Ball"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Arm Ball")}
        >
          Arm Ball
        </button>
        <hr
          style={{
            width: "100%",
            backgroundColor: "#fff",
            margin: "0",
          }}
        />
        <button
          style={{
            backgroundColor:
              selectedValue === "No Movement"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("No Movement")}
        >
          No Movement
        </button>
      </div>
    </div>
  );
};

export default VariationSpin;
