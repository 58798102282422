import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../constants/Images";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  FormControl,
} from "react-bootstrap";
import NavComponent from "../../constants/NavComponent";
import BackButton from "../../constants/BackButton";
import { toast } from "react-toastify";
import {
  getRequest,
  postRequestForm,
  putRequest,
  uploadURL,
} from "../../helper/api";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../helper/helper";
import Select from "react-select";

import ReactImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import SingleButton from "../../constants/SingleButton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import routes from "../../helper/routes";
import { AsyncPaginate } from "react-select-async-paginate";
import ProfileCardSkeleton from "../../constants/Skeleton Loaders/ProfileCardSkeleton";
import moment from "moment";

const ProfileSettings = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [imageList, setImageList] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const [trialExpiry, setTrialExpiry] = useState(null);

  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trialOptions] = useState([
    { value: 1, label: "1 Day Test" },
    { value: 14, label: "Trial 14 Days" },
    { value: 30, label: "1 month" },
    { value: 90, label: "3 months" },
    { value: 180, label: "6 months" },
    { value: 365, label: "1 year" },
  ]);
  const [trial, setTrial] = useState(null); // Selected trial period

  const getLabelByValue = (options, value) => {
    const option = options.find((option) => option.value === value);
    return option?.label;
  };
  const fetchProfile = async () => {
    try {
      setDataLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const user = JSON.parse(getItemFromLocalStorage("USER"));
      console.log("user", user);
      // Fetch profile based on userId (from query param or local storage)
      const { result, error } = await getRequest(
        `/api/secure/v2/${user?.roleName.replace(" ", "")}/profile`,
        token
      );

      if (result?.status === 200) {
        const { user } = result.data;
        const {
          firstName,
          lastName,
          email,
          profileImage,
          profileImageUrl,
          permissions,
          trialPeriod,
          trialExpiry,
          roleName,
        } = user;
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(email);
        setProfileImageUrl(profileImageUrl);
        if (roleName) {
          setRole(roleName);
          console.log("role", role);
        }
        if (roleName === "Admin") {
          setTrialExpiry(trialExpiry);
          console.log("trialPeriod", trialPeriod);
        }
        if (profileImage) {
          // if (permissions) {
          //   setPermissions(permissions);
          // }

          setImageList([{ data_url: profileImageUrl }]);
        }
        // if (trialPeriod) {
        //   setTrial({
        //     value: trialPeriod,
        //     label: getLabelByValue(trialOptions, trialPeriod),
        //   });
        // }
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message || "User not found.", {
          position: "top-center",
        });
      }
      setDataLoading(false);
    } catch (err) {
      toast.error(err.message || "Failed to load profile data.", {
        position: "top-center",
      });
    }
  };

  const handlePermissionsChange = (selectedOption) => {
    setPermissions(selectedOption);
  };

  const handleTrialChange = (selectedOption) => {
    setTrial(selectedOption);
  };

  const onChangeImage = (imageList, addUpdateIndex) => {
    setImageList(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (!firstName) {
        toast.error("First Name is required.", {
          position: "top-center",
        });
      }
      if (!lastName) {
        toast.error("Last Name is required.", {
          position: "top-center",
        });
      }
      if (role !== "Admin" && role !== "Super Admin") {
        if (!email) {
          toast.error("Email is required.", {
            position: "top-center",
          });
        }
      }

      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");

      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);

      if (role !== "Admin" && role !== "Super Admin") {
        permissions.forEach((permission) => {
          formData.append("permissions[]", permission?.value);
        });
        formData.append("email", email);
        formData.append(
          "trialPeriod",
          typeof trial === "object" ? trial.value : trial
        );
      }

      if (featuredImage) formData.append("profileImage", featuredImage);
      console.log("formData", Object.fromEntries(formData));

      const user = JSON.parse(getItemFromLocalStorage("USER"));
      const { result, error } = await putRequest(
        `/api/secure/v2/${user?.roleName.replace(" ", "")}/profile`,
        token,
        formData
      );

      if (result?.status === 200) {
        toast.success("Profile updated successfully!", {
          position: "top-center",
        });
        setItemInLocalStorage("USER", JSON.stringify(result.data.user));
        window.location.reload();
      } else if (error?.response?.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
        });
        window.location.reload();
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message || "An error occurred.", {
          position: "top-center",
        });
      }
    } catch (err) {
      toast.error(err.message || "An error occurred.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPermisions = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/permission/get-permissions`,
        token,
        {
          page: page,
          search: search,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, permissions } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: permissions,
            hasMore: false,
          };
        } else {
          return {
            options: permissions,
            hasMore: permissions?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Permissions Error", error);
    }
  };
  // Fetch profile data on mount
  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <section
        id="profileSettings"
        className="pb-5 pb-md-0"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <NavComponent
          user={{
            email: email,
            profileImageUrl,
            firstName,
            lastName,
          }}
        />
        <BackButton
          icon={Images.backArrow}
          content="Back"
          onClick={() => {
            navigate("/dashboard");
          }}
        />
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={6} md={8} xs={12}>
              {dataLoading ? (
                <ProfileCardSkeleton />
              ) : (
                <div className="profile-card">
                  <Row className="align-items-center">
                    <Col
                      lg={4}
                      md={4}
                      xs={12}
                      className="d-flex justify-content-center justify-content-md-start"
                    >
                      <ReactImageUploading
                        value={imageList}
                        onChange={onChangeImage}
                        dataURLKey="data_url"
                        maxNumber={1}
                        maxFileSize={10000000}
                        acceptType={["jpeg", "jpg", "gif", "png"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageUpdate,
                          isDragging,
                          dragProps,
                        }) => (
                          <>
                            <div className="upload__image-wrapper mb-3 ">
                              {imageList?.length === 0 ? (
                                <div
                                  className="image-item d-flex justify-content-center"
                                  style={{
                                    border: "1px solid #ccc",
                                    cursor: "pointer",
                                    width: "120px",
                                    height: "120px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundImage: `url(${
                                      profileImageUrl || Images.profile
                                    })`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundBlendMode: "overlay",
                                    backgroundColor: "rgba(231, 50, 147, .5)",
                                    border: "1px solid rgba(231, 50, 147, 1)",
                                    borderRadius: "50%",
                                    ...(isDragging ? { color: "red" } : {}),
                                  }}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color="#000"
                                    style={{
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                              ) : (
                                imageList?.map((image, index) => (
                                  <div
                                    key={index}
                                    className="image-item position-relative d-inline-block"
                                  >
                                    <LazyLoadImage
                                      src={image["data_url"]}
                                      width={120}
                                      height={120}
                                      className="rounded-circle"
                                      alt="uploaded"
                                    />
                                    <Button
                                      className="position-absolute bg-light p-1 rounded-circle translate-middle p-0"
                                      variant="link"
                                      onClick={() => onImageUpdate(index)}
                                      style={{
                                        lineHeight: 1,
                                        right: -10,
                                        bottom: -10,
                                        border: "1px solid rgb(231, 50, 147)",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        color="rgb(231, 50, 147)"
                                      />
                                    </Button>
                                  </div>
                                ))
                              )}
                            </div>
                          </>
                        )}
                      </ReactImageUploading>
                    </Col>
                    <Col
                      lg={8}
                      md={8}
                      xs={12}
                      className="text-center text-md-start"
                    >
                      <h3
                        style={{ wordBreak: "break-all" }}
                      >{`${firstName} ${lastName}`}</h3>
                      <p style={{ wordBreak: "break-all" }}> {email}</p>
                    </Col>
                  </Row>
                  <Row className="text-input-container">
                    <Col xs={12} sm={5}>
                      <p>First Name:</p>
                    </Col>
                    <Col xs={12} sm={7}>
                      <FormControl
                        className="text-start text-sm-end"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="text-input-container">
                    <Col xs={12} sm={5}>
                      <p>Last Name:</p>
                    </Col>
                    <Col xs={12} sm={7}>
                      <FormControl
                        className="text-start text-sm-end"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="text-input-container">
                    <Col xs={12} sm={5}>
                      <p>Email:</p>
                    </Col>
                    <Col xs={12} sm={7}>
                      <FormControl
                        className="text-start text-sm-end disabled"
                        type="text"
                        name="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        disabled
                        style={{
                          backgroundImage: "unset !important",
                        }}
                      />
                    </Col>
                  </Row>

                  {role === "Admin" && <Row className="text-input-container">
                    <Col xs={12} sm={5}>
                      <p>Time Until Logout:</p>
                    </Col>
                    <Col xs={12} sm={7}>
                      <FormControl
                        className="text-start text-sm-end disabled"
                        type="text"
                        name="timeUntilLogout"
                        value={`${moment(trialExpiry)?.format(
                          "DD MMM YYYY"
                        )}`}
                        disabled
                        style={{
                          backgroundImage: "unset !important",
                        }}
                      />
                    </Col>
                  </Row>}
                  {/* {role !== "Admin" && role !== "Super Admin" && (
                  <>
                    <Row className="text-input-container gap-1">
                      <Col xs={12}>
                        <p>Permissions:</p>
                      </Col>
                      <Col xs={12}>
                        <AsyncPaginate
                          value={permissions ? permissions : ""}
                          loadOptions={fetchPermisions}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option._id}
                          onChange={handlePermissionsChange}
                          placeholder="Select Permissions"
                          isClearable
                          loadingMessage={() => "Loading..."}
                          noOptionsMessage={() => "No Permissions Found"}
                          additional={{
                            page: 1,
                          }}
                          isMulti
                          isSearchable
                          styles={{
                            menu: (base) => ({
                              ...base,
                              border: 0,
                              backgroundColor: "rgb(164, 207, 255)",
                              // open upwards
                              top: "auto",
                              // open upwards
                              bottom: "100%",
                              margin: 0,
                            }),
                            option: (
                              styles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => {
                              return {
                                ...styles,
                                backgroundColor: isSelected
                                  ? "rgba(16, 14, 52, 1)"
                                  : isFocused
                                  ? "rgb(164, 207, 255)"
                                  : null,
                                color: isSelected ? "white" : "black",
                              };
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="text-input-container">
                      <Col xs={12}>
                        <p>Trial Period:</p>
                      </Col>
                      <Col xs={12}>
                        <Select
                          options={trialOptions}
                          value={trial}
                          onChange={handleTrialChange}
                          placeholder="Select Trial Period"
                          styles={{
                            menu: (base) => ({
                              ...base,
                              border: 0,
                              backgroundColor: "rgb(164, 207, 255)",
                              // open upwards
                              top: "auto",
                              // open upwards
                              bottom: "100%",
                              margin: 0,
                            }),
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )} */}
                  <Row className="text-input-container border-0">
                    <Col xs={12} className="d-flex justify-content-center ">
                      <Button
                        onClick={handleSaveChanges}
                        variant="primary"
                        className="btn-default w-100"
                        style={{
                          height: "40px",
                          fontSize: "20px",
                        }}
                        disabled={loading}
                      >
                        {loading ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          "UPDATE USER"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ProfileSettings;
