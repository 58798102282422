import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SingleButton from "./SingleButton";
import { toast } from "react-toastify";
import { getItemFromLocalStorage } from "../helper/helper";
import { postRequestForm } from "../helper/api";

const SelectBowlerModal = ({
  handleClose,
  matchDetails,
  setMatchDetails,
  setBallsArray,
}) => {
  const [tableData, setTableData] = useState([]);
  const [selectedBowler, setSelectedBowler] = useState(null);

  useEffect(() => {
    if (matchDetails) {
      const bowlers = matchDetails.match.teams[1]?.selectedPlayers
        .filter((player) => player.ballsDelivered > 0) // ✅ Only include bowlers who have bowled
        .map((player) => ({
          ...player,
          runsGiven: player.runsGiven || 0,
          ballsDelivered: player.ballsDelivered || 0,
          wicketsTaken: player.wicketsTaken || 0,
        }));

      setTableData(bowlers);
    }

  }, [matchDetails]);

  const handleSelectBowler = () => {
    if (!selectedBowler) return;

    // ✅ Ensure the selected bowler is properly assigned
    setMatchDetails((prev) => {
      let updatedMatch = { ...prev };
      const selectedBowlerObj =
        updatedMatch.match.teams[1].selectedPlayers.find(
          (player) => player.player?._id === selectedBowler
        );

      if (selectedBowlerObj) {
        updatedMatch.match.bowler = { ...selectedBowlerObj }; // ✅ Ensure full object is stored
      }

      console.log("🚀 Updated Match Details with Bowler:", updatedMatch);
      return updatedMatch;
    });

    sendMatchDetailsToAPI(matchDetails.match._id, matchDetails);
    handleClose();
  };

  const sendMatchDetailsToAPI = async (matchId, matchDetails) => {
    try {
      saveMatchDetailsToLocalStorage(matchId, matchDetails);

      const payload = structuredClone(matchDetails.match);

      // ✅ Ensure `player` field is mapped correctly
      if (payload.facingBatsman?.player) {
        payload.facingBatsman.player = payload.facingBatsman.player._id;
      }
      if (payload.nonStriker?.player) {
        payload.nonStriker.player = payload.nonStriker.player._id;
      }
      if (payload.bowler?.player) {
        payload.bowler.player = payload.bowler.player._id;
      }

      const removeIds = (obj) => {
        if (!obj) return null;
        const { _id, id, ...cleanedObj } = obj;
        return cleanedObj;
      };

      payload.facingBatsman = removeIds(payload.facingBatsman);
      payload.nonStriker = removeIds(payload.nonStriker);
      payload.bowler = removeIds(payload.bowler);

      // ✅ Correct `teams` structure
      payload.teams = payload.teams.map(
        ({ _id, id, teamId, selectedPlayers, substitutePlayers, ...rest }) => ({
          ...rest,
          teamId: teamId?._id,
          selectedPlayers: selectedPlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
          substitutePlayers: substitutePlayers.map(
            ({ player, _id, id, ...restPlayer }) => ({
              player: player?._id,
              ...restPlayer,
            })
          ),
        })
      );

      payload.matchId = payload._id;

      // ✅ Remove unnecessary fields
      const fieldsToRemove = [
        "_id",
        "id",
        "owner",
        "tournamentId",
        "totalOvers",
        "matchType",
        "overs",
        "updatedAt",
        "createdAt",
        "__v",
        "matchNumber",
        "matchName",
        "winner",
        "manOfTheMatch",
        "isFirstInningCompleted",
      ];
      fieldsToRemove.forEach((field) => delete payload[field]);

      console.log("✅ Cleaned Payload:", JSON.stringify(payload, null, 2));

      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/v2/match/match-details-sync`,
        token,
        payload
      );

      if (result?.status === 200) {
        console.log("✅ Match data sent successfully!");
        toast.success("Match Data sent successfully!", {
          position: "top-center",
          theme: "colored",
          autoClose: 6000,
        });
      }
    } catch (error) {
      console.error("❌ Error sending ball data:", error);
    }finally{
      setBallsArray([]);
    }
  };

  const saveMatchDetailsToLocalStorage = (matchId, matchDetails) => {
    localStorage.setItem(
      `matchDetails_${matchId}`,
      JSON.stringify(matchDetails)
    );
  };

  const tableHead = [
    {
      name: "Bowler",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <input
            type="radio"
            name="Bowler"
            checked={selectedBowler === row.player?._id}
            onChange={() => setSelectedBowler(row.player?._id)}
            style={{
              appearance: "none",
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              border: `2px solid ${
                selectedBowler === row.player?._id
                  ? "rgba(231, 50, 147, 1)"
                  : "gray"
              }`,
              backgroundColor:
                selectedBowler === row.player?._id
                  ? "rgba(231, 50, 147, 1)"
                  : "white",
              cursor: "pointer",
              marginRight: "10px",
            }}
          />
          <p className="m-0 text-start">{row.player?.playerName}</p>
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Runs Given",
      selector: (row) => row.runsGiven,
      width: "100px",
      sortable: true,
      center: true,
    },
    {
      name: "Balls Delivered",
      selector: (row) => row.ballsDelivered,
      width: "120px",
      sortable: true,
      center: true,
    },
    {
      name: "Wickets Taken",
      selector: (row) => row.wicketsTaken,
      width: "120px",
      sortable: true,
      center: true,
    },
  ];

  return (
    <section
      id="selectBowlerModal"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>
        <h1 className="match-scoring-modal-heading">SELECT A BOWLER</h1>
        <div className="d-flex justify-content-end text-light pe-5 mb-3">
          <Form.Select
            aria-label="Select Bowler"
            onChange={(e) => setSelectedBowler(e.target.value)}
            value={selectedBowler || ""}
            style={{ width: "200px" }}
          >
            <option value="">Select Bowler</option>
            {matchDetails.match.teams[1]?.selectedPlayers.map((player) => (
              <option key={player.player?._id} value={player.player?._id}>
                {player.player?.playerName}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <DataTable
            columns={tableHead}
            data={tableData}
            highlightOnHover
            responsive
            pagination
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            noDataComponent={<p>No bowlers found</p>}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "white",
                  fontWeight: "bold",
                },
              },
              headCells: {
                style: {
                  color: "black",
                  textAlign: "center",
                  fontSize: "14px",
                  padding: "10px",
                },
              },
              rows: {
                style: {
                  backgroundColor: "transparent",
                },
              },
              cells: {
                style: {
                  border: "1px solid white",
                  color: "white",
                  textAlign: "center",
                  fontSize: "14px",
                  padding: "10px",
                },
              },
            }}
          />
        </div>
        <div className="d-flex flex-column align-items-center">
          <SingleButton
            content={"Start New Over"}
            onClick={handleSelectBowler}
            backgroundColor={"rgba(231, 50, 147, 1)"}
            color={"#fff"}
            buttonStyles={{
              width: "200px",
              marginTop: "10px",
            }}
          />
        </div>
      </Container>
    </section>
  );
};

export default SelectBowlerModal;
