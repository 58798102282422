import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Images } from "../Images";

const CANVAS_SIZE = 200;
const DISPLAY_SIZE = 350;
const SCALE_FACTOR = DISPLAY_SIZE / CANVAS_SIZE;

const scoreColors = {
  0: "white",
  1: "blue",
  2: "green",
  3: "yellow",
  4: "orange",
  5: "red",
  6: "red",
};

const BallLine = ({ handleClose }) => {
  const canvasRef = useRef(null);
  const [selectedScore, setSelectedScore] = useState(null);
  const [showPercentages, setShowPercentages] = useState(true);

  const data = [
    { x: 100.354, y: 162.517, score: 2 },
    { x: 14.909, y: 18.399, score: 4 },
    { x: 11.909, y: 32.183, score: 4 },
    { x: 79.229, y: 16.545, score: 2 },
  ];

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;
    const img = new Image();
    img.src = Images.ballLine;

    img.onload = () => {
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);

      const wideOutsideOffEnd = width * 0.35;
      const outsideOffEnd = wideOutsideOffEnd + width * 0.1;
      const stumpsEnd = outsideOffEnd + width * 0.1;
      const legSideEnd = stumpsEnd + width * 0.45;

      const sections = [
        { label: "Wide Outside Off", start: 0, end: wideOutsideOffEnd },
        { label: "Outside Off", start: wideOutsideOffEnd, end: outsideOffEnd },
        { label: "Stumps", start: outsideOffEnd, end: stumpsEnd },
        { label: "Leg Side", start: stumpsEnd, end: legSideEnd },
      ];

      const filteredData =
        selectedScore !== null
          ? data.filter(({ score }) => score === selectedScore)
          : data;

      const sectionCounts = Array(sections.length).fill(0);
      filteredData.forEach(({ x }) => {
        const scaledX = x * SCALE_FACTOR;
        for (let i = 0; i < sections.length; i++) {
          if (scaledX <= sections[i].end) {
            sectionCounts[i]++;
            break;
          }
        }
      });

      const totalShots = filteredData.length || 1;
      const sectionPercentages = sectionCounts.map((count) =>
        ((count / totalShots) * 100).toFixed(2)
      );

      if (showPercentages) {
        let prevX = 0;
        sections.forEach(({ label, end }, i) => {
          ctx.fillStyle = `rgba(255, 255, 255, 0.5)`;
          ctx.fillRect(prevX, 0, end - prevX, height);
          ctx.strokeStyle = "black";
          ctx.strokeRect(prevX, 0, end - prevX, height);

          ctx.fillStyle = "black";
          ctx.font = "14px Arial";
          ctx.textAlign = "center";
          ctx.fillText(label, (prevX + end) / 2, height - 20);
          ctx.fillText(`${sectionPercentages[i]}%`, (prevX + end) / 2, 20);

          prevX = end;
        });
      }

      filteredData.forEach(({ x, y, score }) => {
        ctx.beginPath();
        ctx.arc(x * SCALE_FACTOR, y * SCALE_FACTOR, 5, 0, 2 * Math.PI);
        ctx.fillStyle = scoreColors[score];
        ctx.fill();
        ctx.stroke();
      });
    };
  }, [selectedScore, showPercentages]);

  return (
    <section
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
        padding: "60px",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>

        {/* Heading */}
        <h2 className="text-center text-light pb-4">
          Ball Line
        </h2>

        <div className="d-flex">
          <canvas
            ref={canvasRef}
            width={DISPLAY_SIZE}
            height={DISPLAY_SIZE}
            style={{ border: "1px solid black" }}
          />

          <div className="d-flex gap-3 flex-column ms-5">
            {Object.entries(scoreColors).map(([score, color]) => (
              <label
                key={score}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <input
                  type="radio"
                  name="score"
                  value={score}
                  checked={parseInt(score) === selectedScore}
                  onChange={() => setSelectedScore(parseInt(score))}
                  style={{
                    appearance: "none",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: color,
                    border:
                      selectedScore == score
                        ? "3px solid black"
                        : "2px solid transparent",
                  }}
                />
                <span className="text-light">{score}</span>
              </label>
            ))}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input
                type="radio"
                name="score"
                value={"null"}
                checked={selectedScore === null}
                onChange={() => setSelectedScore(null)}
                style={{
                  appearance: "none",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  border:
                    selectedScore == null
                      ? "3px solid black"
                      : "2px solid transparent",
                }}
              />
              <span className="text-light">All</span>
            </label>

            {/* Switch for Showing Percentages */}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                checked={showPercentages}
                onChange={() => setShowPercentages((prev) => !prev)}
                style={{
                  appearance: "none",
                  width: "40px",
                  height: "20px",
                  borderRadius: "10px",
                  backgroundColor: showPercentages ? "green" : "gray",
                  position: "relative",
                  cursor: "pointer",
                }}
              />
              <span className="text-light">
                {showPercentages ? "Show Percentages" : "Only Balls"}
              </span>
            </label>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BallLine;
