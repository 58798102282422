// import React, { useState, useRef, useEffect } from "react";
// import { Container, Form } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClose } from "@fortawesome/free-solid-svg-icons";
// import { Images } from "../Images";

// // Color mapping for different scores
// const scoreColors = {
//   0: "white",
//   1: "blue",
//   2: "green",
//   3: "yellow",
//   4: "orange",
//   5: "red",
//   6: "red",
// };

// // Example ball coordinates (normalized)
// const exampleBalls = [
//   { x: 0.5, y: 0.1, runs: 0 },
//   { x: 0.6, y: 0.2, runs: 1 },
//   { x: 0.7, y: 0.3, runs: 2 },
//   { x: 0.4, y: 0.4, runs: 3 },
//   { x: 0.55, y: 0.5, runs: 4 },
//   { x: 0.65, y: 0.6, runs: 5 },
//   { x: 0.35, y: 0.7, runs: 6 },
// ];

// const WagonWheel = ({ handleClose }) => {
//   const [selectedPlayer, setSelectedPlayer] = useState("Babar Azam");
//   const [selectedScore, setSelectedScore] = useState(null);
//   const graphRef = useRef(null);
//   const [graphSize, setGraphSize] = useState({ width: 300, height: 300 });

//   // Detect graph size on mount & resize
//   useEffect(() => {
//     const updateGraphSize = () => {
//       if (graphRef.current) {
//         const { width, height } = graphRef.current.getBoundingClientRect();
//         setGraphSize({ width, height });
//       }
//     };
//     updateGraphSize();
//     window.addEventListener("resize", updateGraphSize);
//     return () => window.removeEventListener("resize", updateGraphSize);
//   }, []);

//   return (
//     <section
//       id="wagonWheel"
//       style={{
//         background:
//           "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
//         height: "80vh",
//         paddingRight: "60px",
//         paddingLeft: "60px",
//       }}
//     >
//       <Container>
//         {/* Close Button */}
//         <div className="d-flex justify-content-end pt-5">
//           <FontAwesomeIcon
//             icon={faClose}
//             color="#fff"
//             size="2x"
//             onClick={handleClose}
//             style={{ cursor: "pointer" }}
//           />
//         </div>

//         {/* Heading */}
//         <h1 className="match-scoring-modal-heading text-light">
//           Team Wagon Wheels
//         </h1>

//         {/* Player Selection */}
//         <div className="d-flex align-items-center justify-content-between text-light">
//           <p>{selectedPlayer}:</p>
//           <Form.Select
//             aria-label="Select Player"
//             onChange={(e) => setSelectedPlayer(e.target.value)}
//             value={selectedPlayer}
//             style={{ width: "200px" }}
//           >
//             <option value="Babar Azam">Babar Azam</option>
//             <option value="Fakhar Zaman">Fakhar Zaman</option>
//             <option value="Mohammad Rizwan">Mohammad Rizwan</option>
//           </Form.Select>
//         </div>

//         <div className="d-flex">
//           {/* Graph */}
//           <div ref={graphRef} style={graphStyle}>
//             <svg
//               width="100%"
//               height="100%"
//               style={{ position: "absolute", top: 0, left: 0 }}
//             >
//               {exampleBalls
//                 .filter(
//                   (ball) =>
//                     selectedScore === null || ball.runs === selectedScore
//                 )
//                 .map((ball, index) => (
//                   <line
//                     key={index}
//                     x1="50%"
//                     y1="46%" // Center point
//                     x2={`${ball.x * 100}%`}
//                     y2={`${ball.y * 100}%`}
//                     stroke={scoreColors[ball.runs]}
//                     strokeWidth="2"
//                   />
//                 ))}
//             </svg>
//             {exampleBalls
//               .filter(
//                 (ball) => selectedScore === null || ball.runs === selectedScore
//               )
//               .map((ball, index) => (
//                 <div
//                   key={index}
//                   style={{
//                     position: "absolute",
//                     width: "10px",
//                     height: "10px",
//                     backgroundColor: scoreColors[ball.runs],
//                     borderRadius: "50%",
//                     left: `${ball.x * graphSize.width - 5}px`,
//                     top: `${ball.y * graphSize.height - 5}px`,
//                     pointerEvents: "none",
//                   }}
//                 />
//               ))}
//           </div>

//           {/* Score Selection */}
//           <div className="d-flex gap-3 flex-column ms-5">
//             {Object.entries(scoreColors).map(([score, color]) => (
//               <label
//                 key={score}
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "10px",
//                   cursor: "pointer",
//                 }}
//               >
//                 <input
//                   type="radio"
//                   name="score"
//                   value={score}
//                   checked={parseInt(score) === selectedScore}
//                   onChange={() => setSelectedScore(parseInt(score))}
//                   style={{
//                     appearance: "none",
//                     width: "20px",
//                     height: "20px",
//                     borderRadius: "50%",
//                     backgroundColor: color,
//                     border:
//                       selectedScore == score
//                         ? "3px solid black"
//                         : "2px solid transparent",
//                   }}
//                 />
//                 <span className="text-light">{score}</span>
//               </label>
//             ))}
//             <label
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "10px",
//                 cursor: "pointer",
//               }}
//             >
//               <input
//                 type="radio"
//                 name="score"
//                 value={"null"}
//                 checked={selectedScore === null}
//                 onChange={() => setSelectedScore(null)}
//                 style={{
//                   appearance: "none",
//                   width: "20px",
//                   height: "20px",
//                   borderRadius: "50%",
//                   backgroundColor: "white",
//                   border:
//                     selectedScore == null
//                       ? "3px solid black"
//                       : "2px solid transparent",
//                 }}
//               />
//               <span className="text-light">All</span>
//             </label>
//           </div>
//         </div>
//       </Container>
//     </section>
//   );
// };

// // Styles for the Wagon Wheel Graph
// const graphStyle = {
//   width: "31vh", // Responsive height-based size
//   height: "31vh",
//   minWidth: "350px",
//   minHeight: "350px",
//   maxWidth: "90vw",
//   maxHeight: "90vh",
//   position: "relative",
//   backgroundImage: `url(${Images.wagonWheel})`,
//   backgroundSize: "cover",
//   border: "1px solid white",
// };

// export default WagonWheel;

// import React, { useEffect, useRef, useState } from "react";
// import { Images } from "../Images";
// const WagonWheel = () => {
//   const canvasRef = useRef(null);
//   const [points, setPoints] = useState([]); // Store all points

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");
//     const width = canvas.width;
//     const height = canvas.height;
//     const centerX = width / 2;
//     const centerY = height / 2;
//     const radius = width / 2 - 10;

//     // Load background image
//     const img = new Image();
//     img.src = Images.wagonWheel; // Replace with your image URL
//     img.onload = () => {
//       ctx.drawImage(img, 0, 0, width, height);

//       // Draw circular quadrant
//       ctx.beginPath();
//       ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
//       ctx.strokeStyle = "black";
//       ctx.lineWidth = 2;
//       ctx.stroke();

//       // Draw 45-degree lines
//       for (let angle = 0; angle < 360; angle += 45) {
//         const radian = (angle * Math.PI) / 180;
//         const x = centerX + radius * Math.cos(radian);
//         const y = centerY - radius * Math.sin(radian);
//         ctx.beginPath();
//         ctx.moveTo(centerX, centerY);
//         ctx.lineTo(x, y);
//         ctx.strokeStyle = "gray";
//         ctx.lineWidth = 1;
//         ctx.stroke();

//         // Label angles
//         ctx.fillStyle = "black";
//         ctx.font = "12px Arial";
//         ctx.fillText(`${angle}°`, x, y);
//       }

//       // Draw saved points
//       points.forEach(({ x, y }) => {
//         ctx.beginPath();
//         ctx.arc(centerX + x, centerY - y, 4, 0, 2 * Math.PI);
//         ctx.fillStyle = "red";
//         ctx.fill();
//       });
//     };
//   }, [points]);

//   // Function to capture click events
//   const handleCanvasClick = (event) => {
//     const canvas = canvasRef.current;
//     const rect = canvas.getBoundingClientRect();
//     const mouseX = event.clientX - rect.left;
//     const mouseY = event.clientY - rect.top;

//     const width = canvas.width;
//     const height = canvas.height;
//     const centerX = width / 2;
//     const centerY = height / 2;

//     // Convert to Cartesian coordinates
//     const x = mouseX - centerX;
//     const y = -(mouseY - centerY); // Flip y to match Cartesian system

//     // Calculate the angle in degrees
//     let angle = Math.atan2(y, x) * (180 / Math.PI);
//     if (angle < 0) angle += 360; // Convert negative angles

//     // Determine the quadrant and 45-degree section
//     const quadrant = getQuadrant(angle);
//     const angleSection = `${Math.floor(angle / 45) * 45}° - ${
//       Math.floor(angle / 45) * 45 + 45
//     }°`;

//     console.log(
//       `Clicked at (${x}, ${y}) in ${quadrant}, Angle: ${angleSection}`
//     );

//     // Store the point with quadrant and angle section
//     setPoints([...points, { x, y, quadrant, angleSection }]);
//     console.log("points", points);

//   };

//   // Function to determine quadrant
//   const getQuadrant = (angle) => {
//     if (angle >= 0 && angle < 90) return "Quadrant I";
//     if (angle >= 90 && angle < 180) return "Quadrant II";
//     if (angle >= 180 && angle < 270) return "Quadrant III";
//     if (angle >= 270 && angle < 360) return "Quadrant IV";
//   };

//   return (
//     <div style={{ textAlign: "center", background:"#fff" }}>
//       <h2>Wagon Wheel Quadrant</h2>
//       <canvas
//         ref={canvasRef}
//         width={500}
//         height={500}
//         style={{
//           border: "1px solid black",
//           cursor: "pointer",
//           borderRadius: "50%",
//         }}
//         onClick={handleCanvasClick}
//       />
//       <div>
//         <h3>Captured Points:</h3>
//         {points.map((point, index) => (
//           <p
//             key={index}
//           >{`(${point.x}, ${point.y}) - ${point.quadrant}, ${point.angleSection}`}</p>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default WagonWheel;

// import React, { useEffect, useRef } from "react";

// const WagonWheel = () => {
//   const canvasRef = useRef(null);

//   const data = [
//     { x: 164.67, y: 78.25, quadrant: "Quadrant I", angleSection: "0° - 45°" },
//     { x: 164.67, y: 78.25, quadrant: "Quadrant I", angleSection: "0° - 45°" },
//     { x: 164.67, y: 78.25, quadrant: "Quadrant I", angleSection: "0° - 45°" },
//     { x: 51.67, y: 149.25, quadrant: "Quadrant I", angleSection: "45° - 90°" },
//     { x: 51.67, y: 149.25, quadrant: "Quadrant I", angleSection: "45° - 90°" },
//     { x: -73, y: 135.47, quadrant: "Quadrant II", angleSection: "90° - 135°" },
//     { x: -73, y: 135.47, quadrant: "Quadrant II", angleSection: "90° - 135°" },
//     { x: -73, y: 135.47, quadrant: "Quadrant II", angleSection: "90° - 135°" },
//     { x: -73, y: 135.47, quadrant: "Quadrant II", angleSection: "90° - 135°" },
//     { x: -142, y: 69.47, quadrant: "Quadrant II", angleSection: "135° - 180°" },
//     {
//       x: -122,
//       y: -56.53,
//       quadrant: "Quadrant III",
//       angleSection: "180° - 225°",
//     },
//     {
//       x: -38,
//       y: -112.53,
//       quadrant: "Quadrant III",
//       angleSection: "225° - 270°",
//     },
//     {
//       x: 66.99,
//       y: -99.53,
//       quadrant: "Quadrant IV",
//       angleSection: "270° - 315°",
//     },
//   ];

//   useEffect(() => {
//     if (!canvasRef.current) return;
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");
//     if (!ctx) return;

//     const width = canvas.width;
//     const height = canvas.height;
//     const centerX = width / 2;
//     const centerY = height / 2;
//     const radius = width / 2 - 10;

//     // Clear canvas
//     ctx.clearRect(0, 0, width, height);

//     // Draw Cartesian Axes
//     ctx.beginPath();
//     ctx.moveTo(centerX, 0);
//     ctx.lineTo(centerX, height);
//     ctx.moveTo(0, centerY);
//     ctx.lineTo(width, centerY);
//     ctx.strokeStyle = "black";
//     ctx.lineWidth = 2;
//     ctx.stroke();

//     // Define angle sections
//     const angleSections = [
//       "0° - 45°",
//       "45° - 90°",
//       "90° - 135°",
//       "135° - 180°",
//       "180° - 225°",
//       "225° - 270°",
//       "270° - 315°",
//       "315° - 360°",
//     ];

//     // Count occurrences
//     const angleCounts = angleSections.reduce((acc, angle) => {
//       acc[angle] = 0;
//       return acc;
//     }, {});

//     data.forEach(({ angleSection }) => {
//       if (angleCounts[angleSection] !== undefined) {
//         angleCounts[angleSection]++;
//       }
//     });

//     const totalPoints = data.length;
//     const anglePercentages = Object.fromEntries(
//       Object.entries(angleCounts).map(([angle, count]) => [
//         angle,
//         ((count / totalPoints) * 100).toFixed(2),
//       ])
//     );

//     // Color function
//     const getColor = (percentage, min, max) => {
//       if (max === min) return "green"; // If all values are the same, default to green

//       // Normalize percentage between 0 and 1
//       const normalized = (percentage - min) / (max - min);

//       // Define color stops in HSL
//       const colors = [
//         { stop: 0, h: 0, s: 100, l: 50 }, // Red
//         { stop: 0.33, h: 30, s: 100, l: 50 }, // Orange
//         { stop: 0.66, h: 60, s: 100, l: 50 }, // Yellow
//         { stop: 1, h: 120, s: 100, l: 40 }, // Green
//       ];

//       // Find two nearest color stops
//       let start = colors[0];
//       let end = colors[colors.length - 1];
//       for (let i = 0; i < colors.length - 1; i++) {
//         if (normalized >= colors[i].stop && normalized <= colors[i + 1].stop) {
//           start = colors[i];
//           end = colors[i + 1];
//           break;
//         }
//       }

//       // Interpolation factor
//       const factor = (normalized - start.stop) / (end.stop - start.stop);

//       // Interpolate HSL values
//       const h = start.h + factor * (end.h - start.h);
//       const s = start.s + factor * (end.s - start.s);
//       const l = start.l + factor * (end.l - start.l);

//       return `hsl(${h}, ${s}%, ${l}%)`;
//     };

//     // const getColor = (percentage = 0) =>
//     //   percentage >= 75 ? "green" :
//     //   percentage >= 50 ? "yellow" :
//     //   percentage >= 25 ? "orange" : "red";

//     // Draw the 45° sectors
//     for (let i = 0; i < 8; i++) {
//       const startAngle = ((7 - i) * 45 * Math.PI) / 180;
//       const endAngle = ((8 - i) * 45 * Math.PI) / 180;

//       const angleLabel = angleSections[i];
//       const percentage = parseFloat(anglePercentages[angleLabel]) || 0;

//       ctx.beginPath();
//       ctx.moveTo(centerX, centerY);
//       ctx.arc(centerX, centerY, radius, startAngle, endAngle, false);
//       ctx.closePath();
//       const minPercentage = Math.min(
//         ...Object.values(anglePercentages).map(Number)
//       );
//       const maxPercentage = Math.max(
//         ...Object.values(anglePercentages).map(Number)
//       );

//       ctx.fillStyle = getColor(percentage, minPercentage, maxPercentage);

//       ctx.fill();
//       ctx.strokeStyle = "black";
//       ctx.stroke();

//       // Draw labels
//       const midAngle = (startAngle + endAngle) / 2;
//       const textX = centerX + radius * 0.6 * Math.cos(midAngle);
//       const textY = centerY + radius * 0.6 * Math.sin(midAngle);

//       ctx.fillStyle = "black";
//       ctx.font = "14px Arial";
//       ctx.textAlign = "center";
//       ctx.textBaseline = "middle";
//       ctx.fillText(`${percentage}%`, textX, textY);
//     }

//     // Draw Outer Circle
//     ctx.beginPath();
//     ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
//     ctx.strokeStyle = "black";
//     ctx.lineWidth = 2;
//     ctx.stroke();
//   }, []);

//   return (
//     <div style={{ textAlign: "center", background: "#fff" }}>
//       <h2>Wagon Wheel - Quadrants & Angles</h2>
//       <canvas
//         ref={canvasRef}
//         width={500}
//         height={500}
//         style={{ border: "1px solid black", borderRadius: "50%" }}
//       />
//     </div>
//   );
// };

// export default WagonWheel;

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Images } from "../Images";

const CANVAS_SIZE = 200;
const DISPLAY_SIZE = 350;
const SCALE_FACTOR = DISPLAY_SIZE / CANVAS_SIZE;

const scoreColors = {
  0: "white",
  1: "blue",
  2: "green",
  3: "yellow",
  4: "orange",
  5: "red",
  6: "red",
};

const WagonWheel = ({ handleClose }) => {
  const canvasRef = useRef(null);
  const [selectedPlayer, setSelectedPlayer] = useState("Babar Azam");
  const [selectedScore, setSelectedScore] = useState(null);
  const [showPercentages, setShowPercentages] = useState(true);

  const data = [
    { x: 18.9, y: 46.3 },
    { x: -26.1, y: -52.6 },
    { x: 51.9, y: -11.6 },
    { x: -52.0, y: 27.3 },
  ];

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = width / 2 - 10;

    const img = new Image();
    img.src = Images.wagonWheel;
    img.onload = () => {
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);

      ctx.beginPath();
      ctx.moveTo(centerX, 0);
      ctx.lineTo(centerX, height);
      ctx.moveTo(0, centerY);
      ctx.lineTo(width, centerY);
      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;
      ctx.stroke();

      const sectionCounts = Array(8).fill(0);

      data.forEach(({ x, y }) => {
        let angle = Math.atan2(-y, x) * (180 / Math.PI);
        if (angle < 0) angle += 360;
        const adjustedAngle = (angle + 90) % 360;
        const sectionIndex = Math.floor(adjustedAngle / 45);
        sectionCounts[sectionIndex]++;
      });

      const totalShots = data.length;
      const sectionPercentages = sectionCounts.map((count) =>
        ((count / totalShots) * 100).toFixed(2)
      );

      if (showPercentages) {
        for (let i = 0; i < 8; i++) {
          const startAngle = ((i * 45 - 90) * Math.PI) / 180;
          const endAngle = (((i + 1) * 45 - 90) * Math.PI) / 180;
          const percentage = sectionPercentages[i];

          ctx.beginPath();
          ctx.moveTo(centerX, centerY);
          ctx.arc(centerX, centerY, radius, startAngle, endAngle, false);
          ctx.closePath();
          ctx.fillStyle = `rgba(255, 255, 255, 0.5)`;
          ctx.fill();
          ctx.strokeStyle = "black";
          ctx.stroke();

          const midAngle = (startAngle + endAngle) / 2;
          const textX = centerX + radius * 0.6 * Math.cos(midAngle);
          const textY = centerY + radius * 0.6 * Math.sin(midAngle);

          ctx.fillStyle = "black";
          ctx.font = "14px Arial";
          ctx.textAlign = "center";
          ctx.fillText(`${percentage}%`, textX, textY);
        }
      }

      data.forEach(({ x, y }) => {
        const scaledX = x * SCALE_FACTOR;
        const scaledY = y * SCALE_FACTOR;
        ctx.beginPath();
        ctx.arc(centerX + scaledX, centerY - scaledY, 5, 0, 2 * Math.PI);
        ctx.fillStyle = "red";
        ctx.fill();
        ctx.stroke();
      });
    };
  }, [showPercentages]);

  return (
    <section
      id="wagonWheel"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
        paddingRight: "60px",
        paddingLeft: "60px",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>

        <h1 className="match-scoring-modal-heading text-light">
          Team Wagon Wheels
        </h1>

        <div className="d-flex align-items-center justify-content-between text-light">
          <p>{selectedPlayer}:</p>
          <Form.Select
            aria-label="Select Player"
            onChange={(e) => setSelectedPlayer(e.target.value)}
            value={selectedPlayer}
            style={{ width: "200px" }}
          >
            <option value="Babar Azam">Babar Azam</option>
            <option value="Fakhar Zaman">Fakhar Zaman</option>
            <option value="Mohammad Rizwan">Mohammad Rizwan</option>
          </Form.Select>
        </div>

        <div className="d-flex">
          <canvas
            ref={canvasRef}
            width={DISPLAY_SIZE}
            height={DISPLAY_SIZE}
            style={{ border: "1px solid black" }}
          />

          <div className="d-flex gap-3 flex-column ms-5">
            {Object.entries(scoreColors).map(([score, color]) => (
              <label
                key={score}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <input
                  type="radio"
                  name="score"
                  value={score}
                  checked={parseInt(score) === selectedScore}
                  onChange={() => setSelectedScore(parseInt(score))}
                  style={{
                    appearance: "none",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: color,
                    border:
                      selectedScore == score
                        ? "3px solid black"
                        : "2px solid transparent",
                  }}
                />
                <span className="text-light">{score}</span>
              </label>
            ))}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input
                type="radio"
                name="score"
                value={"null"}
                checked={selectedScore === null}
                onChange={() => setSelectedScore(null)}
                style={{
                  appearance: "none",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  border:
                    selectedScore == null
                      ? "3px solid black"
                      : "2px solid transparent",
                }}
              />
              <span className="text-light">All</span>
            </label>

            {/* Toggle Switch */}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                checked={showPercentages}
                onChange={() => setShowPercentages((prev) => !prev)}
                style={{
                  appearance: "none",
                  width: "40px",
                  height: "20px",
                  borderRadius: "10px",
                  backgroundColor: showPercentages ? "green" : "gray",
                  cursor: "pointer",
                }}
              />
              <span className="text-light">
                {showPercentages ? "Show Percentages" : "Only Balls"}
              </span>
            </label>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WagonWheel;

