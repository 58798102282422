import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const [selectedOption, setSelectedOption] = useState("Pakistan");

  // Sample runs per over for both teams
  const teamData = {
    Pakistan: [
      2, 8, 5, 7, 8, 15, 5, 10, 12, 13, 10, 9, 15, 5, 10, 8, 10, 10, 10, 10,
    ],
    India: [
      5, 7, 6, 7, 10, 7, 13, 10, 12, 15, 10, 8, 5, 15, 10, 5, 15, 8, 12, 10,
    ],
  };

  // Chart Data Configuration
  const chartData = {
    labels: Array.from({ length: 20 }, (_, i) => i + 1),
    datasets: [
      selectedOption !== "India" && {
        label: "Pakistan Runs Per Over",
        data: teamData.Pakistan,
        backgroundColor: "rgba(0, 128, 0, 0.6)", // Green
        borderColor: "green",
        borderWidth: 1,
      },
      selectedOption !== "Pakistan" && {
        label: "India Runs Per Over",
        data: teamData.India,
        backgroundColor: "rgba(0, 0, 255, 0.6)", // Blue
        borderColor: "blue",
        borderWidth: 1,
      },
    ].filter(Boolean), // Remove null datasets if only one team is selected
  };

  // Chart options
  const options = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: "Overs" } },
      y: { title: { display: true, text: "Runs per Over" } },
    },
  };

  return (
    <div className="bar-chart px-5 py-2">
      {/* Team Selection */}
      <div className="team-selector">
        {["Pakistan", "India", "Both"].map((option) => (
          <label key={option}>
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={() => setSelectedOption(option)}
            />
            {option}
          </label>
        ))}
      </div>
      {/* Bar Chart */}
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
