import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import Layout from "../../layouts/Layout";
import BackButton from "../../constants/BackButton";
import { Images } from "../../constants/Images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import PairButtons from "../../constants/PairButtons";
import TeamsModal from "../../constants/TeamsModal";
import { deleteRequest, getRequest, postRequestForm } from "../../helper/api";
import { toast } from "react-toastify";
import { getItemFromLocalStorage } from "../../helper/helper";
import TableSkeleton from "../../constants/Skeleton Loaders/TableSkeleton";
import { AsyncPaginate } from "react-select-async-paginate";

const StartMatch = () => {
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [tournamentLoading, setTournamentLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [show, setShow] = useState("screen1");

  const [searching, setSearching] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [teamA, setTeamA] = useState();
  const [teamAPlaying11, setTeamAPlaying11] = useState();

  const [teamB, setTeamB] = useState();
  const [teamBPlaying11, setTeamBPlaying11] = useState();

  const [tournamentId, setTournamentId] = useState();
  const [tournament, setTournament] = useState();
  const [matchNumber, setMatchNumber] = useState("");
  const [matchType, setMatchType] = useState(null);
  const [tossWinner, setTossWinner] = useState();
  const [electedTo, setElectedTo] = useState();
  const [overs, setOvers] = useState();

  const [winnerAlphabet, setWinnerAlphabet] = useState("");

  const [facingBatsman, setFacingBatsman] = useState();
  const [nonStriker, setNonStriker] = useState();
  const [teamAPlayedAs, setTeamAPlayedAs] = useState("");
  const [teamBPlayedAs, setTeamBPlayedAs] = useState("");

  const [bowler, setBowler] = useState();

  const matchTypeOptions = [
    { value: "", label: "Select Match Type" },
    { value: "Test Match", label: "Test Match" },
    { value: "Overs Match", label: "Overs Match" },
  ];

  const tossOptions = [
    { value: "", label: "Elected To" },
    { value: "Batting", label: "Batting" },
    { value: "Bowling", label: "Bowling" },
  ];

  const [teamOptions, setTeamOptions] = useState([]);

  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };
  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearching(searchValue);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log("Rows per page changed to:", newPerPage, page);
    setPerPage(newPerPage);
    setCurrentPage(page); // Reset to the first page
  };

  const fetchTeams = async (id) => {
    try {
      setTeamsLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result } = await getRequest(
        `/api/secure/v2/team/get-teams-by-tournament`,
        token,
        {
          page: currentPage,
          limit: perPage,
          search: searching,
          tournamentId: id,
        }
      );
      if (result?.status === 200) {
        setTeams(result.data.teams);
        setTotalRows(result.data.totalTeams);
      }
      setTeamsLoading(false);
    } catch (error) {
      toast.error(error.message, { position: "top-center" });
    } finally {
      console.log("teams", teams);
    }
  };

  useEffect(() => {
    fetchTeams(tournamentId);
  }, [currentPage, perPage, searching, tournamentId]);

  const fetchTournamentDetails = async (id) => {
    try {
      setTournamentLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result } = await getRequest(
        `/api/secure/v2/tournament/get-tournament`,
        token,
        {
          tournamentId: id,
        }
      );
      if (result?.status === 200) {
        const { tournament } = result.data;
        setTournament(tournament);
      }
    } catch (error) {
      toast.error(error.message, { position: "top-center" });
    } finally {
      setTournamentLoading(false);
    }
  };

  const columns = [
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <Form.Check
          type="checkbox"
          id={`checkbox-${row._id}`}
          checked={teamA?._id === row._id || teamB?._id === row._id}
          onChange={() => handleCheckboxChange(row)}
          disabled={
            !(teamA?._id === row._id || teamB?._id === row._id) &&
            teamA &&
            teamB
          } // Disable if 2 teams are already selected
          style={{
            cursor: "pointer",
            transform: "scale(1.7)",
            backgroundColor: "transparent",
          }}
        />
      ),
      maxWidth: "100px",
    },
    {
      name: "Profile",
      selector: (row) => (
        <LazyLoadImage
          src={row.teamImageUrl || Images.profile}
          alt="Team Image"
          width="40"
          height="40"
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "center",
            border: "1px solid rgba(231, 50, 147, 1)",
          }}
        />
      ),
      maxWidth: "80px",
    },
    {
      name: "Team Name",
      selector: (row) => row.teamName,
      sortable: true,
      style: { justifyContent: "start" },
    },
    // {
    //   name: "Owner",
    //   cell: (row) => (
    //     <div
    //       title={row?.owner?.email}
    //       className="d-flex gap-1 justify-content-between align-items-center"
    //     >
    //       <div className="me-2" title={row?.owner?.email}>
    //         <LazyLoadImage
    //           src={
    //             row?.owner?.profileImageUrl ? row?.owner?.profileImageUrl : null
    //           }
    //           alt={row?.owner?.email}
    //           width="40"
    //           height="40"
    //           style={{
    //             borderRadius: "50%",
    //             objectFit: "cover",
    //             objectPosition: "center",
    //             border: "1px solid rgba(231, 50, 147, 1)",
    //           }}
    //         />
    //       </div>
    //       <div className="text-light">
    //         <p
    //           style={{
    //             wordBreak: "break-all",
    //             margin: 0,
    //           }}
    //           title={row?.owner?.email}
    //         >
    //           {row?.owner?.fullName}
    //         </p>
    //       </div>
    //     </div>
    //   ),
    //   maxWidth: "200px",
    //   sortable: false,
    //   style: {
    //     justifyContent: "left",
    //   },
    // },
  ];

  // const handleCheckboxChange = (team) => {
  //   setTeams((prevTeams) =>
  //     prevTeams.map((t) =>
  //       t._id === team._id ? { ...t, selected: !t.selected } : t
  //     )
  //   );

  //   setTeams((prevTeams) => {
  //     const updatedTeam = prevTeams.find((t) => t._id === team._id);

  //     if (updatedTeam?.selected) {
  //       // If checked, assign it to Team A or Team B (if space available)
  //       if (!teamA) {
  //         setTeamA(updatedTeam);
  //       } else if (!teamB) {
  //         setTeamB(updatedTeam);
  //       } else {
  //         toast.warning("You can only select two teams!", {
  //           position: "top-center",
  //         });
  //       }
  //     } else {
  //       // If unchecked, remove it from Team A or Team B
  //       if (teamA?._id === updatedTeam._id) {
  //         setTeamA(null);
  //       } else if (teamB?._id === updatedTeam._id) {
  //         setTeamB(null);
  //       }
  //     }

  //     return prevTeams;
  //   });
  // };

  const handleCheckboxChange = (team) => {
    setTeams((prevTeams) => {
      const updatedTeams = prevTeams.map((t) =>
        t._id === team._id ? { ...t, selected: !t.selected } : t
      );

      const updatedTeam = updatedTeams.find((t) => t._id === team._id);
      const isSelected = updatedTeam?.selected; // Get latest selection state

      if (isSelected) {
        // Assign to Team A or B if available
        if (!teamA) {
          setTeamA(updatedTeam);
        } else if (!teamB) {
          setTeamB(updatedTeam);
        } else {
          toast.warning("You can only select two teams!", {
            position: "top-center",
          });
        }
      } else {
        // Remove from Team A or B if deselected
        if (teamA?._id === updatedTeam._id) {
          setTeamA(null);
        }
        if (teamB?._id === updatedTeam._id) {
          setTeamB(null);
        }
      }

      return updatedTeams;
    });
  };

  const fetchPlayers = async (teamId) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");

      const { result } = await getRequest(
        `/api/secure/v2/team/get-team`,
        token,
        {
          teamId,
        }
      );

      if (result?.status === 200) {
        const { team } = result.data;
        const players = team?.teamPlayers?.map((player) => ({
          value: player._id,
          label: player.playerName,
          image: player.playerImageUrl,
        }));

        return {
          options: players,
        };
      } else {
        throw new Error("Failed to fetch players");
      }
    } catch (error) {
      toast.error(error.message || "Error fetching players", {
        position: "top-center",
      });
      return { options: [] };
    } finally {
      setLoading(false);
    }
  };

  const handlePlayerTeamAChange = (selectedPlayers) => {
    setTeamAPlaying11(selectedPlayers);
  };

  const handlePlayerTeamBChange = (selectedPlayers) => {
    setTeamBPlaying11(selectedPlayers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!facingBatsman) {
        toast.warning("Please select facing batsman!", {
          position: "top-center",
        });
        return;
      }
      if (!setMatchNumber) {
        toast.warning("Please enter Venue!", {
          position: "top-center",
        });
        return;
      }

      if (!nonStriker) {
        toast.warning("Please select non-striker!", {
          position: "top-center",
        });
        return;
      }

      if (!bowler) {
        toast.warning("Please select bowler!", {
          position: "top-center",
        });
        return;
      }

      if (facingBatsman === nonStriker) {
        toast.warning("Facing batsman and non-striker cannot be same!", {
          position: "top-center",
        });
        return;
      }

      // store ids in player object

      const selectedPlayerAIds = teamAPlaying11.map((ply) => ({
        player: ply.value,
      }));
      const selectedPlayerBIds = teamBPlaying11.map((ply) => ({
        player: ply.value,
      }));
      const selectedPlayerAValues = selectedPlayerAIds.map((obj) => obj.player); // Extract IDs
      const selectedPlayerBValues = selectedPlayerBIds.map((obj) => obj.player); // Extract IDs

      // Filter substitute players correctly
      const substitutePlayersA = teamA.teamPlayers
        .filter(
          (player) => !selectedPlayerAValues.includes(player._id.toString())
        ) // Convert to string for comparison

      const substitutePlayersB = teamB.teamPlayers
        .filter(
          (player) => !selectedPlayerBValues.includes(player._id.toString())
        )

      console.log("Substitutes A:", substitutePlayersA);
      console.log("Substitutes B:", substitutePlayersB);

      const teams = [
        {
          teamId: teamA._id,
          selectedPlayers: selectedPlayerAIds,
          substitutePlayers: substitutePlayersA.map((player) =>({ player: player._id })),
          isTossWinner: tossWinner === teamA._id ? true : false,
          playedAs: teamAPlayedAs,
        },
        {
          teamId: teamB._id,
          selectedPlayers: selectedPlayerBIds,
          substitutePlayers: substitutePlayersB.map((player) =>({ player: player._id })),
          isTossWinner: tossWinner === teamB._id ? true : false,
          playedAs: teamBPlayedAs,
        },
      ];

      //The team that is playing as batting will be at Index 0 and the team that is playing as bowling will be at Index 1
      if (teamAPlayedAs === "Bowling") {
        teams.reverse();
      }


      const user = JSON.parse(getItemFromLocalStorage("USER"));
      const token = getItemFromLocalStorage("TOKEN");
      const ownerType = user.roleName?.replace(" ", "");
      const payload = {
        teams: teams,
        tournamentId,
        matchType,
        facingBatsman: { player: facingBatsman },
        nonStriker: { player: nonStriker },
        bowler: { player: bowler },
        ownerType,
        venue: matchNumber,
        overs: matchType === "Overs Match" ? overs : "50",
      };

      const endpoint = `/api/secure/v2/match/register-match`;

      const { result, error } = await postRequestForm(endpoint, token, payload);

      if (result?.status === 200) {
        toast.success("Match created successfully.", {
          position: "top-center",
        });
        navigate("/dashboard/previous");
      }

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, { position: "top-center" });
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (winnerAlphabet && electedTo) {
      console.log("winner Alphabet", winnerAlphabet);
      console.log("elected To", electedTo);

      if (winnerAlphabet === "A" && electedTo === "Batting") {
        setTeamAPlayedAs("Batting");
        setTeamBPlayedAs("Bowling");
      } else if (winnerAlphabet === "A" && electedTo === "Bowling") {
        setTeamAPlayedAs("Bowling");
        setTeamBPlayedAs("Batting");
      } else if (winnerAlphabet === "B" && electedTo === "Batting") {
        setTeamAPlayedAs("Bowling");
        setTeamBPlayedAs("Batting");
      } else if (winnerAlphabet === "B" && electedTo === "Bowling") {
        setTeamAPlayedAs("Batting");
        setTeamBPlayedAs("Bowling");
      }
    }
  }, [winnerAlphabet, electedTo]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("_id");
    console.log("id", id);

    if (id) {
      fetchTournamentDetails(id);
      fetchTeams(id);
      setTournamentId(id);
    }
  }, [search]);

  // useEffect(() => {
  //   const options = teams
  //     .filter((team) => team.selected)
  //     .map((team) => ({
  //       value: team._id,
  //       label: team.teamName,
  //     }));

  //   options.unshift({ value: "", label: "Select Toss Winning Team" });
  //   setTeamOptions(options);
  // }, [teamA, teamB]);

  useEffect(() => {
    if (!teamA && !teamB) {
      setTeamA(null);
      setTeamB(null);
      setShow("screen1");
    }
  }, []);

  return (
    <Layout>
      <section
        id="startMatchScreen"
        style={{
          // backgroundImage: `url(${Images.background2})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <BackButton
              icon={Images.backArrow}
              content="Back"
              onClick={() => navigate("/dashboard/all-tournament")}
            />
          </div>
          <p className="text-light pt-3 text-center font-secondary fs-4 fw-bold">
            Start Match
          </p>
          <div style={{ visibility: "hidden" }}>
            <BackButton
              icon={Images.backArrow}
              content="Back"
              onClick={() => navigate("/dashboard/all-tournament")}
            />
          </div>
        </div>
        {show === "screen1" ? (
          <Container className="logo-table-container mt-5">
            <div className="table-container">
              <div className="pb-3 heading d-flex align-items-center border-0">
                <h3 className="mb-0 me-3 heading">Search Team</h3>
                <FormControl
                  type="text"
                  placeholder="Name"
                  className="w-25 fs-6 p-0 ps-2"
                  value={searching}
                  onChange={handleSearchCategory}
                />
              </div>
              {teamsLoading ? (
                <TableSkeleton
                  row={perPage}
                  head={[
                    {
                      name: "Actions",
                      single: true,
                      className:
                        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell justify-content-center",
                      width: 30,
                      height: 30,
                    },
                    {
                      name: "Profile",
                      className:
                        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv elpSoT bOmZtP rdt_TableCell",
                      width: 30,
                      height: 30,
                      circle: true,
                    },
                    {
                      name: "Team Name",
                      className:
                        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                      width: 150,
                      height: 20,
                    },
                    // {
                    //   name: "Owner",
                    //   className:
                    //     "ssc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv kPFmFZ jEzKnw rdt_TableCell",
                    //   width: [30, 100],
                    //   height: [30, 20],
                    //   circle: true,
                    // },
                  ]}
                />
              ) : (
                <DataTable
                  columns={columns}
                  data={teams}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  noDataComponent={<p>No data found</p>}
                  customStyles={{
                    headCells: {
                      style: {
                        border: "1px solid #ccc",
                        color: "#fff",
                      },
                    },
                    cells: {
                      style: {
                        border: "1px solid #ccc",
                        color: "#fff",
                        height: "55px",
                      },
                    },
                  }}
                />
              )}
            </div>
            <br />
            <PairButtons
              content1="Cancel"
              onClick1={() => navigate("/dashboard/all-tournament")}
              backgroundColor1={"#fff"}
              color1={"#000"}
              icon1={Images.backArrowButton}
              content2={"Next"}
              onClick2={() => {
                if (teamA && teamB) {
                  setShow("screen2");
                } else {
                  toast.warning("Please select atleast two teams!", {
                    position: "top-center",
                  });
                }
              }}
              backgroundColor2={"rgba(231, 50, 147, 1)"}
              color2={"#fff"}
              icon2={Images.nextArrow}
              reversedIcon2={true}
            />
          </Container>
        ) : show === "screen2" ? (
          <Container className="image-input-container pb-5">
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.user} alt="icon" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Tournament Name"
                value={tournament?.tournamentName}
                required
                disabled
              />
            </InputGroup>

            {/* Tournament Type */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Tournament Type"
                value={tournament?.tournamentType}
                required
                disabled
              />
            </InputGroup>

            {/* Start Date */}
            <InputGroup className="mb-3 " size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.calendar} alt="icon" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Match Venue"
                value={matchNumber}
                onChange={(e) => setMatchNumber(e.target.value)}
                required
              />
            </InputGroup>

            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Match Type"
                onChange={(e) => setMatchType(e.target.value)}
                value={matchType}
                required
              >
                {matchTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>

            {matchType === "Overs Match" ? (
              <InputGroup className="mb-3" size="lg">
                <InputGroup.Text>
                  <LazyLoadImage src={Images.calendar} alt="icon" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Overs"
                  value={overs}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numbers and one decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      setOvers(value);
                    }
                  }}
                />
              </InputGroup>
            ) : null}

            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Toss Won By"
                onChange={(e) => {
                  setTossWinner(e.target.value);
                }}
                value={tossWinner}
                required
              >
                <option value="">Select Toss Winner</option>
                {teamA && <option value={teamA._id}>{teamA.teamName}</option>}
                {teamB && <option value={teamB._id}>{teamB.teamName}</option>}
              </Form.Select>
            </InputGroup>

            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Elected To"
                onChange={(e) => setElectedTo(e.target.value)}
                value={electedTo}
                label="Elected To"
                required
              >
                {tossOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>

            {/* Team A Playing 11 */}
            <InputGroup className="text-input-container mb-3">
              <AsyncPaginate
                className="bg-transparent w-100 border-0"
                value={teamAPlaying11 ? teamAPlaying11 : ""}
                loadOptions={() => fetchPlayers(teamA._id)}
                getOptionLabel={(option) => (
                  <div className="d-flex align-items-center">
                    <img
                      src={option.image}
                      alt={option.label}
                      width="30"
                      height="30"
                      className="me-2 rounded-circle"
                    />
                    {option.label}
                  </div>
                )}
                getOptionValue={(option) => option.value}
                onChange={handlePlayerTeamAChange}
                placeholder={`Playing 11 for Team A (${teamA.teamName})`}
                isClearable
                loadingMessage={() => "Loading..."}
                noOptionsMessage={() => "No Player Team A Found"}
                additional={{
                  page: 1,
                }}
                isMulti
                isSearchable
                styles={{
                  menu: (base) => ({
                    ...base,
                    border: 0,
                    backgroundColor: "rgb(164, 207, 255)",
                    // open upwards
                    top: "auto",
                    // open upwards
                    bottom: "100%",
                    margin: 0,
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      backgroundColor: isSelected
                        ? "rgba(16, 14, 52, 1)"
                        : isFocused
                        ? "rgb(164, 207, 255)"
                        : null,
                      color: isSelected ? "white" : "black",
                    };
                  },
                }}
              />
            </InputGroup>

            {/* Team B Playing 11 */}
            <InputGroup className="text-input-container mb-3">
              <AsyncPaginate
                className="bg-transparent w-100 border-0"
                value={teamBPlaying11 ? teamBPlaying11 : ""}
                loadOptions={() => fetchPlayers(teamB._id)}
                getOptionLabel={(option) => (
                  <div className="d-flex align-items-center">
                    <img
                      src={option.image}
                      alt={option.label}
                      width="30"
                      height="30"
                      className="me-2 rounded-circle"
                    />
                    {option.label}
                  </div>
                )}
                getOptionValue={(option) => option.value}
                onChange={handlePlayerTeamBChange}
                placeholder={`Playing 11 for Team B (${teamB.teamName})`}
                isClearable
                loadingMessage={() => "Loading..."}
                noOptionsMessage={() => "No Player Team B Found"}
                additional={{
                  page: 1,
                }}
                isMulti
                isSearchable
                styles={{
                  menu: (base) => ({
                    ...base,
                    border: 0,
                    backgroundColor: "rgb(164, 207, 255)",
                    // open upwards
                    top: "auto",
                    // open upwards
                    bottom: "100%",
                    margin: 0,
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      backgroundColor: isSelected
                        ? "rgba(16, 14, 52, 1)"
                        : isFocused
                        ? "rgb(164, 207, 255)"
                        : null,
                      color: isSelected ? "white" : "black",
                    };
                  },
                }}
              />
            </InputGroup>
            <br />

            <PairButtons
              content1="Back"
              onClick1={() => setShow("screen1")}
              backgroundColor1={"#fff"}
              color1={"#000"}
              icon1={Images.backArrowButton}
              content2={"Next"}
              onClick2={() => {
                if (!tossWinner) {
                  toast.warning("Please select toss winner!", {
                    position: "top-center",
                  });
                  return;
                }

                if (!electedTo) {
                  toast.warning("Please select elected to!", {
                    position: "top-center",
                  });
                  return;
                }

                if (!overs && matchType === "Overs Match") {
                  toast.warning("Please enter overs!", {
                    position: "top-center",
                  });
                  return;
                }

                if (!matchType) {
                  toast.warning("Please select match type!", {
                    position: "top-center",
                  });
                  return;
                }

                // if (teamAPlaying11.length < 11) {
                //   toast.warning("Please select 11 players for Team A!", {
                //     position: "top-center",
                //   });
                //   return;
                // }

                // if (teamBPlaying11.length < 11) {
                //   toast.warning("Please select 11 players for Team B!", {
                //     position: "top-center",
                //   });
                //   return;
                // }

                setWinnerAlphabet(tossWinner === teamA._id ? "A" : "B");
                setShow("screen3");
              }}
              backgroundColor2={"rgba(231, 50, 147, 1)"}
              color2={"#fff"}
              icon2={Images.nextArrow}
              reversedIcon2={true}
            />
          </Container>
        ) : show === "screen3" ? (
          <Container className="image-input-container pb-5">
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Facing Batsman"
                onChange={(e) => setFacingBatsman(e.target.value)}
                value={facingBatsman}
                required
              >
                <option value="">Select Facing Batsman</option>
                {winnerAlphabet === "A" && electedTo === "Batting"
                  ? teamAPlaying11.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))
                  : winnerAlphabet === "B" && electedTo === "Bowling"
                  ? teamAPlaying11.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))
                  : teamBPlaying11.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Non-Striker Batsman"
                onChange={(e) => setNonStriker(e.target.value)}
                value={nonStriker}
                required
              >
                <option value="">Select Non-Striker Batsman</option>
                {winnerAlphabet === "A" && electedTo === "Batting"
                  ? teamAPlaying11
                      .filter((option) => option.value !== facingBatsman)
                      .map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                  : winnerAlphabet === "B" && electedTo === "Bowling"
                  ? teamAPlaying11
                      .filter((option) => option.value !== facingBatsman)
                      .map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                  : teamBPlaying11
                      .filter((option) => option.value !== facingBatsman)
                      .map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
              </Form.Select>
            </InputGroup>

            {facingBatsman && (
              <InputGroup className="mb-3" size="lg">
                <InputGroup.Text>
                  <LazyLoadImage src={Images.tournamentType} alt="icon" />
                </InputGroup.Text>
                <Form.Select
                  aria-label="Bowler"
                  onChange={(e) => setBowler(e.target.value)}
                  value={bowler}
                  required
                >
                  <option value="">Select Bowler</option>
                  {winnerAlphabet === "A" && electedTo === "Batting"
                    ? teamBPlaying11.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    : winnerAlphabet === "B" && electedTo === "Bowling"
                    ? teamBPlaying11.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    : teamAPlaying11.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                </Form.Select>
              </InputGroup>
            )}
            <PairButtons
              content1="Back"
              onClick1={() => setShow("screen2")}
              backgroundColor1={"#fff"}
              color1={"#000"}
              icon1={Images.backArrowButton}
              content2={loading ? <Spinner size="sm" /> : "Start Match"}
              onClick2={handleSubmit}
              backgroundColor2={"rgba(231, 50, 147, 1)"}
              color2={"#fff"}
              icon2={Images.nextArrow}
              reversedIcon2={true}
            />
          </Container>
        ) : null}
      </section>
    </Layout>
  );
};

export default StartMatch;
