import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../assets/css/allplayerskeleton.scss";
const TableSkeleton = ({
  row = 5,
  head = [
    {
      name: "Profile",
      className:
        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv elpSoT bOmZtP rdt_TableCell",
      width: 40,
      height: 40,
      circle: true,
    },
    {
      name: "Player Name",
      className:
        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
      width: 150,
      height: 20,
    },
    {
      name: "Owner",
      className:
        "ssc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv kPFmFZ jEzKnw rdt_TableCell",
      width: [40, 100],
      height: [40, 20],
      circle: true,
    },
    {
      name: "Actions",
      className:
        "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell",
      width: [30, 30],
      height: [30, 30],
    },
  ],
}) => {
  const skeletonRows = Array(row).fill(0);
  return (
    <div className="allplayerskeleton">
      <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
        <div className="sc-eUlrpB hZZlND">
          <div className="sc-hJRrWL yGWof">
            <div className="sc-blHHSb dknajr rdt_Table" role="table">
              <div className="sc-gtLWhw caNzNe rdt_TableHead" role="rowgroup">
                <div className="sc-egkSDF hudJpJ rdt_TableHeadRow" role="row">
                  {head.map((_, index) => (
                    <div data-column-id={index} className={_.className}>
                      <div
                        data-column-id={index}
                        data-sort-id={index}
                        role="columnheader"
                        tabindex="0"
                        className="sc-jwIPbr bAhklE rdt_TableCol_Sortable"
                        disabled=""
                      >
                        <div
                          data-column-id={index}
                          className="sc-cHqXqK ddvZdT"
                        >
                          {_.name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class="sc-dprtRQ htmdXE rdt_TableBody" role="rowgroup">
                {skeletonRows.map((_, rowIndex) => (
                  <div
                    id={`row-${rowIndex}`}
                    role="row"
                    class="sc-dstKZu kQSTYI rdt_TableRow"
                  >
                    {head.map((column, colIndex) => {
                      const isCircle = column.circle || false;
                      return (
                        <div
                          id={`cell-1-${colIndex}-${rowIndex}`}
                          data-column-id={colIndex}
                          role="cell"
                          class={column.className}
                          data-tag="allowRowEvents"
                        >
                          <div data-tag="allowRowEvents">
                            {column.name === "Owner" ? (
                              <div
                                title="User Email"
                                class="d-flex gap-1 justify-content-center align-items-center"
                              >
                                <div class="me-2" title="User Email">
                                  {isCircle && (
                                    <Skeleton
                                      circle
                                      width={
                                        column.width.length > 1
                                          ? column.width[0]
                                          : column.width
                                      }
                                      height={
                                        column.height.length > 1
                                          ? column.height[0]
                                          : column.height
                                      }
                                    />
                                  )}
                                </div>
                                <div
                                  class="text-light
                                  "
                                >
                                  <Skeleton
                                    width={
                                      column.width.length > 1
                                        ? column.width[1]
                                        : column.width
                                    }
                                    height={
                                      column.height.length > 1
                                        ? column.height[1]
                                        : column.height
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                {isCircle && (
                                  <Skeleton
                                    circle
                                    width={
                                      column.width.length > 1
                                        ? column.width[0]
                                        : column.width
                                    }
                                    height={
                                      column.height.length > 1
                                        ? column.height[0]
                                        : column.height
                                    }
                                  />
                                )}
                              </>
                            )}
                            {column.name === "Actions" && (
                              <>
                                {column.single ? (
                                  <div className="d-flex">
                                    <Skeleton
                                      width={
                                        column.width.length > 1
                                          ? column.width[1]
                                          : column.width
                                      }
                                      height={
                                        column.height.length > 1
                                          ? column.height[1]
                                          : column.height
                                      }
                                      className="my-3"
                                    />
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <Skeleton
                                      width={
                                        column.width.length > 1
                                          ? column.width[0]
                                          : column.width
                                      }
                                      height={
                                        column.height.length > 1
                                          ? column.height[0]
                                          : column.height
                                      }
                                      className="my-3 me-2"
                                    />
                                    <Skeleton
                                      width={
                                        column.width.length > 1
                                          ? column.width[1]
                                          : column.width
                                      }
                                      height={
                                        column.height.length > 1
                                          ? column.height[1]
                                          : column.height
                                      }
                                      className="my-3"
                                    />
                                  </div>
                                )}
                              </>
                            )}
                            {column.name.includes("Name") && (
                              <Skeleton
                                width={
                                  column.width.length > 1
                                    ? column.width[0]
                                    : column.width
                                }
                                height={
                                  column.height.length > 1
                                    ? column.height[0]
                                    : column.height
                                }
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {/* <div
                      id={`cell-1-${index}`}
                      data-column-id="1"
                      role="cell"
                      class="sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv elpSoT bOmZtP rdt_TableCell"
                      data-tag="allowRowEvents"
                      maxwidth="80px"
                    >
                      <div data-tag="allowRowEvents">
                        <Skeleton
                          circle
                          width={40}
                          height={40}
                          style={{ marginRight: "0" }}
                        />
                      </div>
                    </div>
                    <div
                      id={`cell-2-${index}`}
                      data-column-id="2"
                      role="cell"
                      class="sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell"
                      data-tag="allowRowEvents"
                    >
                      <div data-tag="allowRowEvents">
                        <Skeleton width={100} height={20} />
                      </div>
                    </div>
                    <div
                      id={`cell-3-${index}`}
                      data-column-id="3"
                      role="cell"
                      class="sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv kPFmFZ jEzKnw rdt_TableCell"
                      data-tag="allowRowEvents"
                      maxwidth="200px"
                    >
                      <div
                        title="User Email"
                        class="d-flex gap-1 justify-content-center align-items-center"
                      >
                        <div class="me-2" title="User Email">
                          <Skeleton
                            circle
                            width={40}
                            height={40}
                            style={{ marginRight: "0" }}
                          />
                        </div>
                        <div class="text-light">
                          <Skeleton width={100} height={20} />
                        </div>
                      </div>
                    </div>
                    <div
                      id={`cell-4-${index}`}
                      data-column-id="4"
                      role="cell"
                      class="sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell"
                      data-tag="allowRowEvents"
                      maxwidth="100px"
                    >
                      <Skeleton width={30} height={30} className="my-3 me-2" />
                      <Skeleton width={30} height={30} className="my-3" />
                    </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default TableSkeleton;
