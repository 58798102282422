import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
  removeItemFromLocalStorage,
} from "../helper/helper";
import { postRequestForm } from "../helper/api";
import routes from "../helper/routes"; // Import your routes
import { toast } from "react-toastify";

const Layout = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isPrivateRoute = (routes, currentPath) => {
    return routes.some((route) => {
      const routePath = route.path?.replace(/\/$/, "");
      const normalizedCurrentPath = currentPath?.replace(/\/$/, "");
      if (routePath === normalizedCurrentPath && route.layout === "PRIVATE") {
        return true;
      }
      if (route.subMenu && Array.isArray(route.subMenu)) {
        return isPrivateRoute(route.subMenu, currentPath);
      }
      return false;
    });
  };

  const isPublicRoute = (routes, currentPath) => {
    return routes.some((route) => {
      const routePath = route.path?.replace(/\/$/, "");
      const normalizedCurrentPath = currentPath?.replace(/\/$/, "");
      if (routePath === normalizedCurrentPath && route.layout === "PUBLIC") {
        return true;
      }
      if (route.subMenu && Array.isArray(route.subMenu)) {
        return isPublicRoute(route.subMenu, currentPath);
      }
      return false;
    });
  };

  const callRefreshTokenAPI = async () => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const user = JSON.parse(getItemFromLocalStorage("USER"));
      const { result, error } = await postRequestForm(
        `/api/auth/v2/${user?.roleName.replace(" ", "")}/refresh-token`,
        "",
        { token }
      );

      if (result?.status === 200 || result?.status === 201) {
        const { token: newToken, user: newUser } = result.data;
        setItemInLocalStorage("TOKEN", newToken);
        setItemInLocalStorage("USER", JSON.stringify(newUser));
        setUser(newUser);
      } else {
        throw new Error(
          error?.response?.data?.message || "Password update failed."
        );
      }
    } catch (error) {
      toast.error(error.message || "An error occurred.");
      removeItemFromLocalStorage("TOKEN");
      removeItemFromLocalStorage("USER");
      navigate("/user/login");
    }
  };

  useEffect(() => {
    const user = JSON.parse(getItemFromLocalStorage("USER"));
    const token = getItemFromLocalStorage("TOKEN");

    const isPrivate = isPrivateRoute(routes, location.pathname);
    const isPublic = isPublicRoute(routes, location.pathname);

    if (isPrivate) {
      if (token && user) {
        // setUser(user);
        callRefreshTokenAPI();
      } else {
        removeItemFromLocalStorage("TOKEN");
        removeItemFromLocalStorage("USER");
        navigate("/user/login");
      }
    } else if (isPublic) {
      if (token && user) {
        navigate("/");
      }
    }

    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    window.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [navigate, location]);

  const childProps = {
    user,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }
    return child;
  });

  return <main id="main">{childrenWithProps}</main>;
};

export default Layout;
