import React, { useRef, useEffect } from "react";
import { Images } from "../../../constants/Images";

const scoreColors = {
  0: "white",
  1: "blue",
  2: "green",
  3: "yellow",
  4: "orange",
  5: "red",
  6: "red",
};

const BallLengthTracker = ({ score, ballLengthCords, setBallLengthCords }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;

    // Load and draw the pitch image
    const img = new Image();
    img.src = Images.ballLength; // Replace with the actual image path
    img.onload = () => {
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);

      // Draw horizontal sections
      drawSections(ctx, width, height);

      // Draw the dot if a point is selected
      if (ballLengthCords) {
        ctx.beginPath();
        ctx.arc(ballLengthCords.x, ballLengthCords.y, 4, 0, 2 * Math.PI);
        ctx.fillStyle = ballLengthCords.color;
        ctx.fill();
      }
    };
  }, [ballLengthCords]);

  const drawSections = (ctx, width, height) => {
    const fullTossEnd = height * 0.1;
    const yorkerEnd = fullTossEnd + height * 0.1;
    const halfVolleyEnd = yorkerEnd + height * 0.1;
    const goodLengthEnd = halfVolleyEnd + height * 0.11;
    const shortOfGoodEnd = goodLengthEnd + height * 0.1;
    const shortEnd = height; // Remaining 50% is short

    const sections = [
      { label: "Full Toss", end: fullTossEnd },
      { label: "Yorker", end: yorkerEnd },
      { label: "Half Volley", end: halfVolleyEnd },
      { label: "Good Length", end: goodLengthEnd },
      { label: "Short of Good", end: shortOfGoodEnd },
      { label: "Short", end: shortEnd },
    ];

    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;
    ctx.fillStyle = "black";
    ctx.font = "bold 12px Arial";
    ctx.textAlign = "left";

    let prevY = 0;
    sections.forEach(({ label, end }) => {
      // Draw horizontal line
      ctx.beginPath();
      ctx.moveTo(0, end);
      ctx.lineTo(width, end);
      ctx.stroke();

      // Add labels
    //   ctx.fillText(label, 10, (prevY + end) / 2);
    //   prevY = end;
    });
  };

  const handleCanvasClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    const height = canvas.height;
    const fullTossEnd = height * 0.1;
    const yorkerEnd = fullTossEnd + height * 0.1;
    const halfVolleyEnd = yorkerEnd + height * 0.1;
    const goodLengthEnd = halfVolleyEnd + height * 0.1;
    const shortOfGoodEnd = goodLengthEnd + height * 0.1;

    let category;
    if (mouseY <= fullTossEnd) {
      category = "Full Toss";
    } else if (mouseY <= yorkerEnd) {
      category = "Yorker";
    } else if (mouseY <= halfVolleyEnd) {
      category = "Half Volley";
    } else if (mouseY <= goodLengthEnd) {
      category = "Good Length";
    } else if (mouseY <= shortOfGoodEnd) {
      category = "Short of Good";
    } else {
      category = "Short";
    }

    setBallLengthCords({
      x: mouseX,
      y: mouseY,
      color: score === null ? "black" : scoreColors[score],
      category,
    });

    console.log(`Ball Length Selected: ${mouseX}, ${mouseY}, ${category}`);
  };

  return (
      <canvas
        ref={canvasRef}
        width={200}
        height={200}
        style={{
          border: "1px solid black",
          cursor: "pointer",
        }}
        onClick={handleCanvasClick}
      />
  );
};

export default BallLengthTracker;
