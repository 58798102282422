import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactImageUploading from "react-images-uploading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequestForm, uploadURL } from "../../../helper/api";
import Layout from "../../../layouts/Layout";
import { Images } from "../../../constants/Images";
import BackButton from "../../../constants/BackButton";
import PairButtons from "../../../constants/PairButtons";
import { getItemFromLocalStorage } from "../../../helper/helper";
import SingleButton from "../../../constants/SingleButton";
import EditTeamSkeleton from "../../../constants/Skeleton Loaders/EditTeamSkeleton";

const CreateEditTeam = () => {
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const [name, setName] = useState("");
  const [teamImage, setTeamImage] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [createUpdateLoading, setCreateUpdateLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  // Image Upload Handling
  const onChangeImage = (imageList, addUpdateIndex) => {
    setTeamImage(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  // Handle Team Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setCreateUpdateLoading(true);
      const endpoint = isEdit
        ? `/api/secure/v2/team/edit-team`
        : `/api/secure/v2/team/register-team`;
      const formData = new FormData();
      formData.append("teamName", name);
      if (selectedPlayers.length === 0) {
        toast.error("Please add at least one player.", {
          position: "top-center",
        });
      } else {
        // Append selectedPlayers as teamPlayers array
        selectedPlayers.forEach((player) => {
          formData.append("teamPlayers[]", player._id);
        });
      }

      if (teamImage.some((file) => file.hasOwnProperty("file"))) {
        if (featuredImage) {
          formData.append("teamImage", featuredImage);
        } else {
          toast.error("Please Upload the Thumbnail Image for a Team.", {
            position: "top-center",
            theme: "colored",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }

      if (isEdit) {
        formData.append("teamId", teamId);
      }

      const user = JSON.parse(getItemFromLocalStorage("USER"));

      if (!isEdit) {
        formData.append("ownerType", user.roleName?.replace(" ", ""));
      }

      console.log("formData", Object.fromEntries(formData));

      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        endpoint,
        token,
        formData
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success(`Team ${isEdit ? "updated" : "created"} successfully.`, {
          position: "top-center",
        });
        navigate("/dashboard/all-teams");
      } else {
        throw new Error(error?.response?.data?.message || "Operation failed");
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
    setCreateUpdateLoading(false);
  };

  // Check if editing a player
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("_id");
    console.log("id", id);
    if (id) {
      setIsEdit(true);
      fetchTeamDetails(id);
    }
  }, [search]);

  useEffect(() => {
    if (state) {
      setName(state?.teamName || "");
      setSelectedPlayers(state?.players || []);
      setTeamImage(state?.images || []);
      setFeaturedImage(state?.images?.[0]?.file || null);
      if (state?.isEdit) {
        setIsEdit(true);
        setTeamId(state?.teamId);
      }
      console.log("state", state);
    }
  }, [state]);

  // Fetch Player Details for Editing
  const fetchTeamDetails = async (id) => {
    try {
      setDataLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/team/get-team`,
        token,
        { teamId: id }
      );
      if (result?.status === 200) {
        const { team } = result.data;
        setTeamId(team?._id);
        setName(team?.teamName);
        if (team.teamImage) {
          setTeamImage([{ data_url: team.teamImageUrl }]);
          setFeaturedImage(team.teamImage);
        }
        const modifiedPlayers = team?.teamPlayers?.map((player) => ({
          _id: player?._id,
          name: player?.playerName,
          image: player?.playerImageUrl,
          selected: true,
        }));
        setSelectedPlayers(modifiedPlayers);

        console.log("state after fetch", state);
        if (state) {
          setSelectedPlayers(state?.players);
        }
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch player details"
        );
      }
      setDataLoading(false);
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  const handleRemovePlayer = async (playerId) => {
    const updatedPlayers = selectedPlayers.filter(
      (player) => player._id !== playerId
    );
    setSelectedPlayers(updatedPlayers);
  };

  return (
    <Layout>
      <section
        id="createTeam"
        style={{
          // backgroundImage: `url(${Images.background2})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        <Container>
          {/* <div className="d-flex justify-content-between align-items-center"> */}
            <BackButton
              icon={Images.backArrow}
              onClick={() => navigate("/dashboard/all-teams")}
              content="Back"
            />
            <p className="text-light mt-2 text-center font-secondary fs-4 fw-bold">
              {isEdit ? "UPDATE TEAM" : "CREATE TEAM"}
            </p>
            {/* <div></div>
          </div> */}
        </Container>
        {dataLoading ? (
          <EditTeamSkeleton />
        ) : (
          <Container className="list-container w-75">
            <Row>
              <Col lg={10} md={10} xs={10}>
                <span className="pb-2 heading d-flex align-items-center">
                  <p className="mb-0 me-3">Team Name:</p>
                  <FormControl
                    type="text"
                    placeholder="Name"
                    className="w-50"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </span>
              </Col>

              <Col lg={2} md={2} xs={2}>
                <ReactImageUploading
                  value={teamImage}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                  maxNumber={1}
                  maxFileSize={10000000}
                  acceptType={["jpeg", "jpg", "gif", "png"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                  }) => (
                    <>
                      <div className="upload__image-wrapper mb-3 ">
                        {imageList?.length === 0 ? (
                          <div
                            className="image-item d-flex justify-content-center"
                            style={{
                              border: "1px solid #ccc",
                              cursor: "pointer",
                              width: "80px",
                              height: "80px",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundImage: `url(${
                                teamImage || Images.profile
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundBlendMode: "overlay",
                              backgroundColor: "rgba(231, 50, 147, .5)",
                              border: "1px solid rgba(231, 50, 147, 1)",
                              borderRadius: "50%",
                              ...(isDragging ? { color: "red" } : {}),
                            }}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              color="#000"
                              style={{
                                fontSize: "30px",
                              }}
                            />
                          </div>
                        ) : (
                          imageList?.map((image, index) => (
                            <div
                              key={index}
                              className="image-item position-relative d-inline-block"
                            >
                              <LazyLoadImage
                                src={image["data_url"]}
                                width={80}
                                height={80}
                                className="rounded-circle"
                                alt="uploaded"
                              />
                              <Button
                                className="position-absolute bg-light p-1 rounded-circle translate-middle p-0"
                                variant="link"
                                onClick={() => onImageUpdate(index)}
                                style={{
                                  lineHeight: 1,
                                  right: -10,
                                  bottom: -10,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  color="rgb(231, 50, 147)"
                                />
                              </Button>
                            </div>
                          ))
                        )}
                      </div>
                    </>
                  )}
                </ReactImageUploading>
              </Col>

              <Col lg={12} md={12} xs={12}>
                <div className="item-container mb-4">
                  <>
                    {selectedPlayers &&
                      selectedPlayers.map((player) => (
                        <>
                          <div className="list-item" key={player._id}>
                            <div className="item-content">
                              <LazyLoadImage
                                src={player.image}
                                className="flag"
                                height={50}
                                width={50}
                              />
                              <p className="team-name">{player.name}</p>
                            </div>

                            <div className="item-buttons">
                              <Button
                                className="btn-default"
                                onClick={() => handleRemovePlayer(player._id)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                </div>
              </Col>
            </Row>

            {/* Add Player Buttons */}
            <PairButtons
              content1="Add New Player"
              onClick1={() =>
                navigate("/dashboard/add-new-player", {
                  state: {
                    teamName: name,
                    images: teamImage,
                    selectedPlayers,
                    isEdit,
                    teamId,
                  },
                })
              }
              icon1={Images.plus}
              backgroundColor1={"rgba(231, 50, 147, 1)"}
              color1={"#fff"}
              // content2="Add Existing Player"
              // onClick2={() =>
              //   navigate("/existing-player", {
              //     state: {
              //       teamName: name,
              //       images: teamImage,
              //       selectedPlayers,
              //       isEdit,
              //       teamId,
              //     },
              //   })
              // }
              // icon2={Images.plus}
              // backgroundColor2={"rgba(231, 50, 147, 1)"}
              // color2={"#fff"}
            />
            <br />
            <SingleButton
              content={
                createUpdateLoading ? (
                  <Spinner size="sm" />
                ) : isEdit ? (
                  "UPDATE TEAM"
                ) : (
                  "CREATE TEAM"
                )
              }
              onClick={handleSubmit}
              backgroundColor={"rgba(231, 50, 147, 1)"}
              color={"#fff"}
              buttonStyles={{
                width: "100%",
              }}
            />
          </Container>
        )}
      </section>
    </Layout>
  );
};

export default CreateEditTeam;
