import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Container,
  Modal,
  Form,
  Col,
  Row,
  FormControl,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEdit,
  faTrash,
  faPencilAlt,
  faPlus,
  faBriefcaseClock,
  faTrashRestore,
  faUserLock,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Layout from "../../../layouts/Layout";
import { Images } from "../../../constants/Images";
import BackButton from "../../../constants/BackButton";
import { toast } from "react-toastify";
import routes from "../../../helper/routes";
import Select from "react-select";
import ReactImageUploading from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../helper/helper";
import {
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
} from "../../../helper/api";
import { AsyncPaginate } from "react-select-async-paginate";
import SingleButton from "../../../constants/SingleButton";
import TableSkeleton from "../../../constants/Skeleton Loaders/TableSkeleton";
import moment from "moment";

const AllAdmin = () => {
  const [admins, setAdmins] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableHead, setTableHead] = useState();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [modalFunction, setModalFunction] = useState("Creete User");
  const [shouldFetch, setShouldFetch] = useState(false);

  const navigate = useNavigate();

  const handleShowModal = (modalFunction) => {
    setShowModal(true);
    setModalFunction(modalFunction);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAdmin(null);
    getAllAdmins(currentPage, perPage);
  };

  const handleBlockRecover = async (id, status) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      if (status === "active") {
        const { result, error } = await postRequestForm(
          `/api/secure/v2/Admin/block`,
          token,
          { userId: id }
        );
        if (result?.status === 200 || result?.status === 201) {
          toast.success("User Blocked successfully.", {
            position: "top-center",
          });
          getAllAdmins(currentPage, perPage);
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
            theme: "colored",
          });
        }
      } else {
        const { result, error } = await postRequestForm(
          `/api/secure/v2/Admin/recover`,
          token,
          { userId: id }
        );
        if (result?.status === 200 || result?.status === 201) {
          toast.success("User Recovered successfully.", {
            position: "top-center",
          });
          getAllAdmins(currentPage, perPage);
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
            theme: "colored",
          });
        }
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };

  const getAllAdmins = useCallback(
    async (currentPage, perPage, search) => {
      try {
        setLoading(true);
        const token = getItemFromLocalStorage("TOKEN");
        const { result, error } = await getRequest(
          `/api/secure/v2/Admin/get-all`,
          token,
          { page: currentPage, limit: perPage, search: search }
        );

        if (result?.status === 200 || result?.status === 201) {
          setTableData(result?.data?.data);
          setTotalRows(result?.data?.totalUsers);
        } else {
          throw new Error(
            error?.response?.data?.message || "An error occurred."
          );
        }

        setTableHead([
          {
            name: "Profile",
            maxWidth: "40px",
            center: true,
            selector: (row) => (
              <LazyLoadImage
                src={row?.profileImageUrl || Images.profile}
                alt="icon"
                width={30}
                height={30}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  objectPosition: "center",
                  border: "1px solid rgba(231, 50, 147, 1)",
                }}
              />
            ),
          },
          {
            name: "Name",
            selector: (row) => row.fullName,
            sortable: true,
          },
          {
            name: "Time Until Logout",
            selector: (row) =>
              `${moment(row?.trialExpiry).format("DD MMM YYYY")}`,
            sortable: true,
            center: true,
          },
          {
            name: "Actions",
            maxWidth: "100px",
            cell: (row) => (
              <>
                <Button
                  variant="primary"
                  size="sm"
                  className="me-2 my-3"
                  onClick={() => {
                    setSelectedAdmin(row);
                    handleShowModal("Update User");
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                {/* <Button
                  variant="danger"
                  size="sm"
                  className="me-2 my-3"
                  onClick={() => handleDeleteSuperAdmin(row._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button> */}
                {row?.status === "active" ? (
                  <Button
                    variant="danger"
                    size="sm"
                    className=" my-3"
                    onClick={() => {
                      handleBlockRecover(row._id, row.status);
                    }}
                  >
                    <FontAwesomeIcon icon={faUserSlash} title="Block User" />
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    size="sm"
                    className="my-3"
                    onClick={() => {
                      handleBlockRecover(row._id, row.status);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashRestore}
                      title="Recover User"
                    />
                  </Button>
                )}
              </>
            ),
          },
        ]);
      } catch (err) {
        toast.error(err.message || "An error occurred.", {
          position: "top-center",
        });
      } finally {
        setLoading(false);
      }
    },
    [currentPage, perPage, search] // Stable reference
  );

  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log("Rows per page changed to:", newPerPage, page);
    setPerPage(newPerPage);
    setCurrentPage(page); // Reset to the first page
  };

  useEffect(() => {
    getAllAdmins(currentPage, perPage);
  }, [currentPage, perPage, getAllAdmins, search]);

  const handleDeleteSuperAdmin = async (id) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await deleteRequest(
        `/api/secure/v2/Admin/delete`,
        { userId: id },
        token
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("User deleted successfully.", {
          position: "top-center",
        });
        getAllAdmins(currentPage, perPage);
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };
  return (
    <Layout>
      <section
        id="startMatch"
        style={{
          // backgroundImage: `url(${Images.background2})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <BackButton
              icon={Images.backArrow}
              content="Back"
              onClick={() => navigate("/dashboard")}
            />{" "}
            <p className="text-light mt-2 font-main fs-4 fw-bold">All Users</p>
            <SingleButton
              variant="primary"
              backgroundColor={"#fff"}
              color={"rgba(231, 50, 147, 1)"}
              content={"Create User"}
              onClick={() => {
                setSelectedAdmin(null);
                handleShowModal("Create User");
              }}
              buttonStyles={
                {
                  // width: "100%",
                }
              }
            />
          </div>
        </Container>
        <Container className="logo-table-container mt-5">
          {/* <LazyLoadImage
            className="logo mt-3 mb-5"
            src={Images.logo}
            width={100}
            alt="Logo"
          /> */}
          <div className="table-container">
            <Row className="mb-3">
              <Col xs={12} className="mt-3">
                <FormControl
                  type="text"
                  placeholder="Search User"
                  className="me-2"
                  value={search}
                  onChange={handleSearchCategory}
                />
              </Col>
            </Row>
            <br />
            {loading ? (
              <TableSkeleton
                row={5}
                head={[
                  {
                    name: "Profile",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv elpSoT bOmZtP rdt_TableCell",
                    width: 40,
                    height: 40,
                    circle: true,
                  },
                  {
                    name: "Name",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                    width: 150,
                    height: 20,
                  },
                  {
                    name: "Time Until Logout",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                    width: 150,
                    height: 20,
                  },
                  {
                    name: "Actions",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell",
                    width: [30, 30],
                    height: [30, 30],
                  },
                ]}
              />
            ) : (
              <DataTable
                columns={tableHead}
                data={tableData}
                paginationPerPage={perPage}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination
                paginationServer
                highlightOnHover
                responsive
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                customStyles={{
                  headCells: {
                    style: {
                      border: "1px solid #ccc",
                      color: "#fff",
                    },
                  },
                  cells: {
                    style: {
                      border: "1px solid #ccc",
                      color: "#fff",
                    },
                  },
                }}
              />
            )}
          </div>

          <SuperAdminModal
            show={showModal}
            onHide={handleCloseModal}
            adminData={selectedAdmin}
            modalFunction={modalFunction}
          />
        </Container>
      </section>
    </Layout>
  );
};

const SuperAdminModal = ({ show, onHide, adminData, modalFunction }) => {
  useEffect(() => {
    if (adminData) {
      const user = adminData;
      const {
        firstName,
        lastName,
        email,
        profileImage,
        profileImageUrl,
        permissions,
        trialPeriod,
        roleName,
      } = user;
      setUserId(user._id);
      setFirstName(firstName);
      setLastName(lastName);
      setEmail(email);
      setProfileImageUrl(profileImageUrl);
      if (password) {
        setPassword(password);
      }
      if (roleName) {
        setRole(roleName);
      }
      if (permissions) {
        setPermissions(permissions);
      }

      if (profileImage) {
        setImageList([{ data_url: `${profileImageUrl}` }]);
      }
      if (trialPeriod) {
        setTrial({
          value: trialPeriod,
          label: getLabelByValue(trialOptions, trialPeriod),
        });
      }
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setProfileImageUrl("");
      setImageList([]);
      setFeaturedImage(null);
      setLoading(false);
      setRole("");
      setPassword("");
      setPermissions([]);
      setTrial(null);
    }
  }, [adminData]);

  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [imageList, setImageList] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);
  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trialOptions] = useState([
    { value: 1, label: "1 Day Test" },
    { value: 14, label: "Trial 14 Days" },
    { value: 30, label: "1 month" },
    { value: 90, label: "3 months" },
    { value: 180, label: "6 months" },
    { value: 365, label: "1 year" },
  ]);
  const [trial, setTrial] = useState(null); // Selected trial period

  const getLabelByValue = (options, value) => {
    const option = options.find((option) => option.value === value);
    return option?.label;
  };

  const handlePermissionsChange = (selectedOption) => {
    setPermissions(selectedOption);
    setRefreshKey((prevKey) => prevKey + 1); // Force AsyncPaginate to reload
  };

  const handleTrialChange = (selectedOption) => {
    setTrial(selectedOption);
  };

  const onChangeImage = (imageList, addUpdateIndex) => {
    setImageList(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (firstName === "") {
        toast.error("First Name is required.", {
          position: "top-center",
        });
        return false;
      }
      if (lastName === "") {
        toast.error("Last Name is required.", {
          position: "top-center",
        });
        return false;
      }
      if (email === "") {
        toast.error("Email is required.", {
          position: "top-center",
        });
        return false;
      }

      if (permissions?.length === 0) {
        toast.error("Permissions are required.", {
          position: "top-center",
        });
        return false;
      }
      if (!trial) {
        toast.error("Trial period is required.", {
          position: "top-center",
        });
        return false;
      }

      const token = getItemFromLocalStorage("TOKEN");

      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);

      permissions.forEach((permission) => {
        formData.append("permissions[]", permission?.name);
      });
      formData.append("email", email);
      formData.append(
        "trialPeriod",
        typeof trial === "object" ? trial.value : trial
      );
      if (featuredImage) formData.append("profileImage", featuredImage);
      if (modalFunction === "Create User") {
        if (!password) {
          toast.error("Password is required.", {
            position: "top-center",
          });
          return false;
        } else {
          if (password?.length < 6) {
            toast.error("Password must be greater than 6.", {
              position: "top-center",
            });
            return false;
          }
          formData.append("password", password);
        }

        console.log("create user formData", Object.fromEntries(formData));
        const { result, error } = await postRequestForm(
          `/api/secure/v2/Admin/create`,
          token,
          formData
        );

        if (result?.status === 200 || result?.status === 201) {
          toast.success("User Created successfully!", {
            position: "top-center",
          });

          onHide();
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
          });
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message || "An error occurred.", {
            position: "top-center",
          });
        }
      } else {
        if (password) {
          if (password?.length < 6) {
            toast.error("Password must be greater than 6.", {
              position: "top-center",
            });
            return false;
          }
          formData.append("password", password);
        }
        formData.append("userId", userId);
        console.log("update user formData", Object.fromEntries(formData));
        const { result, error } = await putRequest(
          `/api/secure/v2/Admin/profile`,
          token,
          formData
        );
        console.log("result, error", result, error);
        if (result?.status === 200 || result?.status === 201) {
          toast.success("User Updated successfully!", {
            position: "top-center",
          });

          onHide();
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
          });
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message || "An error occurred.", {
            position: "top-center",
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchPermisions = async (
    search,
    loadedOptions,
    { page },
    selectedPermissions
  ) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/permission/get-permissions`,
        token,
        {
          page: page,
          search: search,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, permissions } = result?.data;
        console.log("selectedPermissions", selectedPermissions);

        // Check if "Private User" or "Public User" is selected
        const selectedNames = selectedPermissions?.map((p) => p.name) || [];
        let filteredPermissions = permissions;

        if (selectedNames.includes("Private User")) {
          filteredPermissions = permissions.filter(
            (p) => p.name !== "Public User"
          );
        } else if (selectedNames.includes("Public User")) {
          filteredPermissions = permissions.filter(
            (p) => p.name !== "Private User"
          );
        }

        return {
          options: filteredPermissions,
          hasMore:
            currentPage !== totalPages && filteredPermissions?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Permissions Error", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="teams-modal"
    >
      <section id="profileSettings" className="">
        <Container className="list-container">
          <div
            className="d-flex justify-content-between align-items-center pb-2 "
            style={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              marginBottom: "1rem",
            }}
          >
            <h3 className="heading border-0 m-0 p-0">{modalFunction}</h3>
            <Button
              onClick={() => {
                onHide();
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                ":hover": {
                  backgroundColor: "transparent",
                },
                ":focus,:active": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border: "none",
                },
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                color="#fff"
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
          <Row className="align-items-center justify-content-center">
            <Col xs={12}>
              <div className="profile-card v2 bg-transparent p-0 border-0 shadow-none">
                <Row className="align-items-center">
                  <Col
                    lg={4}
                    md={4}
                    xs={12}
                    className="d-flex justify-content-center justify-content-md-start"
                  >
                    <ReactImageUploading
                      value={imageList}
                      onChange={onChangeImage}
                      dataURLKey="data_url"
                      maxNumber={1}
                      maxFileSize={10000000}
                      acceptType={["jpeg", "jpg", "gif", "png"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                      }) => (
                        <>
                          <div className="upload__image-wrapper mb-3">
                            {imageList?.length === 0 ? (
                              <div
                                className="image-item d-flex"
                                style={{
                                  border: "1px solid #ccc",
                                  cursor: "pointer",
                                  width: "120px",
                                  height: "120px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundImage: `url(${
                                    profileImageUrl || Images.profile
                                  })`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundBlendMode: "overlay",
                                  backgroundColor: "rgba(231, 50, 147, .5)",
                                  border: "1px solid rgba(231, 50, 147, 1)",
                                  borderRadius: "50%",
                                  ...(isDragging ? { color: "red" } : {}),
                                }}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  color="#000"
                                  style={{
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                            ) : (
                              imageList?.map((image, index) => (
                                <div key={index} className="image-item">
                                  <LazyLoadImage
                                    src={image["data_url"]}
                                    width={65}
                                    height={65}
                                    style={{ borderRadius: "50%" }}
                                    alt="uploaded"
                                  />
                                  <Button
                                    className="position-absolute"
                                    variant="link"
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </Button>
                                </div>
                              ))
                            )}
                          </div>
                        </>
                      )}
                    </ReactImageUploading>
                  </Col>
                  <Col
                    lg={8}
                    md={8}
                    xs={12}
                    className="text-center text-md-start"
                  >
                    <h3
                      style={{ wordBreak: "break-all" }}
                    >{`${firstName} ${lastName}`}</h3>
                    <p style={{ wordBreak: "break-all" }}> {email}</p>
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>First Name:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>Last Name:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>Email:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      style={{
                        backgroundImage: "unset !important",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>Password:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row className="text-input-container gap-1">
                  <Col xs={12}>
                    <p>Permissions:</p>
                  </Col>
                  <Col xs={12}>
                    <AsyncPaginate
                      key={refreshKey} // Force re-render when key changes
                      value={permissions ? permissions : ""}
                      loadOptions={(search, loadedOptions, additional) =>
                        fetchPermisions(
                          search,
                          loadedOptions,
                          additional,
                          permissions
                        )
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={handlePermissionsChange}
                      placeholder="Select Permissions"
                      isClearable
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Permissions Found"}
                      additional={{ page: 1 }}
                      isMulti
                      isSearchable
                      styles={{
                        menu: (base) => ({
                          ...base,
                          border: 0,
                          backgroundColor: "rgb(164, 207, 255)",
                          top: "auto",
                          bottom: "100%",
                          margin: 0,
                        }),
                        option: (styles, { isFocused, isSelected }) => ({
                          ...styles,
                          backgroundColor: isSelected
                            ? "rgba(16, 14, 52, 1)"
                            : isFocused
                            ? "rgb(164, 207, 255)"
                            : null,
                          color: isSelected ? "white" : "black",
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12}>
                    <p>Session Time:</p>
                  </Col>
                  <Col xs={12}>
                    <Select
                      options={trialOptions}
                      value={trial}
                      onChange={handleTrialChange}
                      placeholder="Select Session Time"
                      styles={{
                        menu: (base) => ({
                          ...base,
                          border: 0,
                          backgroundColor: "rgb(164, 207, 255)",
                          // open upwards
                          top: "auto",
                          // open upwards
                          bottom: "100%",
                          margin: 0,
                        }),
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            backgroundColor: isSelected
                              ? "rgba(16, 14, 52, 1)"
                              : isFocused
                              ? "rgb(164, 207, 255)"
                              : null,
                            color: isSelected ? "white" : "black",
                          };
                        },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="text-input-container border-0">
                  <Col xs={12} className="d-flex justify-content-center ">
                    <Button
                      onClick={handleSaveChanges}
                      variant="primary"
                      className="btn-default w-100"
                      style={{
                        height: "40px",
                        fontSize: "20px",
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        `${modalFunction}`
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Modal>
  );
};

export default AllAdmin;
