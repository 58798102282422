import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Images } from "../Images";

const CANVAS_SIZE = 200;
const DISPLAY_SIZE = 350;
const SCALE_FACTOR = DISPLAY_SIZE / CANVAS_SIZE;

const scoreColors = {
  0: "white",
  1: "blue",
  2: "green",
  3: "yellow",
  4: "orange",
  5: "red",
  6: "red",
};

const PitchMap = ({ handleClose }) => {
  const canvasRef = useRef(null);
  const [selectedScore, setSelectedScore] = useState(null);
  const [showPercentages, setShowPercentages] = useState(true);

  const data = [
    { x: 100.354, y: 162.517, score: 2 },
    { x: 14.909, y: 18.399, score: 4 },
    { x: 11.909, y: 32.183, score: 4 },
    { x: 99.8992919921875, y: 50.95135498046875, score: 2 },
  ];

 useEffect(() => {
   if (!canvasRef.current) return;
   const canvas = canvasRef.current;
   const ctx = canvas.getContext("2d");
   if (!ctx) return;

   const width = canvas.width;
   const height = canvas.height;
   const img = new Image();
   img.src = Images.ballLength;

   img.onload = () => {
     ctx.clearRect(0, 0, width, height);
     ctx.drawImage(img, 0, 0, width, height);

     if (showPercentages) {
       drawSections(ctx, width, height); // Draw sections *without* clearing the canvas again
     }

     // Filter data based on selectedScore
     const filteredData =
       selectedScore !== null
         ? data.filter(({ score }) => score === selectedScore)
         : data;

     // Draw Balls after sections
     filteredData.forEach(({ x, y, score }) => {
       ctx.beginPath();
       ctx.arc(x * SCALE_FACTOR, y * SCALE_FACTOR, 5, 0, 2 * Math.PI);
       ctx.fillStyle = scoreColors[score];
       ctx.fill();
       ctx.stroke();
     });
   };
 }, [selectedScore, showPercentages]);

 const drawSections = (ctx, width, height) => {
   const fullTossEnd = height * 0.1;
   const yorkerEnd = fullTossEnd + height * 0.1;
   const halfVolleyEnd = yorkerEnd + height * 0.1;
   const goodLengthEnd = halfVolleyEnd + height * 0.11;
   const shortOfGoodEnd = goodLengthEnd + height * 0.1;
   const shortEnd = height; // Remaining 50% is short

   const sections = [
     { label: "Full Toss", end: fullTossEnd },
     { label: "Yorker", end: yorkerEnd },
     { label: "Half Volley", end: halfVolleyEnd },
     { label: "Good Length", end: goodLengthEnd },
     { label: "Short of Good", end: shortOfGoodEnd },
     { label: "Short", end: shortEnd },
   ];

   // Count how many balls fall into each section
   const sectionCounts = Array(sections.length).fill(0);
   data.forEach(({ y }) => {
     let scaledY = y * SCALE_FACTOR; // Scale the y-coordinate properly
     for (let i = 0; i < sections.length; i++) {
       if (scaledY <= sections[i].end) {
         sectionCounts[i]++;
         break;
       }
     }
   });

   const totalShots = data.length || 1; // Avoid division by zero

   ctx.strokeStyle = "black";
   ctx.lineWidth = 2;
   ctx.fillStyle = "black";
   ctx.font = "14px Arial";
   ctx.textAlign = "center";

   let prevY = 0;
   sections.forEach(({ label, end }, i) => {
     const percentage = ((sectionCounts[i] / totalShots) * 100).toFixed(2);

     // Overlay semi-transparent sections on top of the image
     ctx.fillStyle = `rgba(255, 255, 255, 0.5)`;
     ctx.fillRect(0, prevY, width, end - prevY);
     ctx.strokeRect(0, prevY, width, end - prevY);

     // Draw text labels
     ctx.fillStyle = "black";
     ctx.fillText(label, width / 2, (prevY + end) / 2);
     ctx.fillText(`${percentage}%`, width / 2, end - 5);

     prevY = end;
   });
 };



  return (
    <section
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
        padding: "60px",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>

        <h2 className="text-center text-light pb-4">Pitch Map</h2>

        <div className="d-flex">
          <canvas
            ref={canvasRef}
            width={DISPLAY_SIZE}
            height={DISPLAY_SIZE}
            style={{ border: "1px solid black" }}
          />

          <div className="d-flex gap-3 flex-column ms-5">
            {Object.entries(scoreColors).map(([score, color]) => (
              <label
                key={score}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <input
                  type="radio"
                  name="score"
                  value={score}
                  checked={parseInt(score) === selectedScore}
                  onChange={() => setSelectedScore(parseInt(score))}
                  style={{
                    appearance: "none",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: color,
                    border:
                      selectedScore == score
                        ? "3px solid black"
                        : "2px solid transparent",
                  }}
                />
                <span className="text-light">{score}</span>
              </label>
            ))}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input
                type="radio"
                name="score"
                value={"null"}
                checked={selectedScore === null}
                onChange={() => setSelectedScore(null)}
                style={{
                  appearance: "none",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  border:
                    selectedScore == null
                      ? "3px solid black"
                      : "2px solid transparent",
                }}
              />
              <span className="text-light">All</span>
            </label>

            {/* Switch for Showing Percentages */}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                checked={showPercentages}
                onChange={() => setShowPercentages((prev) => !prev)}
                style={{
                  appearance: "none",
                  width: "40px",
                  height: "20px",
                  borderRadius: "10px",
                  backgroundColor: showPercentages ? "green" : "gray",
                  cursor: "pointer",
                }}
              />
              <span className="text-light">
                {showPercentages ? "Show Percentages" : "Only Balls"}
              </span>
            </label>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default PitchMap;
