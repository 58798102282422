import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { deleteRequest, getRequest, postRequestForm, uploadURL } from "../../../helper/api";
import PairButtons from "../../../constants/PairButtons";
import { Images } from "../../../constants/Images";
import BackButton from "../../../constants/BackButton";
import Layout from "../../../layouts/Layout";

const TeamSelection = () => {
  const navigate = useNavigate();

  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch teams from API
  const fetchTeams = async () => {
    try {
      setLoading(true);
      const { result, error } = await getRequest(`/api/secure/team/get-teams`);
      if (result?.status === 200) {
        const teams = result.data.teams.map((team) => ({
          ...team,
          selected: false, // Add selected field for checkbox handling
        }));

        // Pre-select teams if editing an existing tournament
        if (localStorage.getItem("tournamentId") !== null) {
          const preselected = teams.map((team) => ({
            ...team,
            // selected: tournamentTeams.includes(team._id),
          }));
          setAvailableTeams(preselected);
        } else {
          setAvailableTeams(teams);
        }
        setLoading(false);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch teams"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  // Handle checkbox change
  const toggleTeamSelection = (teamId) => {
    setAvailableTeams((prevTeams) =>
      prevTeams.map((team) =>
        team._id === teamId ? { ...team, selected: !team.selected } : team
      )
    );
  };

  // Handle Submit
  const handleSubmit = async () => {
    const selectedTeamIds = availableTeams
      .filter((team) => team.selected)
      .map((team) => team._id);

    if (selectedTeamIds.length < 2) {
      toast.error("Please select at least two teams.", {
        position: "top-center",
      });
      return;
    }

    const payload = {
      tournamentName: JSON.parse(localStorage.getItem("tournamentName")),
      tournamentType: JSON.parse(localStorage.getItem("tournamentType")),
      startDate: JSON.parse(localStorage.getItem("startDate")),
      endDate: JSON.parse(localStorage.getItem("endDate")),
      tournamentTeams: selectedTeamIds, // Array of team IDs
    };

    try {
      const apiEndpoint =
        JSON.parse(localStorage.getItem("tournamentId")) !== ""
          ? `/api/secure/tournament/edit-tournament`
          : `/api/secure/tournament/register-tournament`;

      const { result, error } = await postRequestForm(apiEndpoint, "", payload);

      if (result?.status === 200 || result?.status === 201) {
        const message = localStorage.getItem("tournamentId")
          ? "Tournament updated successfully."
          : "Tournament registered successfully.";
        toast.success(message, { position: "top-center" });

        // Navigate to Tournament Overview or other appropriate page
        localStorage.removeItem("tournamentId");
        localStorage.removeItem("tournamentName");
        localStorage.removeItem("tournamentType");
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
        localStorage.removeItem("players");
        navigate("/start-match");
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to save tournament"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  // Handle Delete
  const handleDelete = async (teamId) => {
    try {
      const { result, error } = await deleteRequest(
        `/api/secure/team/remove-team?_id=${teamId}`
      );
      if (result?.status === 200) {
        toast.success("Team deleted successfully.", { position: "top-center" });
        fetchTeams();
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to delete team"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const SkeletonLoader = () => (
    <div className="item-container mb-4">
      {Array(4)
        .fill()
        .map((_, index) => (
          <div className="list-item" key={index}>
            <div className="item-content">
              <Skeleton circle width={40} height={40} />
              <Skeleton
                width={150}
                height={20}
                style={{ marginLeft: "10px" }}
              />
            </div>
            <div className="item-buttons">
              <Skeleton
                width={60}
                height={30}
                style={{ marginRight: "10px" }}
              />
              <Skeleton
                width={60}
                height={30}
                style={{ marginRight: "10px" }}
              />
              <Skeleton width={60} height={30} />
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <Layout>
      <section
        id="teamSelection"
        style={{
          // backgroundImage: `url(${Images.background2})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        <BackButton
          icon={Images.backArrow}
          content="Back"
          onClick={() => {
            if (localStorage.getItem("tournamentName") === "" || null) {
              navigate("/dashboard");
            } else {
              navigate("/create-tournament");
            }
          }}
        />
        <div className="logo-container my-5">
          <LazyLoadImage src={Images.logo} width={100} alt="logo" />
        </div>
        <Container className="list-container">
          <h3 className="pb-2 heading">Already Registered Teams</h3>
          <p>Select teams that will participate in this tournament.</p>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <div className="item-container mb-4">
              {availableTeams.map((team) => (
                <div className="list-item" key={team._id}>
                  <div className="item-content">
                    <Form.Check
                      type="checkbox"
                      id={team._id}
                      checked={team.selected}
                      onChange={() => toggleTeamSelection(team._id)}
                    />
                    <LazyLoadImage
                      src={uploadURL + team.teamLogo}
                      className="flag"
                      height={50}
                      width={50}
                    />
                    <p className="team-name">{team.teamName}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          <PairButtons
            content1="Next"
            onClick1={handleSubmit}
            icon1={Images.nextArrow}
            backgroundColor1={"#fff"}
            color1={"#000"}
            reversedIcon1={true}
          />
        </Container>
      </section>
    </Layout>
  );
};

export default TeamSelection;
