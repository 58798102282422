import React from "react";
import { max } from "underscore";

const VariationFast = ({
  heading,
  secHeight,
  secWidth,
  variation,
  isVariation,
  setIsVariation,
  selectedValue,
  setSelectedValue,
  variationPace,
  setVariationPace,
}) => {
  const buttonStyles = {
    color: "white",
    height: "auto",
    width: "100%",
    border: "none",
    borderRadius: "5px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.6rem",
    padding: "1px 5px ",
  };

  return (
    <div
      className="heading-options-line"
      style={{
        height: secHeight,
        width: secWidth,
      }}
    >
      <p className="d-flex align-items-center justify-content-around heading-options-line-p">
        {heading}
        <label
          style={{ background: isVariation ? "#e7e7e7" : "transparent" }}
          className="react-switch"
        >
          <input
            checked={isVariation}
            onChange={() => setIsVariation(!isVariation)}
            className="react-switch-checkbox"
            type="checkbox"
          />
          <div className="react-switch-button" />
          <div
            className="react-switch-labels"
            style={{
              backgroundColor: isVariation ? "#be8202" : "red",
              borderRadius: "50px",
            }}
          >
            <span style={{ color: isVariation ? "#fff" : "red" }}>SPIN</span>
            <span style={{ color: !isVariation ? "#fff" : "#be8202" }}>
              PACE
            </span>
          </div>
        </label>
      </p>

      {/* Buttons Section */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "2px",
        }}
      >
        <span className="d-flex justify-content-center gap-2">
          <button
            style={{
              backgroundColor:
                variationPace === "Pace On"
                  ? "rgba(231, 50, 147, 1)"
                  : "rgba(98, 97, 141, 1)",
              ...buttonStyles,
              width: "auto",
            }}
            onClick={() => setVariationPace("Pace On")}
          >
            Pace On
          </button>
          <button
            style={{
              backgroundColor:
                variationPace === "Pace Off"
                  ? "rgba(231, 50, 147, 1)"
                  : "rgba(98, 97, 141, 1)",
              ...buttonStyles,
              width: "auto",
            }}
            onClick={() => setVariationPace("Pace Off")}
          >
            Pace Off
          </button>
        </span>
        <button
          style={{
            backgroundColor:
              selectedValue === "No Movement"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("No Movement")}
        >
          No Movement
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Slower Ball"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Slower Ball")}
        >
          Slower Ball
        </button>
        <span className="d-flex justify-content-center gap-2">
          <button
            style={{
              backgroundColor:
                selectedValue === "In Swing"
                  ? "rgba(231, 50, 147, 1)"
                  : "rgba(98, 97, 141, 1)",
              ...buttonStyles,
              width: "auto",
            }}
            onClick={() => setSelectedValue("In Swing")}
          >
            In Swing
          </button>
          <button
            style={{
              backgroundColor:
                selectedValue === "Out Swing"
                  ? "rgba(231, 50, 147, 1)"
                  : "rgba(98, 97, 141, 1)",
              ...buttonStyles,
              width: "auto",
            }}
            onClick={() => setSelectedValue("Out Swing")}
          >
            Out Swing
          </button>
        </span>
        <button
          style={{
            backgroundColor:
              selectedValue === "Reverse Swing"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Reverse Swing")}
        >
          Reverse Swing
        </button>
        <span className="d-flex justify-content-center gap-2">
          <button
            style={{
              backgroundColor:
                selectedValue === "Angle In"
                  ? "rgba(231, 50, 147, 1)"
                  : "rgba(98, 97, 141, 1)",
              ...buttonStyles,
              width: "auto",
            }}
            onClick={() => setSelectedValue("Angle In")}
          >
            Angle In
          </button>
          <button
            style={{
              backgroundColor:
                selectedValue === "Angle Out"
                  ? "rgba(231, 50, 147, 1)"
                  : "rgba(98, 97, 141, 1)",
              ...buttonStyles,
              width: "auto",
            }}
            onClick={() => setSelectedValue("Angle Out")}
          >
            Angle Out
          </button>
        </span>
        <button
          style={{
            backgroundColor:
              selectedValue === "Off-Cutter"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Off-Cutter")}
        >
          Off-Cutter
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Leg-Cutter"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Leg-Cutter")}
        >
          Leg-Cutter
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Knuckle Ball"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Knuckle Ball")}
        >
          Knuckle Ball
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Back of the Hand"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Back of the Hand")}
        >
          Back of the Hand
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Bouncer"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Bouncer")}
        >
          Bouncer
        </button>
        <button
          style={{
            backgroundColor:
              selectedValue === "Beamer"
                ? "rgba(231, 50, 147, 1)"
                : "rgba(98, 97, 141, 1)",
            ...buttonStyles,
          }}
          onClick={() => setSelectedValue("Beamer")}
        >
          Beamer
        </button>
      </div>
    </div>
  );
};

export default VariationFast;
