import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { head } from "underscore";

const OptionCard = ({
  heading,
  options,
  selectedValue,
  setSelectedValue,
  secHeight,
  secWidth,
  variation,
  isVariation,
  setIsVariation,
  btnWidth,
  btnHeight,
  btnFontSize,
  gap,
}) => {
  return (
    <div
      className="heading-options-line"
      style={{
        height: secHeight,
        width: secWidth,
      }}
    >
      {variation === "true" ? (
        <p className="d-flex align-items-center justify-content-around heading-options-line-p">
          {heading}
          <label
            style={{ background: isVariation && "#e7e7e7" }}
            className="react-switch"
          >
            <input
              checked={isVariation}
              onChange={() => setIsVariation(!isVariation)}
              className="react-switch-checkbox"
              type="checkbox"
            />
            <div className="react-switch-button" />
            <div
              className="react-switch-labels"
              style={{
                backgroundColor: isVariation ? "#be8202" : "red",
                borderRadius: "50px",
              }}
            >
              <span
                style={{
                  color: isVariation ? "#fff" : "red",
                }}
              >
                SPIN
              </span>
              <span
                style={{
                  color: !isVariation ? "#fff" : "#be8202",
                }}
              >
                PACE
              </span>
            </div>
          </label>
        </p>
      ) : (
        <p className="heading-options-line-p" style={{ fontSize: "0.7rem" }}>
          {heading}
        </p>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center", // Align buttons from left to right
          alignItems: "flex-start", // Remove unnecessary vertical space
          gap: gap, // Minimal spacing between buttons
        }}
      >
        {options.map((option) => (
          <button
            key={option.value}
            style={{
              backgroundColor:
                option.value === selectedValue
                  ? "rgba(231, 50, 147, 1)" // Highlight selected button
                  : "rgba(98, 97, 141, 1)", // Default background for other buttons
              color: "white",
              minWidth: "min-content",
              height: btnHeight,
              width: btnWidth,
              border: "none",
              borderRadius: "5px",
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: btnFontSize,
            }}
            onClick={() =>
              setSelectedValue(
                option.value === selectedValue ? null : option.value
              )
            }
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OptionCard;
