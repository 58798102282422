import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SingleButton from "./SingleButton";

const WicketModal = ({
  handleClose,
  matchDetails,
  fielder,
  newBatsman,
  setNewBatsman,
  dismissedBatsman,
  setDismissedBatsman,
  setFielder,
  out,
}) => {
  const handleSave = () => {
    if (!newBatsman) {
      alert("Please select a new batsman.");
      return;
    }

    handleClose();
  };

  return (
    <section
      id="selectBowlerModal"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
          />
        </div>
        <h1 className="match-scoring-modal-heading">
          Select Striker & Non-Striker
        </h1>
        <div className="d-flex justify-content-end text-light pe-5 mb-3">
          <Form.Check
            type="radio"
            label={`Striker: ${matchDetails?.match?.facingBatsman?.player?.playerName}`}
            name="batsman"
            value="striker"
            checked={dismissedBatsman === "striker"}
            onChange={(e) => setDismissedBatsman(e.target.value)}
          />
          <Form.Check
            type="radio"
            label={`Non-Striker: ${matchDetails?.match?.nonStriker?.player?.playerName}`}
            name="batsman"
            value="nonStriker"
            checked={dismissedBatsman === "nonStriker"}
            onChange={(e) => setDismissedBatsman(e.target.value)}
          />
        </div>

        <Form.Label>Select New Batsman</Form.Label>
        <Form.Select
          value={newBatsman}
          onChange={(e) => setNewBatsman(e.target.value)}
        >
          <option value="">-- Select Batsman --</option>
          {matchDetails?.match?.teams[0]?.selectedPlayers
            /* ?.filter(
              (player) =>
                player.id !== dismissedBatsman
            ) */
            .map((player) => (
              <option key={player.player?._id} value={player.player?._id}>
                {player.player?.playerName}
              </option>
            ))}
        </Form.Select>
        {out === "Caught" || out === "Run Out" || out === "Stumped" ? (
          <Form.Select
            value={fielder}
            onChange={(e) => setFielder(e.target.value)}
          >
            <option value="">-- Select Fielder --</option>
            {matchDetails?.match?.teams[1]?.selectedPlayers.map((player) => (
              <option key={player.player?._id} value={player.player?._id}>
                {player.player?.playerName}
              </option>
            ))}
          </Form.Select>
        ) : null}

        <div className="d-flex flex-column align-items-center">
          <SingleButton
            content={"Undo Last Ball"}
            onClick={() => console.log("Undo Last Ball")}
            backgroundColor={"#fff"}
            color={"#000"}
            buttonStyles={{
              width: "200px",
              marginTop: "20px",
            }}
          />
          <SingleButton
            content={"Save"}
            onClick={handleSave}
            backgroundColor={"rgba(231, 50, 147, 1)"}
            color={"#fff"}
            buttonStyles={{
              width: "200px",
              marginTop: "10px",
            }}
          />
        </div>
      </Container>
    </section>
  );
};

export default WicketModal;
