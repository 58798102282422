import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const ProfileCardSkeleton = () => {
  return (
    <div className="profile-card">
      {/* Profile Image and Details */}
      <Row className="align-items-center">
        <Col
          lg={4}
          md={4}
          xs={12}
          className="d-flex justify-content-center justify-content-md-start"
        >
          <Skeleton circle width={120} height={120} />
        </Col>
        <Col lg={8} md={8} xs={12} className="text-center text-md-start">
          <Skeleton width={200} height={20} style={{ marginBottom: "10px" }} />
          <Skeleton width={150} height={10} />
        </Col>
      </Row>

      {/* Input Fields */}
      <Row className="text-input-container">
        <Col xs={12} sm={5}>
          <Skeleton width={100} height={10} />
        </Col>
        <Col xs={12} sm={7}>
          <Skeleton height={20} />
        </Col>
      </Row>
      <Row className="text-input-container">
        <Col xs={12} sm={5}>
          <Skeleton width={100} height={10} />
        </Col>
        <Col xs={12} sm={7}>
          <Skeleton height={20} />
        </Col>
      </Row>
      <Row className="text-input-container">
        <Col xs={12} sm={5}>
          <Skeleton width={100} height={10} />
        </Col>
        <Col xs={12} sm={7}>
          <Skeleton height={20} />
        </Col>
      </Row>

      <Row className="text-input-container">
        <Col xs={12} sm={5}>
          <Skeleton width={100} height={10} />
        </Col>
        <Col xs={12} sm={7}>
          <Skeleton height={20} />
        </Col>
      </Row>
      {/* Save Button */}
      <Row className="text-input-container border-0">
        <Col xs={12} className="d-flex justify-content-center">
          <Skeleton width="100%" height={20} />
        </Col>
      </Row>
    </div>
  );
};

export default ProfileCardSkeleton;
