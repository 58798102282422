import logo from "../assets/img/Logo.png";
import background from "../assets/img/background.webp";
import background2 from "../assets/img/background2.webp";
import start from "../assets/img/start.svg";
import lock from "../assets/img/lock.svg";
import user from "../assets/img/user.svg";
import previous from "../assets/img/previous.svg";
import create from "../assets/img/create.svg";
import stats from "../assets/img/stats.svg";
import history from "../assets/img/history.svg";
import mail from "../assets/img/mail.svg";
import profile from "../assets/img/profile.svg";
import signout from "../assets/img/signout.svg";
import settings from "../assets/img/settings.svg";
import help from "../assets/img/help.svg";
import upgrade from "../assets/img/upgrade.svg";
import backArrow from "../assets/img/backArrow.svg";
import plus from "../assets/img/plus.svg";
import backArrowButton from "../assets/img/back-arrow-button.svg";
import userIcon from "../assets/img/userIcon.svg";
import calendar from "../assets/img/calendar.svg";
import tournamentType from "../assets/img/tournamentType.svg";
import nextArrow from "../assets/img/nextArrow.svg";
import babarAzam from "../assets/img/babarAzam.png";
import searchIcon from "../assets/img/searchIcon.svg";
// import pitch from "../assets/img/pitch.png";
// import stadium from "../assets/img/stadium.png";
// import ballHit from "../assets/img/ballHit.png";
import pinkBall from "../assets/img/pinkBall.svg";
import pinkBat from "../assets/img/pinkBat.svg";
import matchIcon from "../assets/img/matchIcon.svg";
import location from "../assets/img/location.svg";
import trophyIcon from "../assets/img/trophyIcon.svg";
import summaryCalendar from "../assets/img/summaryCalendar.svg";
import summaryToss from "../assets/img/summaryToss.svg";
import summaryLocation from "../assets/img/summaryLocation.svg";
import summaryTournament from "../assets/img/summaryTournament.svg";
import summaryRefree from "../assets/img/summaryRefree.svg";
import reportReset from "../assets/img/reportReset.svg";
import reportGenerate from "../assets/img/reportGenerate.svg";
import whiteNextArrow from "../assets/img/whiteNextArrow.svg";
import background3 from "../assets/img/background3.webp";
import playerManagment from "../assets/img/playerManagement.svg";
import teamManagment from "../assets/img/teamManagement.svg";
import userManagement from "../assets/img/userManagement.svg";
import adminManagement from "../assets/img/adminManagement.svg";
import tournamentManagement from "../assets/img/tournamentManagement.svg";
import hamburgerButton from "../assets/img/Hamburger Button.png";
import startCamera from "../assets/img/open-camera.png";
import startRecord from "../assets/img/start-record.png";
import stopRecord from "../assets/img/stop-record.png";
import ballLine from "../assets/img/ball-line.png";
import ballLength from "../assets/img/ball-length.png";
import wagonWheel from "../assets/img/wagon-wheel.png";
import ballColorFrame from "../assets/img/colors-ball-frame.png"
import recording from "../assets/img/recording.png";
import Undo from "../assets/img/Undo.png";
import Redo from "../assets/img/Redo.png";



export const Images = {
  logo,
  background,
  background2,
  start,
  lock,
  user,
  previous,
  create,
  stats,
  history,
  mail,
  profile,
  signout,
  settings,
  help,
  upgrade,
  backArrow,
  plus,
  backArrowButton,
  userIcon,
  calendar,
  tournamentType,
  nextArrow,
  hamburgerButton,
  babarAzam,
  searchIcon,
  // pitch,
  // stadium,
  // ballHit,
  pinkBall,
  pinkBat,
  matchIcon,
  location,
  trophyIcon,
  summaryCalendar,
  summaryToss,
  summaryLocation,
  summaryTournament,
  summaryRefree,
  reportReset,
  reportGenerate,
  whiteNextArrow,
  background3,
  playerManagment,
  teamManagment,
  userManagement,
  adminManagement,
  tournamentManagement,
  startCamera,
  startRecord,
  stopRecord,
  ballLine,
  ballLength,
  wagonWheel,
  ballColorFrame,
  recording,
  Undo,
  Redo
};
