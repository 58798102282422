import React, { useEffect, useState } from "react";
import { Images } from "../../constants/Images";
import BackButton from "../../constants/BackButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import WormChart from "../../constants/Charts/WormChat";
import BarChart from "../../constants/Charts/BarChart";
import ComparisonBarChart from "../../constants/Charts/ComparisonBarChart";
import PieChart from "../../constants/Charts/PieChart";
import { getItemFromLocalStorage } from "../../helper/helper";
import { getRequest } from "../../helper/api";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MatchSummary = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  let matchId;

  if (state.matchId) {
    matchId = state.matchId;
  } else {
    navigate(-1);
  }

  const [matchInfo, setMatchInfo] = useState({});
  const [matchInnings, setMatchInnings] = useState([]);

  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);

  const columns1 = [
    {
      name: "Batting",
      selector: (row) => row.batting,
      sortable: true,
      minWidth: "300px", // Wider column for Format
    },

    {
      name: "Balls",
      selector: (row) => row.balls,
      sortable: true,
      center: true,
    },
    {
      name: "Dots",
      selector: (row) => row.dots,
      sortable: true,
      center: true,
    },
    {
      name: "Balls",
      selector: (row) => row.balls,
      sortable: true,
      center: true,
    },
    {
      name: "4s",
      selector: (row) => row.fours,
      sortable: true,
      center: true,
    },
    {
      name: "6s",
      selector: (row) => row.sixes,
      sortable: true,
      center: true,
    },
    {
      name: "S/R",
      selector: (row) => row.sr,
      sortable: true,
      center: true,
    },
  ];

  const columns2 = [
    {
      name: "Bowling",
      selector: (row) => row.bowling,
      sortable: true,
      minWidth: "300px", // Wider column for Format
    },
    {
      name: "Overs",
      selector: (row) => row.overs,
      sortable: true,
      center: true,
    },
    {
      name: "Runs Conceded",
      selector: (row) => row.runs,
      sortable: true,
      center: true,
    },
    {
      name: "Dots",
      selector: (row) => row.dots,
      sortable: true,
      center: true,
    },
    {
      name: "Wickets",
      selector: (row) => row.wickets,
      sortable: true,
      center: true,
    },
    {
      name: "Maiden",
      selector: (row) => row.maiden,
      sortable: true,
      center: true,
    },
    {
      name: "ECON",
      selector: (row) => row.economy,
      sortable: true,
      center: true,
    },
  ];

  const columns3 = [
    {
      name: "Start Overs",
      selector: (row) => row.startOvers,
      sortable: true,
      minWidth: "500px",
    },
    {
      name: "End Overs",
      selector: (row) => row.endOvers,
      sortable: true,
      center: true,
    },
    {
      name: "Start Runs",
      selector: (row) => row.startRuns,
      sortable: true,
      center: true,
    },
    {
      name: "End Runs",
      selector: (row) => row.endRuns,
      sortable: true,
      center: true,
    },
  ];

  useEffect(() => {
    // Populate dummy data
    const dummyData1 = [
      {
        id: 1,
        batting: "Babar Azam",
        runs: 50,
        balls: 10,
        fours: 2,
        sixes: 1,
        dots: 5,
        sr: 50.0,
      },
      {
        id: 2,
        batting: "Virat Kohli",
        runs: 60,
        balls: 10,
        fours: 2,
        sixes: 1,
        dots: 5,
        sr: 60.0,
      },
      {
        id: 3,
        batting: "Rohit Sharma",
        runs: 70,
        balls: 10,
        fours: 2,
        dots: 5,
        sixes: 1,
        sr: 70.0,
      },
      {
        id: 4,
        batting: "Shikhar Dhawan",
        runs: 80,
        balls: 10,
        fours: 2,
        dots: 5,
        sixes: 1,
        sr: 80.0,
      },

      // Add more dummy data as needed
    ];

    const dummyData2 = [
      {
        id: 1,
        bowling: "Babar Azam",
        overs: 10,
        runs: 50,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
      {
        id: 2,
        bowling: "Virat Kohli",
        overs: 10,
        runs: 60,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
      {
        id: 3,
        bowling: "Rohit Sharma",
        overs: 10,
        runs: 70,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
      {
        id: 4,
        bowling: "Shikhar Dhawan",
        overs: 10,
        runs: 80,
        wickets: 1,
        maiden: 0,
        economy: 10.0,
      },
    ];
    const dummyData3 = [
      {
        id: 1,
        startOvers: "Babar Azam",
        endOvers: "50",
        startRuns: 10,
        endRuns: 20,
      },
    ];
    setTableData1(dummyData1);
    setTableData2(dummyData2);
    setTableData3(dummyData3);
  }, []);

  const getMatchInfo = async (matchId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/MatchSummary/get-match-info`,
        token,
        { matchId }
      );
      if (result?.status === 200) {
        console.log("API match summary", result.data);
        setMatchInfo(result.data);
      } else {
        throw new Error(error?.message || "Error while fetching match summary");
      }
    } catch (error) {
      console.error("Error while fetching match summary", error);
    }
  };

  const getMatchInnings = async (matchId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/MatchSummary/get-match-innings`,
        token,
        { matchId }
      );
      if (result?.status === 200) {
        console.log("API match inning", result.data);
        setMatchInnings(result.data);
      } else {
        throw new Error(error?.message || "Error while fetching match summary");
      }
    } catch (error) {
      console.error("Error while fetching match summary", error);
    }
  };

  const getHighestScore = async (matchId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/v2/MatchSummary/get-match-highest-run-in-over`,
        token,
        { matchId }
      );
      if (result?.status === 200) {
        console.log("API match inning", result.data);
        setMatchInnings(result.data);
      } else {
        throw new Error(error?.message || "Error while fetching match summary");
      }
    } catch (error) {
      console.error("Error while fetching match summary", error);
    }
  };

  useEffect(() => {
    if (matchId) {
      getMatchInfo(matchId);
      getMatchInnings(matchId);
      getHighestScore(matchId);
    } else {
      console.log("No match id found");
    }
  }, [matchId]);

  return (
    <section
      id="matchSummary"
      style={{
        // backgroundImage: `url(${Images.background2})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
      }}
    >
      <BackButton
        content={"Back"}
        onClick={() => {
          navigate("/dashboard");
        }}
        icon={Images.backArrow}
      />
      <Container>
        <LazyLoadImage
          src={Images.logo}
          width={100}
          style={{ display: "block", margin: "auto" }}
          alt="Logo"
        />
        <h1 className="heading1 text-center text-light">
          Match Analysis Summary
        </h1>

        {matchInfo?.teams?.length > 1 && (
          <section>
            <div className="m-auto mt-4 d-flex justify-content-center align-items-center text-light">
              <div className="text-center">
                <LazyLoadImage
                  src={matchInfo?.teams[0]?.flag}
                  alt="Logo"
                  width={70}
                  height={70}
                  style={{ borderRadius: "100%" }}
                />
                <h2 className="heading2 text-center">
                  {matchInfo?.teams[0]?.name}
                </h2>
              </div>
              <div>
                <h2 className="heading2 text-center mt-0 mx-5">VS</h2>
              </div>
              <div className="text-center">
                <LazyLoadImage
                  src={matchInfo?.teams[1]?.flag}
                  alt="Logo"
                  width={70}
                  height={70}
                  style={{ borderRadius: "100%" }}
                />
                <h2 className="heading2 text-center">
                  {matchInfo?.teams[1]?.name}
                </h2>
              </div>
            </div>
            <Row className="g-auto mt-5">
              <Col
                lg={6}
                className="d-flex flex-column justify-content-between"
              >
                <div className="score-card p-4 bg-light">
                  <div
                    className="d-flex justify-content-between align-items-center pb-3"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <span className="d-flex align-items-center">
                      <LazyLoadImage
                        src={matchInfo?.teams[0]?.flag}
                        alt="Logo"
                        width={48}
                        height={48}
                        style={{
                          borderRadius: "100%",
                          border: "1px solid #000",
                        }}
                      />
                      <h2 className="heading2 d-flex align-items-baseline mt-0 ms-3 text-black">
                        {matchInfo?.teams[0]?.score}{" "}
                        <p className="m-0 fs-6 fw-light">
                          {" "}
                          ({matchInfo?.teams[0]?.overs})
                        </p>
                      </h2>
                    </span>
                    <p
                      className="m-0 fs-4"
                      style={{ color: "rgba(231, 50, 147, 1)" }}
                    >
                      vs
                    </p>
                    <span className="d-flex align-items-center">
                      <LazyLoadImage
                        src={matchInfo?.teams[1]?.flag}
                        alt="Logo"
                        width={48}
                        height={48}
                        style={{
                          borderRadius: "100%",
                          border: "1px solid #000",
                        }}
                      />
                      <h2 className="heading2 d-flex align-items-baseline mt-0 ms-3 text-black">
                        {matchInfo?.teams[1]?.score}{" "}
                        <p className="m-0 fs-6 fw-light">
                          {" "}
                          ({matchInfo?.teams[1]?.overs})
                        </p>
                      </h2>
                    </span>
                  </div>
                  <p
                    className="m-0 fs-5 fw-bolder text-center mt-3"
                    style={{ color: "rgba(231, 50, 147, 1)" }}
                  >
                    {matchInfo.result}
                  </p>
                </div>
                <div className="score-card text-light p-4 mt-3">
                  <Row className="align-items-center">
                    <Col lg={4}>
                      <h1 className="heading1 mt-0">Player of The Match</h1>
                    </Col>
                    <Col lg={3} className="text-center">
                      <LazyLoadImage
                        src={matchInfo.playerOfTheMatch.playerImageUrl}
                        alt="Logo"
                        width={95}
                        height={95}
                        style={{ borderRadius: "100%" }}
                      />
                    </Col>
                    <Col lg={5}>
                      <h3 className="heading3 player-of-match m-0">
                        {matchInfo.playerOfTheMatch.playerName}:
                        <p className="m-0 mt-2 fw-bold">Batting:</p>
                        <p className="m-0 fw-light mt-1">
                          {matchInfo.playerOfTheMatch.stats.batting}
                        </p>
                        <p className="m-0 mt-2 fw-bold">Bowling:</p>
                        <p className="m-0  fw-light mt-1">
                          {matchInfo.playerOfTheMatch.stats.bowling}
                        </p>
                      </h3>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div className="m-auto ms-2 match-description-container px-4 py-2">
                  <h1 className="heading2 text-black">Match Information</h1>
                  <span className="description-line2 d-flex align-items-center">
                    <LazyLoadImage
                      src={Images.summaryTournament}
                      alt="Logo"
                      width={40}
                      height={40}
                      style={{ borderRadius: "100%", border: "1px solid #000" }}
                    />
                    <h3 className="heading3 ms-3 text-black">
                      Tournament{" "}
                      <p className="text-black">{matchInfo.tournament}</p>
                    </h3>
                  </span>
                  <span className="description-line2 d-flex align-items-center">
                    <LazyLoadImage
                      src={Images.summaryCalendar}
                      alt="Logo"
                      width={40}
                      height={40}
                      style={{ borderRadius: "100%", border: "1px solid #000" }}
                    />
                    <h3 className="heading3 ms-3 text-black">
                      Match Date{" "}
                      <p className="text-black">
                        {moment(matchInfo?.createdAt).format("DD-MM-YYYY")}
                      </p>
                    </h3>
                  </span>
                  <span className="description-line2 d-flex align-items-center">
                    <LazyLoadImage
                      src={Images.summaryToss}
                      alt="Logo"
                      width={40}
                      height={40}
                      style={{ borderRadius: "100%", border: "1px solid #000" }}
                    />
                    <h3 className="heading3 ms-3 text-black">
                      Toss <p className="text-black">{matchInfo.tossWonBy}</p>
                    </h3>
                  </span>
                  <span className="description-line2 d-flex align-items-center">
                    <LazyLoadImage
                      src={Images.summaryLocation}
                      alt="Logo"
                      width={40}
                      height={40}
                      style={{ borderRadius: "100%", border: "1px solid #000" }}
                    />
                    <h3 className="heading3 ms-3 text-black">
                      Venue <p className="text-black">{matchInfo.venue}</p>
                    </h3>
                  </span>
                </div>
              </Col>
            </Row>
          </section>
        )}
        {matchInnings.length > 0 && (
          <section>
            {matchInnings.map((matchInning, index) => (
              <div key={index}>
                <h2 className="heading1 text-start mt-5 mb-3 text-light">
                  {matchInning?.teamName} - {index === 1 ? "2nd" : "1st"}{" "}
                  Innings
                </h2>
                <div className="table-container text-black">
                  <div
                    className="d-flex justify-content-between pb-3 mb-3"
                    style={{ borderBottom: "1px solid #000" }}
                  >
                    <p className="heading2 mt-0">
                      {matchInning?.teamName} - {index === 1 ? "2nd" : "1st"}{" "}
                      Innings
                    </p>
                    <h2 className="heading2 d-flex align-items-baseline mt-0 ms-3">
                      {matchInning?.runs}/{matchInning?.wickets}
                      <p className="m-0 fs-6 fw-light">
                        ({matchInning?.oversPlayed})
                      </p>
                    </h2>
                  </div>
                  <DataTable
                    columns={columns1}
                    data={matchInning?.battingStats}
                    // pagination
                  />
                  <div className="d-flex mt-2 fw-bolder">
                    <p className=" mt-0 me-5">Extras</p>
                    <p className=" d-flex align-items-baseline mt-0">
                      {matchInning?.extras?.total} ({matchInning?.extras?.noBalls}
                      NB, {matchInning?.extras?.wides}WD)
                    </p>
                  </div>
                  {/*
              <p className=" m-0 fw-bold">Fall of Wickets</p>
              <p
                className=" d-flex align-items-baseline m-0 pb-2 mb-3"
                style={{ borderBottom: "1px solid #fff" }}
              >
                1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1 - 25(Rohit
                Sharma), 1 - 25(Rohit Sharma), 1 - 25(Rohit Sharma), 1 -
                25(Rohit Sharma)
              </p> */}
                  <DataTable
                    columns={columns2}
                    data={matchInning?.bowlingStats}
                    // pagination
                  />
                </div>
              </div>
            ))}
          </section>
        )}

        {/* <h1 className="heading1 text-center mt-5 mb-3 text-light">
          Partnership Report
        </h1>
        <div className="partnership">
          <div className="partnership-section px-5 py-2">
            <span className="d-flex justify-content-between align-align-items-center">
              <p>Babar Azam 3(17)</p>
              <strong>9 (24)</strong>
              <p>Babar Azam 3(17)</p>
            </span>
            <div className="d-flex justify-content-center">
              <div
                className="partnership-section-highlights d-flex position-relative mt-3"
                style={{
                  borderBottom: "1px solid #000",
                  width: "75%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    marginRight: "5px",
                    position: "relative",
                  }}
                >
                  <ProgressBar
                    // now={}
                    variant="success"
                    className="progress-bar-rtl"
                  />
                </div>

                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: "5px",
                    position: "relative",
                  }}
                >
                  <ProgressBar
                    // now={player1Progress}
                    variant="primary"
                    className="progress-bar-ltr"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="heading1 text-center mt-5 mb-3 text-light">
          Best Partnerships
        </h1>
        <h2 className="heading2 text-start mt-5 mb-3 text-light">Pakistan</h2>
        <div className="best-partnership px-5 py-2">
          <div className="partnership-section d-flex pb-2 align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <LazyLoadImage
                src={Images.babarAzam}
                width={60}
                height={60}
                className="rounded-circle"
              />
              <h3 className="heading3 fw-bolder ms-2">
                Babar Azam<p className="text-black">35(24)</p>
              </h3>
            </div>
            <div
              className="text-center"
              style={{ color: "rgba(231, 50, 147, 1)" }}
            >
              <h5 className="m-0">Wicket # 3</h5>
              <p className="m-0">56(39)</p>
            </div>
            <div className="d-flex align-items-center">
              <LazyLoadImage
                src={Images.babarAzam}
                width={60}
                height={60}
                className="rounded-circle"
              />
              <h3 className="heading3 fw-bolder ms-2">
                Babar Azam<p className="text-black">35(24)</p>
              </h3>
            </div>
          </div>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h2 className="heading2 text-start mt-5 mb-3 text-light">Pakistan</h2>
        <div className="best-partnership px-5 py-2">
          <div className="partnership-section d-flex pb-2 align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <LazyLoadImage
                src={Images.babarAzam}
                width={60}
                height={60}
                className="rounded-circle"
              />
              <h3 className="heading3 fw-bolder ms-2">
                Babar Azam<p className="text-black">35(24)</p>
              </h3>
            </div>
            <div
              className="text-center"
              style={{ color: "rgba(231, 50, 147, 1)" }}
            >
              <h5 className="m-0">Wicket # 3</h5>
              <p className="m-0">56(39)</p>
            </div>
            <div className="d-flex align-items-center">
              <LazyLoadImage
                src={Images.babarAzam}
                width={60}
                height={60}
                className="rounded-circle"
              />
              <h3 className="heading3 fw-bolder ms-2">
                Babar Azam<p className="text-black">35(24)</p>
              </h3>
            </div>
          </div>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Pakistan Spell Report
        </h1>
        <div className="spell-report px-5 py-2 pb-4">
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h1 className="heading1 text-start mt-3 mb-3 text-light">
          India Spell Report
        </h1>
        <div className="spell-report px-5 py-2 pb-4">
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
          <h3 className="heading3 fw-bolder">Babar Azam - (OB)</h3>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div> */}
        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Highest Runs in An Over
        </h1>
        <div className="d-flex justify-content-between gap-5">
          <div className="highest-runs-over px-5 py-2">
            <h3 className="heading2 fw-bolder">Pakistan</h3>
            <div className="highest-runs-over-section d-flex justify-content-between align-items-center">
              <div>
                <h3 className="heading3 fw-bolder">
                  Overs:<p className="text-black">15</p>
                </h3>
                <h3 className="heading3 fw-bolder">
                  Runs:<p className="text-black">156</p>
                </h3>
                <h3 className="heading3 fw-bolder mb-2">This Over:</h3>
                <span className="d-flex flex-wrap">
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                </span>
              </div>
              <div>
                <div className="d-flex align-items-center">
                  <LazyLoadImage
                    src={Images.babarAzam}
                    width={38}
                    height={38}
                    className="rounded-circle"
                  />
                  <h3 className="heading3 fw-bolder ms-2">
                    Babar Azam<p className="text-black">35(24)</p>
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <LazyLoadImage
                    src={Images.babarAzam}
                    width={38}
                    height={38}
                    className="rounded-circle"
                  />
                  <h3 className="heading3 fw-bolder ms-2">
                    Babar Azam<p className="text-black">35(24)</p>
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <LazyLoadImage
                    src={Images.babarAzam}
                    width={38}
                    height={38}
                    className="rounded-circle"
                  />
                  <h3 className="heading3 fw-bolder ms-2">
                    Babar Azam<p className="text-black">35(24)</p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="highest-runs-over px-5 py-2">
            <h3 className="heading2 fw-bolder">Pakistan</h3>
            <div className="highest-runs-over-section d-flex justify-content-between align-items-center">
              <div>
                <h3 className="heading3 fw-bolder">
                  Overs:<p className="text-black">15</p>
                </h3>
                <h3 className="heading3 fw-bolder">
                  Runs:<p className="text-black">156</p>
                </h3>
                <h3 className="heading3 fw-bolder mb-2">This Over:</h3>
                <span className="d-flex flex-wrap">
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                  <h5 className="overs bg-black">6</h5>
                </span>
              </div>
              <div>
                <div className="d-flex align-items-center">
                  <LazyLoadImage
                    src={Images.babarAzam}
                    width={38}
                    height={38}
                    className="rounded-circle"
                  />
                  <h3 className="heading3 fw-bolder ms-2">
                    Babar Azam<p className="text-black">35(24)</p>
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <LazyLoadImage
                    src={Images.babarAzam}
                    width={38}
                    height={38}
                    className="rounded-circle"
                  />
                  <h3 className="heading3 fw-bolder ms-2">
                    Babar Azam<p className="text-black">35(24)</p>
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <LazyLoadImage
                    src={Images.babarAzam}
                    width={38}
                    height={38}
                    className="rounded-circle"
                  />
                  <h3 className="heading3 fw-bolder ms-2">
                    Babar Azam<p className="text-black">35(24)</p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Ball Wise Boundaries Given %
        </h1>
        <div className="d-flex justify-content-between gap-5">
          <div className="ball-wise-boundaries px-5 py-2">
            <h3 className="heading2 fw-bolder">Pakistan</h3>
            <span className="d-flex justify-content-between mt-3">
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
            </span>
          </div>
          <div className="ball-wise-boundaries px-5 py-2">
            <h3 className="heading2 fw-bolder">Pakistan</h3>
            <span className="d-flex justify-content-between mt-3">
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
              <span className="text-center">
                <h5 className="overs bg-success">60%</h5>
                <p className="text-black m-0">35(24)</p>
              </span>
            </span>
          </div>
        </div>

        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Worm (Overs vs Runs)
        </h1>
        <WormChart />
        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Manhattan (Overs vs Runs)
        </h1>
        <BarChart />
        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Overs Comparison
        </h1>
        <ComparisonBarChart tableData3={tableData3} columns3={columns3} />
        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Types of Wickets Taken
        </h1>
        <div className="d-flex justify-content-between">
          <PieChart />
          <PieChart />
        </div>
        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Overs Timeline
        </h1>
        <div className="overs-timeline px-4">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ backgroundColor: "E5F3F0" }}
          >
            <div className="d-flex align-items-center">
              <LazyLoadImage
                src={Images.babarAzam}
                width={40}
                height={40}
                className="rounded-circle"
              />
              <h3 className="heading3 fw-bolder ms-2">
                Pakistan<p className="text-black">1st Innings</p>
              </h3>
            </div>

            <h3 className="heading3 fw-bolder ms-2 d-flex">
              286/6 <p className="text-black">(50)</p>
            </h3>
          </div>
          <div className="table-container p-0">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>

        <h1 className="heading1 text-start mt-5 mb-3 text-light">
          Overs Timeline
        </h1>
        <div className="overs-timeline px-4">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ backgroundColor: "E5F3F0" }}
          >
            <div className="d-flex align-items-center">
              <LazyLoadImage
                src={Images.babarAzam}
                width={40}
                height={40}
                className="rounded-circle"
              />
              <h3 className="heading3 fw-bolder ms-2">
                Pakistan<p className="text-black">1st Innings</p>
              </h3>
            </div>

            <h3 className="heading3 fw-bolder ms-2 d-flex">
              286/6 <p className="text-black">(50)</p>
            </h3>
          </div>
          <div className="table-container p-0">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h1 className="heading1 text-center mt-5 mb-3 text-light">
          <LazyLoadImage
            src={Images.babarAzam}
            width={38}
            height={38}
            className="rounded-circle me-4"
          />
          Pakistan Team Analysis
        </h1>
        <h1 className="heading2 text-start mt-5 mb-3 text-light">
          Batting Comparison
        </h1>
        <div className="best-partnership px-5 py-2">
          <div className="partnership-section pb-2">
            <h2 className="heading3 fw-bolder">
              Batting comparison within Team Pakistan
            </h2>
          </div>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h1 className="heading2 text-start mt-5 mb-3 text-light">
          Bowling Comparison
        </h1>
        <div className="best-partnership px-5 py-2">
          <div className="partnership-section pb-2">
            <h2 className="heading3 fw-bolder">
              Bowling comparison within Team Pakistan
            </h2>
          </div>
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h1 className="heading2 text-start mt-5 mb-3 text-light">
          Pakistan Team Batting Against Spin
        </h1>
        <div className="best-partnership px-5 py-2">
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h1 className="heading2 text-start mt-5 mb-3 text-light">
          Pakistan Team Batting Against Pace
        </h1>
        <div className="best-partnership px-5 py-2">
          <div className="table-container p-0 mt-2">
            <DataTable columns={columns3} data={tableData3} />
          </div>
        </div>
        <h1 className="heading2 text-start mt-5 mb-3 text-light">
          Area of Runs Against (Pace/Spin)
        </h1>
        <div className="wagon-wheel-area-runs px-5 py-2"></div>
        <h1 className="heading2 text-start mt-5 mb-3 text-light">
          Player Stats
        </h1>
        <div className="player-stats px-5 py-2">
          <div className="player-stats-batting">
            <div className="player-stats-batting-left"></div>
            <div className="player-stats-batting-left"></div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MatchSummary;
