import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import SingleButton from "../SingleButton";
import { toast } from "react-toastify";
import { getItemFromLocalStorage } from "../../helper/helper";
import { postRequestForm } from "../../helper/api";

const EndMatch = ({ handleClose, matchDetails, setMatchDetails, winner }) => {
  const [manOfTheMatch, setManOfTheMatch] = useState(null);
  

  useEffect(() => {
    console.log("🚀 Winner:", winner)
    }, [winner]);

  const handleSave = () => {
    setMatchDetails((prev) => {
      return {
        ...prev,
        match: {
          ...prev.match,
          manOfTheMatch: manOfTheMatch,
          winner: winner.teamId._id,
        },
      };
    });

    sendMatchDetailsToAPI(matchDetails.match._id, matchDetails);

  };


  const saveMatchDetailsToLocalStorage = (matchId, matchDetails) => {
    localStorage.setItem(
      `matchDetails_${matchId}`,
      JSON.stringify(matchDetails)
    );
  };

  const sendMatchDetailsToAPI = async (matchId, matchDetails) => {
      try {

        console.log("🚀 Match Details to be sent winning:", matchDetails);
        saveMatchDetailsToLocalStorage(matchId, matchDetails);
  
        const payload = structuredClone(matchDetails.match);
  
        // ✅ Ensure `player` field is mapped correctly
        if (payload.facingBatsman?.player) {
          payload.facingBatsman.player = payload.facingBatsman.player._id;
        }
        if (payload.nonStriker?.player) {
          payload.nonStriker.player = payload.nonStriker.player._id;
        }
        if (payload.bowler?.player) {
          payload.bowler.player = payload.bowler.player._id;
        }
  
        const removeIds = (obj) => {
          if (!obj) return null;
          const { _id, id, ...cleanedObj } = obj;
          return cleanedObj;
        };
  
        payload.facingBatsman = removeIds(payload.facingBatsman);
        payload.nonStriker = removeIds(payload.nonStriker);
        payload.bowler = removeIds(payload.bowler);
  
        // ✅ Correct `teams` structure
        payload.teams = payload.teams.map(
          ({ _id, id, teamId, selectedPlayers, substitutePlayers, ...rest }) => ({
            ...rest,
            teamId: teamId?._id,
            selectedPlayers: selectedPlayers.map(
              ({ player, _id, id, ...restPlayer }) => ({
                player: player?._id,
                ...restPlayer,
              })
            ),
            substitutePlayers: substitutePlayers.map(
              ({ player, _id, id, ...restPlayer }) => ({
                player: player?._id,
                ...restPlayer,
              })
            ),
          })
        );
  
        payload.matchId = payload._id;
  
        // ✅ Remove unnecessary fields
        const fieldsToRemove = [
          "_id",
          "id",
          "owner",
          "tournamentId",
          "totalOvers",
          "matchType",
          "overs",
          "updatedAt",
          "createdAt",
          "__v",
          "matchNumber",
          "matchName",
          "venue",
          "isDlsApplied",
          "isFirstInningCompleted",
        ];
        fieldsToRemove.forEach((field) => delete payload[field]);
  
        console.log("✅ Cleaned Payload:", JSON.stringify(payload, null, 2));
  
        const token = getItemFromLocalStorage("TOKEN");
        const { result, error } = await postRequestForm(
          `/api/secure/v2/match/match-winner`,
          token,
          payload
        );
  
        if (result?.status === 200) {
          console.log("✅ Match data sent successfully!");
          toast.success("Match Data sent successfully!", {
            position: "top-center",
            theme: "colored",
            autoClose: 6000,
          });
        }
      } catch (error) {
        console.error("❌ Error sending ball data:", error);
      }finally{
      }
    };
  

  return (
    <section
      id="dlsModal"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
          />
        </div>
        <h1 className="match-scoring-modal-heading text-light text-center mb-5">
          {winner && (
            <h1 className="text-light text-center mb-5">
              {winner?.teamId.teamName} won the match
            </h1>
          )}
        </h1>

        {/* DLS Overs Input */}
        <Form.Group className="mb-3 text-light">
          <Form.Label>Select Man of the Match</Form.Label>
          <Form.Select
            aria-label="Select Player"
            onChange={(e) => setManOfTheMatch(e.target.value)}
            value={manOfTheMatch || ""}
            style={{ width: "200px" }}
          >
            <option value="">Select Player</option>
            {winner?.selectedPlayers?.map((player) => (
              <option key={player.player?._id} value={player.player?._id}>
                {player.player?.playerName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <div className="d-flex flex-column align-items-center">
          <SingleButton
            content="Save"
            onClick={handleSave}
            backgroundColor="rgba(231, 50, 147, 1)"
            color="#fff"
            buttonStyles={{ width: "200px", marginTop: "10px" }}
          />
        </div>
      </Container>
    </section>
  );
};

export default EndMatch;
