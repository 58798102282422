import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Modal,
  Row,
  Col,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faDownload,
  faEye,
  faRemove,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../constants/Images";
import BackButton from "../../constants/BackButton";
import Layout from "../../layouts/Layout";
import { useNavigate } from "react-router-dom";
import SingleButton from "../../constants/SingleButton";
import DataTable from "react-data-table-component";
import TableSkeleton from "../../constants/Skeleton Loaders/TableSkeleton";
import { getItemFromLocalStorage } from "../../helper/helper";
import { deleteRequest, getRequest } from "../../helper/api";
import { toast } from "react-toastify";

function Previous() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [matchId, setMatchId] = useState(null);
  const [search, setSearch] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);
  const [tableHead, setTableHead] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteConfirmation = (playerId) => {
    setMatchId(playerId);
    setShowDeleteModal(true);
  };
  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };
  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    console.log("Rows per page changed to:", newPerPage, page);
    setPerPage(newPerPage);
    setCurrentPage(page); // Reset to the first page
  };
  const handleDeletePlayer = async () => {
    try {
      setDeleteLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await deleteRequest(
        `/api/secure/v2/match/remove-match`,
        { matchId: matchId },
        token
      );
      if (result?.status === 200) {
        toast.success("Match deleted successfully.", {
          position: "top-center",
        });
        getAllMatches(currentPage, perPage);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to delete Match"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    } finally {
      setShowDeleteModal(false);
      setDeleteLoading(false);
    }
  };

  const getAllMatches = useCallback(
    async (currentPage, perPage, search) => {
      try {
        setLoading(true);
        const token = await getItemFromLocalStorage("TOKEN");
        const { result, error } = await getRequest(
          `/api/secure/v2/match/get-matches`,
          token,
          { page: currentPage, limit: perPage, search: search }
        );

        if (result?.status === 200 || result?.status === 201) {
          setTableData(result?.data?.matches);
          setTotalRows(result?.data?.totalMatches);
        } else {
          throw new Error(
            error?.response?.data?.message || "An error occurred."
          );
        }
        setTableHead([
          {
            name: "Match Name",
            selector: (row) => row.matchName,
            sortable: true,
          },
          {
            name: "Tournament Name",
            selector: (row) => row.tournamentId?.tournamentName,
            sortable: true,
          },
          {
            name: "Owner",
            // center: true,
            cell: (row) => (
              <div
                title={row.owner.email}
                className="d-flex gap-1 justify-content-between align-items-center"
              >
                <div className="me-2" title={row.owner.email}>
                  <LazyLoadImage
                    src={
                      row.owner.profileImageUrl
                        ? row?.owner?.profileImageUrl
                        : null
                    }
                    alt={row.owner.email}
                    width="40"
                    height="40"
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      objectPosition: "center",
                      border: "1px solid rgba(231, 50, 147, 1)",
                    }}
                  />
                </div>
                <div className="text-light">
                  <p
                    style={{
                      wordBreak: "break-all",
                      margin: 0,
                    }}
                    title={row.owner.email}
                  >
                    {row.owner.fullName}
                  </p>
                  {/* <p
                    style={{
                      wordBreak: "break-all",
                      margin: 0,
                    }}
                  >
                    {row.owner.email}
                  </p> */}
                </div>
              </div>
            ),
            maxWidth: "200px",
            sortable: false,
          },
          {
            name: "Actions",
            cell: (row) => (
              <>
                <Button
                  variant="danger"
                  size="sm"
                  className="me-2 my-3"
                  onClick={() => handleDeleteConfirmation(row._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  className="me-2 my-3"
                  onClick={() =>
                    navigate(`/dashboard/match-scoring?_id=${row._id}`)
                  }
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    navigate(`/dashboard/match-summary`, {
                      state: { matchId: row._id },
                    })
                  }
                >
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              </>
            ),
            maxWidth: "140px",
          },
        ]);
      } catch (err) {
        toast.error(err.message || "An error occurred.", {
          position: "top-center",
        });
      } finally {
        setLoading(false);
      }
    },
    [currentPage, perPage, search] // Stable reference
  );

  useEffect(() => {
    getAllMatches(currentPage, perPage, search);
  }, [currentPage, perPage, search, getAllMatches]);
  return (
    <Layout>
      <section
        id="startMatch"
        style={{
          background:
            "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        }}
      >
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <BackButton
              icon={Images.backArrow}
              content="Back"
              onClick={() => navigate("/dashboard")}
            />
            <p className="text-light mt-2 font-main fs-4 fw-bold">
              Previous Matches
            </p>

            <SingleButton
              variant="primary"
              backgroundColor={"#fff"}
              color={"rgba(231, 50, 147, 1)"}
              content={"Create Match"}
              onClick={() => navigate("/dashboard/all-tournament")}
              buttonStyles={
                {
                  // width: "100%",
                }
              }
            />
          </div>
        </Container>
        <Container className="logo-table-container mt-5 ">
          <div className="table-container">
            <Row className="mb-3">
              <Col xs={12} className="mt-3">
                <FormControl
                  type="text"
                  placeholder="Search Matches"
                  className="me-2"
                  value={search}
                  onChange={handleSearchCategory}
                />
              </Col>
            </Row>
            <br />
            {loading ? (
              <TableSkeleton
                row={perPage}
                head={[
                  {
                    name: "Match Name",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                    width: 350,
                    height: 20,
                    // circle: true,
                  },
                  {
                    name: "Tournament Name",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                    width: 350,
                    height: 20,
                  },
                  {
                    name: "Owner",
                    className:
                      "ssc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv kPFmFZ jEzKnw rdt_TableCell",
                    width: [40, 100],
                    height: [40, 20],
                    circle: true,
                  },
                  {
                    name: "Actions",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell",
                    width: [30, 30],
                    height: [30, 30],
                  },
                ]}
              />
            ) : (
              <DataTable
                columns={tableHead}
                data={tableData}
                paginationPerPage={perPage}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination
                paginationServer
                highlightOnHover
                responsive
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                noDataComponent={<p>No data found</p>}
                customStyles={{
                  headCells: {
                    style: {
                      border: "1px solid #ccc",
                      color: "#fff",
                    },
                  },
                  cells: {
                    style: {
                      border: "1px solid #ccc",
                      color: "#fff",
                    },
                  },
                }}
              />
            )}
          </div>
        </Container>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header
            style={{
              backgroundColor: "rgb(0, 16, 65)",
              color: "#fff",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: "rgba(33, 72, 192, 1)",
              color: "#fff",
            }}
          >
            Are you sure you want to delete this Team? This action cannot be
            undone.
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: "rgba(33, 72, 192, 1)",
              color: "#fff",
              border: 0,
              borderRadius: 0,
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeletePlayer}>
              {deleteLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Delete"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </Layout>
  );
}

export default Previous;
