import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SingleButton from "./SingleButton";

const NewInningsModal = ({
  handleClose,
  teams,
  setSelectedFacingBatsman,
  setSelectedNonStriker,
  setSelectedBowler,
  handleInningsSelection,
}) => {

  return (
    <section
      id="selectBowlerModal"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
          />
        </div>
        <h1 className="match-scoring-modal-heading text-light">
          Select Striker, Non-Striker and Bowler for next Innings
        </h1>

        <Form.Group className="mb-3">
          <Form.Label className="text-light">Select Facing Batsman</Form.Label>
          <Form.Select
            onChange={(e) => setSelectedFacingBatsman(e.target.value)}
          >
            <option value="">Choose Batsman</option>
            {teams[0]?.selectedPlayers.map((player) => (
              <option key={player.player?._id} value={player.player?._id}>
                {player.player?.playerName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="text-light">Select Non-Striker</Form.Label>
          <Form.Select onChange={(e) => setSelectedNonStriker(e.target.value)}>
            <option value="">Choose Non-Striker</option>
            {teams[0]?.selectedPlayers.map((player) => (
              <option key={player.player?._id} value={player.player?._id}>
                {player.player?.playerName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="text-light">Select Bowler</Form.Label>
          <Form.Select onChange={(e) => setSelectedBowler(e.target.value)}>
            <option value="">Choose Bowler</option>
            {teams[1]?.selectedPlayers.map((player) => (
              <option key={player.player?._id} value={player.player?._id}>
                {player.player?.playerName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <div className="d-flex flex-column align-items-center">
          <SingleButton
            content={"Start New Innings"}
            onClick={handleInningsSelection}
            backgroundColor={"rgba(231, 50, 147, 1)"}
            color={"#fff"}
            buttonStyles={{
              width: "200px",
              marginTop: "10px",
            }}
          />
        </div>
      </Container>
    </section>
  );
};

export default NewInningsModal ;
