import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import DataTable from "react-data-table-component";

const PhaseComparison = ({ handleClose }) => {
  const [tableData, setTableData] = useState([]);
  const [tableHead, setTableHead] = useState();

 useEffect(() => {
    setTableData([
      {
        overs: "0.1 - 2.0",
        runs: 15,
        balls: 12,
        wicketsLost: 1,
        dots: 5,
        fours: 2,
        sixes: 1,
        wickets: 1,
        dotPercentage: "41.67%",
        boundaryPercentage: "25%",
      },
    ]);

    setTableHead([
      {
        name: "Overs",
        cell: (row) => <p className="m-0 text-start">{row.overs}</p>,
        sortable: true,
        width: "200px", // Wider first column
      },
      {
        name: "Runs",
        selector: (row) => row.runs,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "Balls Played",
        selector: (row) => row.balls,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "Wicket Lost",
        selector: (row) => row.wicketsLost,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "Dots",
        selector: (row) => row.dots,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "4s",
        selector: (row) => row.fours,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "6s",
        selector: (row) => row.sixes,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "Wickets",
        selector: (row) => row.wickets,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "Dot%",
        selector: (row) => row.dotPercentage,
        width: "90px",
        sortable: true,
        center: true,
      },
      {
        name: "Boundary%",
        selector: (row) => row.boundaryPercentage,
        width: "90px",
        sortable: true,
        center: true,
      },
    ]);
  }, []);

  return (
    <section
      id="phase-comparison"
      style={{
        background:
          "linear-gradient(180deg, rgba(17,28,65,1) 0%, rgba(8,8,32,1) 100%)",
        height: "80vh",
      }}
    >
      <Container>
        <div className="d-flex justify-content-end pt-5">
          <FontAwesomeIcon
            icon={faClose}
            color="#fff"
            size="2x"
            onClick={handleClose}
          />
        </div>
        <h1 className="match-scoring-modal-heading">Partnership</h1>
        <div className="comparison-section d-flex flex-column align-items-center px-5 py-2">
          <DataTable
            columns={tableHead}
            data={tableData}
            paginationServer
            highlightOnHover
            responsive
            noDataComponent={<p>No data found</p>}
          />
        </div>
      </Container>
    </section>
  );
};

export default PhaseComparison;
