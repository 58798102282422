import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DataTable from "react-data-table-component";

// Register necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ComparisonBarChart = ({ columns3, tableData3 }) => {
  // Runs scored in groups of 5 overs
  const teamData = {
    Pakistan: [40, 55, 50, 35], // Sum of runs in each 5-over segment
    India: [45, 50, 60, 38],
  };

  // Labels for 5-over segments
  const labels = ["Overs 1-5", "Overs 6-10", "Overs 11-15", "Overs 16-20"];

  // Chart Data Configuration
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Pakistan Runs",
        data: teamData.Pakistan,
        backgroundColor: "rgba(0, 128, 0, 0.6)", // Green
        borderColor: "green",
        borderWidth: 1,
      },
      {
        label: "India Runs",
        data: teamData.India,
        backgroundColor: "rgba(0, 0, 255, 0.6)", // Blue
        borderColor: "blue",
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    scales: {
      x: { title: { display: true, text: "Overs (Grouped by 5)" } },
      y: { title: { display: true, text: "Total Runs in 5 Overs" } },
    },
  };

  return (
    <div className="comparison-bar-chart px-5 py-2">
      <Bar data={chartData} options={options} />
      <div className="table-container p-0 mt-4">
        <DataTable columns={columns3} data={tableData3} />
      </div>
      <div className="table-container p-0 mt-4">
        <DataTable columns={columns3} data={tableData3} />
      </div>
      <div className="table-container p-0 mt-4">
        <DataTable columns={columns3} data={tableData3} />
      </div>
      <div className="table-container p-0 mt-4">
        <DataTable columns={columns3} data={tableData3} />
      </div>
    </div>
  );
};

export default ComparisonBarChart;
